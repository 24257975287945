/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }] */

import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { CustomP, PageTitle, PopMessageParams, Section, getStyledNumbers } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import { IconHub } from "../../components/icon.js";
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { NavLink, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CustomLoadingButton } from "../../components/button.js";

const VabzeumBonusListElem = ({ bonus, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const [_bonusType, setBonusType] = useState(undefined)

    useEffect(() => {  
        props.bonusType.forEach(type => {
            if (type.id === bonus.bonus_type_id) {
                setBonusType(type.name)
            }
        })
    }, [theme])


    return (
        <div style={{
            backgroundColor: props.index % 2 === 1 ? theme.secondary : theme.secondary_lighter,
            borderTop: props.index > 0 ? `1px solid ${theme.primary}` : ''
        }}
            className={`py-4 flex justify-around text-center items-center transition-all`}>
            <div className='flex justify-center
                w-3/12'>
                <CustomP>{bonus.name}</CustomP>
            </div>
            <div className='flex justify-center
                w-3/12
                sm:w-2/12
                md:w-1/12'>
                <CustomP>{bonus.value}</CustomP>
            </div>
            <div className='flex justify-center items-center
                w-3/12
                sm:w-3/12
                md:w-2/12
                lg:w-1/12'>
                <CustomP>{getStyledNumbers(undefined, 'react', bonus.operation)}</CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                md:block md:w-1/12'>
                <div className='flex justify-center'>
                    <CustomP>{bonus.auto ? <IconHub fontSize='large' name='checkbox-circle' /> : <IconHub fontSize='large' name='crossbox' />}</CustomP>

                </div>
            </div>
            <div className='flex justify-center items-center
                hidden
                lg:block lg:w-1/12'>
                <CustomP
                    className='flex justify-center space-x-1'>
                    <CustomP>{bonus.hour_min}</CustomP>
                    <p>-</p>
                    <CustomP >{bonus.hour_max}</CustomP>
                </CustomP>

            </div>
            <div className='flex justify-center items-center
                hidden
                lg:block lg:w-1/12'>
                <CustomP>{bonus.condition_group_nb}</CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                lg:block lg:w-1/12'>
                <CustomP>{bonus.cond_entity && bonus.cond_entity.name}</CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                lg:block lg:w-1/12'>
                <CustomP>{bonus.cond_side && bonus.cond_side.name}</CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                sm:block sm:w-2/12
                md:block md:w-1/12'>
                <CustomP>{bonus.enabled ? <IconHub fontSize='large' name='checkbox-circle' /> : <IconHub fontSize='large' name='crossbox' />}</CustomP>

            </div>
            <div className='flex justify-center items-center
                hidden
                md:block md:w-2/12'>
                <CustomP>{bonus.bonus_type.name}</CustomP>
            </div>
            <div
                className='flex justify-center items-center
                w-3/12
                sm:w-2/12'>
                <NavLink to={`/admin/vabzeum/bonus/edit?bonus=${bonus.id}`}>
                    <CustomP className={`cursor-pointer`}><IconHub fontSize='large' name='edit' /></CustomP>
                </NavLink>
            </div>
        </div>
    )
}

const AdminVabzeumBonusList = () => {
    const [_bonusList, setBonusList] = useState([])
    const [renderedBonusList, setRenderedBonusList] = useState(undefined)
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const { enqueueSnackbar } = useSnackbar()
    const routerHistory = useHistory()


    const refreshVabzeumBonusList = () => {
        axios.post('/api/admin/vabzeum/get_bonus', {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => {
                setBonusList(response.data.bonus_list)
                var tmp_renderedBonusList = []
                for (var i = 0; i < response.data.bonus_list.length; i++) {
                    tmp_renderedBonusList.push(<VabzeumBonusListElem rowLen={response.data.bonus_list.length} key={i} index={i} bonus={response.data.bonus_list[i]} theme={theme} bonusType={response.data.bonus_type} baseData={response.data.base_data}/>)
                }
                setRenderedBonusList(tmp_renderedBonusList)
            })
            .catch(() => {
                enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
            })
    }

    useEffect(() => {
        refreshVabzeumBonusList(1)
    }, [])

    
    const createBonusLink = () => {
        routerHistory.push('/admin/vabzeum/bonus/create')
    }

    return (
        <div className='flex-row justify-center mb-8'>
            <PageTitle>Liste des bonus</PageTitle>
            <Section max_width={1600} callBack={refreshVabzeumBonusList} className=''
                title={``}>
                <div className='w-full'>
                    <div className="flex justify-end">
                        <CustomLoadingButton onClick={createBonusLink}>Créer un bonus</CustomLoadingButton>
                    </div>
                    <div className=''>
                        <div style={{ borderBottom: `2px solid ${theme.primary}` }}
                            className='border-b-2 flex justify-around items-center text-center h-12'>
                            <CustomP className='
                            w-3/12'>Nom</CustomP>
                            <CustomP className='
                            w-3/12
                            sm:w-2/12
                            md:w-1/12'>Valeur</CustomP>

                            <CustomP className='
                            w-3/12
                            sm:w-3/12
                            md:w-2/12
                            lg:w-1/12'>Opération</CustomP>

                            <CustomP className='
                            hidden
                            md:block md:w-1/12
                            '>Auto</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-1/12
                            '>Horaires</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-1/12
                            '>Cond. nb joueurs</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-1/12
                            '>Cond. entité</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-1/12
                            '>Cond. côté</CustomP>

                            <CustomP
                                className='
                            hidden
                            sm:block sm:w-2/12
                            md:block md:w-1/12
                            '>Activé</CustomP>

                            <CustomP
                                className='
                            hidden
                            md:block md:w-2/12
                            '>Type</CustomP>


                            <CustomP
                                className='
                            w-3/12
                            sm:w-2/12
                            '></CustomP>

                        </div>
                    </div>
                    <Scrollbars autoHide autoHeight autoHeightMax={window.innerHeight - 350}>
                        <div style={{
                            borderColor: theme.secondary,
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                        }}
                            v-for="item in items" className='w-full transform even:bg-gray-400'>
                            {renderedBonusList}
                        </div>
                    </Scrollbars>
                </div>
            </Section >
        </div >
    );
}

const AdminVabzeumBonusListPage = () => {
    return (
        <Access>
            <AdminVabzeumBonusList />
        </Access>
    )
}

export default AdminVabzeumBonusListPage