/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { CustomP, PageTitle, PopMessageParams, Section, useInterval } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import { CustomLoadingButton } from "../../components/button.js";
import AnimatedNumber from "animated-number-react";
import { useHistory } from 'react-router';
import { IconHub } from "../../components/icon.js";
import { useSnackbar } from "notistack";

const MemberSection = (props) => {
    const routerHistory = useHistory()
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [nbUser, setNbUser] = useState(0)
    const [nbCharacter, setNbCharacter] = useState(0)

    useEffect(() => {
        axios.post('/api/nb_user', {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => { setNbUser(response.data) })
            .catch(() => { setNbUser(-1) })

        axios.post('/api/nb_character', {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => { setNbCharacter(response.data) })
            .catch(() => { setNbCharacter(-1) })
    }, [props.refresh])
    const formatValue = (value) => value.toFixed(0);

    const linkToUserCreation = () => {
        routerHistory.push('/admin/create_user')
    }

    const linkToUserList = () => {
        routerHistory.push('/admin/user_list')
    }

    return (
        <div className='flex flex-wrap justify-center items-center
            md:justify-between'>
            <div className='flex flex-col
                p-4'>
                <CustomP>
                    <AnimatedNumber value={nbUser} formatValue={formatValue} /> utilisateurs
                </CustomP>
                <CustomP>
                    <AnimatedNumber value={nbCharacter} formatValue={formatValue} /> personnages
                </CustomP>
            </div>
            <div className='flex flex-wrap sm:justify-end justify-center'>
                <CustomLoadingButton
                    loading={false}
                    loadingColor={theme.primary}
                    outlined={false}
                    startIcon={<IconHub name='person-add' />}
                    onClick={linkToUserCreation}
                >Créer un utilisateur
                </CustomLoadingButton>
                <CustomLoadingButton
                    loading={false}
                    loadingColor={theme.primary}
                    outlined={false}
                    startIcon={<IconHub name='list-numbered' />}
                    onClick={linkToUserList}
                >Liste des utilisateurs
                </CustomLoadingButton>
            </div>
        </div>
    )
}

const VabzeumSection = (props) => {
    const routerHistory = useHistory()
    // const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [nbMatch, setNbMatch] = useState(0)
    const [nbMatchToValid, setNbMatchToValid] = useState(0)
    // const { session } = useContext(SessionContext)
    const [nbPointThisMonth, setNbPointThisMonth] = useState(0)
    const [nbMatchThisMonth, setNbMatchThisMonth] = useState(0)

    useEffect(() => {
        axios.post('/api/vabzeum_nb_match_preview', {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => {
                setNbMatch(response.data['nb_match'])
                setNbMatchToValid(response.data['nb_match_to_valid'])
                setNbMatchThisMonth(response.data['nb_match_this_month'])
            })
            .catch(() => {
                setNbMatch(-1)
                setNbMatchToValid(-1)
                setNbMatchThisMonth(-1)
            })
        axios.post('/api/vabzeum_match_point_preview', {
            dateRange: 'month',
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            setNbPointThisMonth(response.data)
        }).catch(() => {
            setNbPointThisMonth(-1)
        })
    }, [props.refresh])

    const formatValue = (value) => value.toFixed(0);

    const linkToMatchList = () => {
        routerHistory.push('/admin/vabzeum/list')
    }

    const linkToBonusList = () => {
        routerHistory.push('/admin/vabzeum/bonus/list')
    }

    const linkToGenerateScore = () => {
        routerHistory.push('/admin/vabzeum/generate_score')
    }


    return (
        <div className='flex flex-wrap justify-center items-center
            md:justify-between'>
            <div className='flex flex-col
                p-4'>
                <CustomP>
                    <AnimatedNumber value={nbMatch} formatValue={formatValue} /> matchs au total</CustomP>
                <CustomP>
                    <AnimatedNumber value={nbMatchThisMonth} formatValue={formatValue} /> matchs ce mois</CustomP>
                <CustomP>
                    <AnimatedNumber value={nbMatchToValid} formatValue={formatValue} /> matchs à valider</CustomP>
                <CustomP>
                    <AnimatedNumber value={nbPointThisMonth} formatValue={formatValue} /> points ce mois-ci</CustomP>
            </div>
            <div>
                <div className='flex flex-wrap sm:justify-end justify-center'>
                    <CustomLoadingButton
                        startIcon={<IconHub name='list-numbered' />}
                        onClick={linkToMatchList}>
                        Liste des matchs
                    </CustomLoadingButton>
                    <CustomLoadingButton
                        startIcon={<IconHub name='star' />}
                        onClick={linkToBonusList}>
                        Liste des bonus</CustomLoadingButton>
                </div>
                <div className='flex flex-wrap sm:justify-end justify-center'>
                    <CustomLoadingButton
                        startIcon={<IconHub name='graph' />}
                        onClick={linkToGenerateScore}>
                        Générer les scores
                    </CustomLoadingButton>
                </div>
                <div className='flex flex-wrap sm:justify-end justify-center'>
                    <CustomLoadingButton disabled outlined startIcon={<IconHub name='playlist-add' />}>Barême</CustomLoadingButton>
                    <CustomLoadingButton disabled outlined>Entités</CustomLoadingButton>
                    <CustomLoadingButton disabled outlined>Côtés</CustomLoadingButton>
                </div>
            </div>
        </div>
    )
}

const VabrusherSection = (props) => {
    const routerHistory = useHistory()
    // const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [nbMatch, setNbMatch] = useState(0)
    const [nbMatchToValid, setNbMatchToValid] = useState(0)
    // const { session } = useContext(SessionContext)
    const [nbPointThisMonth, setNbPointThisMonth] = useState(0)
    const [nbMatchThisMonth, setNbMatchThisMonth] = useState(0)

    useEffect(() => {
        axios.post('/api/vabrusher_nb_match_preview', {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => {
                setNbMatch(response.data['nb_match'])
                setNbMatchToValid(response.data['nb_match_to_valid'])
                setNbMatchThisMonth(response.data['nb_match_this_month'])
            })
            .catch(() => {
                setNbMatch(-1)
                setNbMatchToValid(-1)
                setNbMatchThisMonth(-1)
            })
        axios.post('/api/vabrusher_match_point_preview', {
            dateRange: 'month',
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            setNbPointThisMonth(response.data)
        }).catch(() => {
            setNbPointThisMonth(-1)
        })
    }, [props.refresh])

    const formatValue = (value) => value.toFixed(0);

    const linkToMatchList = () => {
        routerHistory.push('/admin/vabrusher/list')
    }

    const linkToBonusList = () => {
        routerHistory.push('/admin/vabrusher/bonus/list')
    }

    const linkToGenerateScore = () => {
        routerHistory.push('/admin/vabrusher/generate_score')
    }


    return (
        <div className='flex flex-wrap justify-center items-center
            md:justify-between'>
            <div className='flex flex-col
                p-4'>
                <CustomP>
                    <AnimatedNumber value={nbMatch} formatValue={formatValue} /> matchs au total</CustomP>
                <CustomP>
                    <AnimatedNumber value={nbMatchThisMonth} formatValue={formatValue} /> matchs ce mois</CustomP>
                <CustomP>
                    <AnimatedNumber value={nbMatchToValid} formatValue={formatValue} /> matchs à valider</CustomP>
                <CustomP>
                    <AnimatedNumber value={nbPointThisMonth} formatValue={formatValue} /> points ce mois-ci</CustomP>
            </div>
            <div>
                <div className='flex flex-wrap sm:justify-end justify-center'>
                    <CustomLoadingButton
                        startIcon={<IconHub name='list-numbered' />}
                        onClick={linkToMatchList}>
                        Liste des matchs
                    </CustomLoadingButton>
                    <CustomLoadingButton
                        disabled outlined
                        startIcon={<IconHub name='star' />}
                        onClick={linkToBonusList}>
                        Liste des bonus</CustomLoadingButton>
                </div>
                <div className='flex flex-wrap sm:justify-end justify-center'>
                    <CustomLoadingButton
                        startIcon={<IconHub name='graph' />}
                        onClick={linkToGenerateScore}>
                        Générer les scores
                    </CustomLoadingButton>
                </div>
                <div className='flex flex-wrap sm:justify-end justify-center'>
                    <CustomLoadingButton disabled outlined>Tiers</CustomLoadingButton>
                    <CustomLoadingButton disabled outlined>Donjons</CustomLoadingButton>
                    <CustomLoadingButton disabled outlined>Quêtes</CustomLoadingButton>
                </div>
            </div>
        </div>
    )
}

const BotSection = () => {
    const [botState, setBotState] = useState('wait')
    const { session } = useContext(SessionContext)
    const { enqueueSnackbar } = useSnackbar()
    const [refresh, setRefresh] = useState(false)

    useInterval(() => {
        setRefresh(refresh !== true)
    }, 5000)

    useEffect(() => {
        axios.post('/api/bot_state', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            if (response.data.state) {
                setBotState('star2')
            } else {
                setBotState('death')
            }
        }).catch(() => {
            enqueueSnackbar("ERREUR SERVEUR - RECUPERATION DE L'ETAT DU BOT", PopMessageParams('error'))
        })

    }, [refresh])

    const rebootBot = () => {
        axios.post('/api/admin/reboot_bot', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            enqueueSnackbar(response.data, PopMessageParams('info'))
        }).catch(() => {
            enqueueSnackbar("Le bot n'a pas pu redémarrer", PopMessageParams('error'))
        })
    }

    return (
        <div className="flex items-center justify-around text-center">
            <div className="flex flex-col justify-center">
                <CustomP>Etat du bot:</CustomP>
                <IconHub name={botState} style={{ width: '50px', margin: 'auto' }} />
            </div>
            <CustomLoadingButton onClick={rebootBot}>Redémarrer</CustomLoadingButton>
        </div>
    )
}

const Admin = () => {
    const [refreshMemberSection, setRefreshMemberSection] = useState(false)
    const [refreshVabzeumSection, setRefreshVabzeumSection] = useState(false)
    const [refreshVabrusherSection, setRefreshVabrusherSection] = useState(false)

    return (
        <div className='flex-row justify-center'>
            <PageTitle>Panneau d'administration</PageTitle>
            <Section noRefresh sectionIcon={<IconHub name='locker' />} max_width={350} title='Gestion du bot'>
                <BotSection/>
            </Section>
            <Section callBack={setRefreshMemberSection} sectionIcon={<IconHub name='user2' />} className='mt-6 ' title='Gestion des utilisateurs'>
                <MemberSection refresh={refreshMemberSection} />
            </Section>
            <Section callBack={setRefreshVabzeumSection} sectionIcon={<IconHub name='swords' />} className='mt-6 ' title='Vabzeum'>
                <VabzeumSection refresh={refreshVabzeumSection} />
            </Section>
            <Section callBack={setRefreshVabrusherSection} sectionIcon={<IconHub name='dungeon2' />} className='mt-6 ' title='Vabrusher'>
                <VabrusherSection refresh={refreshVabrusherSection} />
            </Section>
        </div>
    );
}

const AdminPage = () => {
    return (
        <Access>
            <Admin />
        </Access>
    )
}


export default AdminPage