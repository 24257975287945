/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }] */


import { useContext, useEffect, useRef, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { ThemeContext } from "./context";
import { IconHub } from "./icon";
import { CustomP, themeStyle } from "./utils";

const SelectElem = (props) => {
    const [hover, setHover] = useState(false)

    return (
        <div
            style={{
                backgroundColor: hover ? (props.reverse ? props.theme.primary_lighter : props.theme.secondary_lighter) : (props.reverse ? props.theme.primary : props.theme.secondary),

            }}
            className='px-2'
            onMouseEnter={() => { setHover(true) }}
            onMouseOut={() => { setHover(false) }}
            onClick={() => { props.callBack(props.content) }}
        >
            {props.content}
        </div>
    )
}


export const CustomSelect = (props) => {
    const { theme } = useContext(ThemeContext)
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    var hover = useRef(false)

    useEffect(() => {
        var tmp_options = []
        const arrayOptions = Object.keys(props.options)
        if (props.options) {

            for (var i = 0; i < arrayOptions.length; i++) {
                tmp_options.push(<SelectElem key={i} index={i} content={arrayOptions[i]} theme={theme} callBack={props.callBack} reverse={props.reverse} />)
            }
        }
        setOptions(tmp_options)
    }, [props.placeHolder, props.options])


    const handleHover = () => {
        hover.current = true
        setOpen(true)
    }

    const handleUnHover = () => {
        hover.current = false
        setTimeout(() => {
            if (!hover.current) {
                setOpen(false)
            }
        }, props.unHoverDelay !== undefined ? props.unHoverDelay : 200);
    }

    return (
        <div style={themeStyle(props, theme)}
            className={`transition-all cursor-pointer relative
        m-1 flex justify-between p-2 ${props.className}`}
            onClick={() => { setOpen(open !== true) }}
            onMouseEnter={handleHover}
            onMouseLeave={handleUnHover}>
            <CustomP className='rounded-sm'>{props.current || props.placeHolder}</CustomP>
            <div style={{ transform: `rotateX(${!open ? "0" : "180"}deg)` }} className={`transition-all tranform duration-500`}>
                <IconHub className='discord' name='down-arrow' />
            </div>
            {open && <>
                <div
                    style={{
                        backgroundColor: theme.secondary,
                        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                        border: `2px solid ${theme.primary}`
                    }}
                    className='absolute top-12 z-10 left-0 w-full min-w-min p-2 rounded-sm'>
                    <Scrollbars autoHeight autoHeightMax={150}>
                        {options}
                    </Scrollbars>
                </div>
            </>}
        </div>
    )
}