/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import { ToggleButton } from "@mui/material";
import { errorThemeModel, ThemeContext } from "./context";

const CustomThemedOutlined = styled(ToggleButton)((props) => ({
    color: props.theme.primary_darker,
    backgroundColor: props.theme.secondary_darker,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.secondary_darker,
    },
    border: '0px',
    borderRadius: 0,
    opacity: 0.5
}));

const CustomThemedReverseOutlined = styled(ToggleButton)((props) => ({
    color: props.theme.secondary_darker,
    backgroundColor: props.theme.primary_darker,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.primary_darker,
    },
    border: '0px',
    borderRadius: 0,
    opacity: 0.5
}));

const CustomThemedOutlinedSelected = styled(ToggleButton)((props) => ({
    color: props.theme.secondary_lighter,
    backgroundColor: props.theme.primary_lighter,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.primary_lighter,
    },
    boxShadow: props.theme.boxInnerShadow,
    border: '0px',
    borderRadius: 0
}));

const CustomThemedReverseOutlinedSelected = styled(ToggleButton)((props) => ({
    color: props.theme.primary_lighter,
    backgroundColor: props.theme.secondary_lighter,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.secondary_lighter,
    },
    boxShadow: props.theme.boxInnerShadow,
    border: '0px',
    borderRadius: 0
}));

export const CustomToggleButton = (props) => {
    const [attr, setAttr] = useState(false)
    const { theme } = useContext(ThemeContext)

    const isSelected = props.value === props.isSelected

    useEffect(() => {
        var themeVariant = null
        if (props.variant) {
            themeVariant = errorThemeModel()
        }
        setAttr({
            disabled: props.disabled || false,
            value: props.value,
            theme: themeVariant || theme || undefined,
            size: props.size || 'medium',
            type: props.type || 'button',
            onClick: props.onClick || undefined,
            side: props.side || 'center',
            sx: { 
                width: props.width || undefined,
                // height: props.heigth || undefined
            },
            // disabled: props.disabled || undefined
        })
    }, [theme, props.disabled])

    return (
        <div className={`transition-all duration-500 opacity-${props.disabled ? '50' : '100'}`}>
            {/* selected: {isSelected ? 'oui' : 'non'} */}
            {attr && <>
                {isSelected && <>
                    {props.reverse && <>
                        <CustomThemedReverseOutlinedSelected {...attr}>{props.children}</CustomThemedReverseOutlinedSelected>
                    </>} {!props.reverse && <>
                        <CustomThemedOutlinedSelected {...attr}>{props.children}</CustomThemedOutlinedSelected>
                    </>}
                </>}
                {!isSelected && <>
                    {props.reverse && <>
                        <CustomThemedReverseOutlined {...attr}>{props.children}</CustomThemedReverseOutlined>
                    </>} {!props.reverse && <>
                        <CustomThemedOutlined {...attr}>{props.children}</CustomThemedOutlined>
                    </>}
                </>}
            </>}
        </div >
    )
}