/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from 'react'
import Access from '../../components/access.js'
import { SessionContext, ThemeContext } from '../../components/context.js'
import { PageTitle, CustomP, getStyledNumbers, getUserAvatarLink, getVabzeumScreenshotLink } from '../../components/utils.js'
import { IconHub } from '../../components/icon.js'
import { CustomToggleButton } from '../../components/toggleButton.js'
import axios from 'axios'
import { Avatar } from '@mui/material'
import { ScreenShotHandler } from "../../components/screenshotHandler.js";

const VabzeumToggleGroup = (props) => {
    const { theme } = useContext(ThemeContext)

    return (
        <div
            className='flex justify-center m-2'
        >
            <div
                className='flex'
                style={{
                    border: `2px solid ${theme.primary}`,
                    borderRadius: 5
                }}
            >
                {props.children}
            </div>
        </div>
    )
}


const AllyElem = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = getUserAvatarLink(element.avatar)
    const [hover, setHover] = useState(false)
    const size = props.size || 30
    return (
        <div>
            {element.show && <>
                <div
                    className='flex justify-around items-center cursor-pointer rounded-full items-center transition-all duration-800
                    mt-1 w-max mx-1 '
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        border: `2px solid ${theme.primary_darker}`,
                        boxShadow: theme.boxShadow
                    }}

                >
                    <div
                        onMouseEnter={() => { setHover(true) }}
                        onMouseLeave={() => { setHover(false) }}
                        className={`flex justify-start items-center
                     space-x-2 pr-1`}
                    >
                        <Avatar
                            style={{
                                width: size,
                                height: size
                            }}
                            src={pic}
                            className=''
                        />
                        <CustomP
                            reverse
                            className='pr-1'
                        >
                            {element.name}</CustomP>
                    </div>
                </div >
            </>}
        </div>

    )
}

const EmptyAllyElem = (props) => {
    const { theme } = useContext(ThemeContext)
    const pic = require('../../statics/images/icons/icon_sleeping.png')
    const size = props.size || 30
    return (
        <div>
            <div
                className='flex justify-around items-center cursor-not-allowed rounded-full items-center transition-all duration-800
                    mt-1 w-max mx-1 '
                style={{
                    border: `2px solid ${theme.primary_darker}`,
                    boxShadow: theme.boxShadow
                }}
            >
                <div
                    className={`flex justify-center items-center
                     pl-4 pr-4 space-x-2`}
                >
                    <Avatar
                        style={{
                            width: size,
                            height: size
                        }}
                        src={pic.default}
                    />
                </div>
            </div >
        </div>

    )
}

export const AllyElemMin = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = getUserAvatarLink(element.avatar)
    const [hover, setHover] = useState(false)
    const size = props.size || 30
    return (
        <div className={props.className}>
            {element.show && <>
                <div
                    onMouseEnter={() => { setHover(true) }}
                    onMouseLeave={() => { setHover(false) }}
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        border: `2px solid ${theme.primary_darker}`,
                        boxShadow: theme.boxShadow
                    }}
                    className={`flex m-1 pr-4 w-max space-x-2 cursor-pointer rounded-full items-center transition-all duration-800`}
                    onMouseDown={() => { props.onClick(element) }}
                >
                    <Avatar
                        style={{
                            width: size,
                            height: size
                        }}
                        src={pic}
                        className=''
                    />
                    <CustomP
                        reverse
                        className=''
                    >
                        {element.name}</CustomP>
                </div>
            </>}
        </div >

    )
}


const PlayerNbToggleButton = (props) => {
    return (
        <CustomToggleButton
            value={props.value}
            isSelected={props.isSelected}
            onClick={undefined}
            width={props.width}
        >{props.value}</CustomToggleButton>
    )
}

const SelectedAlliesDisplayer = (props) => {
    const [displayedAllies, setDisplayedAllies] = useState([])
    const { theme } = useContext(ThemeContext)

    const delAlly = (ally) => {
        const newSelectedAllies = ([...props.selectedAllies])
        newSelectedAllies.splice(props.selectedAllies.indexOf(ally), 1)
        props.setSelectedAllies(newSelectedAllies)
    }

    const handleDel = (element) => {
        delAlly(element)
    }

    useEffect(() => {
        const tmp_displayedAllies = []
        for (var i = 0; i < 5; i++) {
            if (props.selectedAllies.length > i) {
                tmp_displayedAllies.push(
                    <AllyElem disabled key={i} element={props.selectedAllies[i]} theme={theme} onClick={handleDel} />
                )
            } else {
                tmp_displayedAllies.push(
                    <EmptyAllyElem key={i} theme={theme} />
                )
            }
        }
        setDisplayedAllies(tmp_displayedAllies)
    }, [props.nbAllies, props.selectedAllies])

    return (
        <div className='flex flex-wrap justify-center space-x-1 z-10'>
            {displayedAllies}
        </div>
    )
}


const MatchPointDisplayer = (props) => {
    const [nbToShow, setNbToShow] = useState([])
    const [nbToShowGroup, setNbToShowGroup] = useState([])

    useEffect(() => {
        setNbToShow(getStyledNumbers(props.realScale, 'react', undefined, 25))
        setNbToShowGroup(getStyledNumbers(props.realScale * props.selectedAllies.length, 'react', undefined, 25))
    }, [props.realScale, props.selectedAllies])


    return (
        <div className='flex justify-center space-x-6'>
            <div className='flex flex-col justify-center'>
                <CustomP className='mx-auto'>Points{props.selectedAllies.length > 1 ? " par joueur" : ""}</CustomP>
                <div className='flex justify-center mt-2 h-8'>
                    {nbToShow && nbToShow.length > 0 && <>
                        {nbToShow}
                    </>}
                </div>
            </div>
            {props.selectedAllies.length > 1 && <>
                <div className='flex flex-col justify-center'>
                    <CustomP className='mx-auto'>Points totaux</CustomP>
                    <div className='flex justify-center mt-2 h-8'>
                        {nbToShowGroup && nbToShowGroup.length > 0 && <>
                            {nbToShowGroup}
                        </>}
                    </div>
                </div>
            </>}
        </div>
    )
}


export const CustomBonusElement = ({ bonus }) => {
    const { theme } = useContext(ThemeContext)
    const [hover, setHover] = useState(false)

    return (
        <div className='flex'>
            <div
                onMouseEnter={() => { setHover(true) }}
                onMouseLeave={() => { setHover(false) }}
                style={{
                    backgroundColor: hover ? theme.primary_lighter : theme.primary,
                    border: `2px solid ${theme.primary_darker}`,
                    boxShadow: theme.boxShadow
                }}
                className={`flex m-1 px-4 w-max space-x-2 cursor-pointer rounded-full items-center transition-all duration-800`}
            >
                <CustomP reverse>{bonus.name}</CustomP>
                <CustomP reverse>{bonus.operation}{bonus.value}</CustomP>
            </div>
        </div>
    )
}

const BonusList = (props) => {
    const { theme } = useContext(ThemeContext)
    const [bonusList, setBonusList] = useState([])

    useEffect(() => {
        var tmp_bonusList = []
        var i = 0
        props.bonusList.forEach(bonus => {
            tmp_bonusList.push(<CustomBonusElement key={`bonus_${i}`} bonus={bonus}/>)
            i += 1
        })
        setBonusList(tmp_bonusList)
    }, [theme, props.bonusList])

    return (
        <div
            className='mt-1 z-10'
        >
            <div className='flex flex-wrap mt-1 justify-center'>
                {bonusList}
            </div>
        </div>
    )
}


const VabzeumMatch = () => {
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)

    const [matchId, setMatchId] = useState(-1)
    const [side, setSide] = useState('défense')
    const [entity, setEntity] = useState('percepteur')
    const [nbAllies, setNbAllies] = useState(3)
    const [nbEnemies, setNbEnemies] = useState(5)
    const [selectedAllies, setSelectedAllies] = useState([])
    const [recorder, setRecorder] = useState(0)
    const [bonusList, setBonusList] = useState([])
    const [realScale, setRealScale] = useState(0)
    const [screenShotFile, setScreenShotFile] = useState(undefined)
    const screenShot = useRef(null)
    const backgroundImage1 = require("../../statics/images/classes/XelorF.png")

    useEffect(() => {
        var target = parseInt(new URLSearchParams(window.location.search).get('match'))
        axios.post('/api/vabzeum/get_match', {
            user_id: session.user_id,
            session_token: session.session_token,
            match_id: target
        }).then(response => {
            // console.log(response.data)
            const match = response.data.match_list[0]
            setMatchId(match.match_id)
            setSide(match.side)
            setEntity(match.entity)
            setNbAllies(match.nb_ally)
            setNbEnemies(match.nb_enemy)
            setRealScale(match.calculated_score)
            match.allies.forEach(ally => {
                ally['show'] = true
            })
            // console.log(match.allies)
            setSelectedAllies([...match.allies])
            // console.log(currentImg)
            setScreenShotFile(getVabzeumScreenshotLink(match.screenshot))
            document.getElementById('commentary').value = match.commentary
            setBonusList(match.bonus ? match.bonus : [])
            setRecorder(match.recorder)
        })
    }, [])

    const separatorStyle = {
        border: `2px solid ${theme.primary}`,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    const setScreenShotRef = (receivedScreenShot) => {
        screenShot.current = receivedScreenShot
    }


    return (
        <div className='flex flex-wrap justify-center mx-auto relative
        w-11/12
        '
        >
            <div style={{
                backgroundColor: theme.secondary,
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
            }}
                className='rounded-lg  relative
                    w-full flex-col items-center max-w-screen-lg'>
                <div>
                    <img
                        style={{
                            transform: 'rotateY(180deg)',
                            opacity: 0.4
                        }}
                        className='absolute right-0 z-0 transition-all duration-800'
                        src={backgroundImage1.default} alt='background_image_default' />
                </div>
                <div className=''>
                    <PageTitle reverse>Match Vabzeum ({matchId})</PageTitle>
                </div>
                <div className='w-11/12 m-auto flex-row'>
                    <div className='flex justify-center z-10'>
                        <CustomP>Id de l'enregistreur: {recorder ? recorder : "introuvable"}</CustomP>
                    </div>
                    <div className='flex flex-wrap justify-center items-center z-10'>
                        <VabzeumToggleGroup disabled>
                            <CustomToggleButton
                                // 
                                value="percepteur"
                                isSelected={entity}
                                width={140}
                            >
                                Percepteur
                                <IconHub name='vabzeum_perco' className='w-8' />
                            </CustomToggleButton>
                            <CustomToggleButton
                                // 
                                value="prisme"
                                isSelected={entity}
                                width={140}
                            >
                                Prisme
                                <IconHub name='vabzeum_prisme' className='w-8' />
                            </CustomToggleButton>
                        </VabzeumToggleGroup>
                        <VabzeumToggleGroup>
                            <CustomToggleButton
                                // 
                                value="attaque"
                                isSelected={side}
                                width={140}
                            >
                                Attaque
                                <IconHub name='vabzeum_atk' className='w-8' />
                            </CustomToggleButton>
                            <CustomToggleButton
                                // 
                                value="défense"
                                isSelected={side}
                                width={140}
                            >
                                Défense
                                <IconHub name='vabzeum_def' className='w-8' />
                            </CustomToggleButton>
                        </VabzeumToggleGroup>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex flex-wrap justify-center
                                    '>
                        <div className='text-center'>
                            <CustomP>Nombre d'alliés</CustomP>
                            <VabzeumToggleGroup>
                                <PlayerNbToggleButton
                                    value={1}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={2}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={3}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={4}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={5}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    width={56}
                                />
                            </VabzeumToggleGroup>
                        </div>
                        <div className='text-center'>
                            <CustomP>Nombre d'ennemis</CustomP>
                            <VabzeumToggleGroup>
                                <PlayerNbToggleButton
                                    value={1}
                                    isSelected={nbEnemies}
                                    width={56}
                                >1</PlayerNbToggleButton>
                                <PlayerNbToggleButton
                                    value={2}
                                    isSelected={nbEnemies}
                                    width={56}
                                >2</PlayerNbToggleButton>
                                <PlayerNbToggleButton
                                    value={3}
                                    isSelected={nbEnemies}
                                    width={56}
                                >3</PlayerNbToggleButton>
                                <PlayerNbToggleButton
                                    value={4}
                                    isSelected={nbEnemies}
                                    width={56}
                                >4</PlayerNbToggleButton>
                                <PlayerNbToggleButton
                                    value={5}
                                    isSelected={nbEnemies}
                                    width={56}
                                >5</PlayerNbToggleButton>
                            </VabzeumToggleGroup>
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex justify-center 
                                       '>
                        <SelectedAlliesDisplayer
                            selectedAllies={selectedAllies}
                            nbAllies={nbAllies}
                            setSelectedAllies={setSelectedAllies} />
                    </div>

                    <div className='flex flex-wrap justify-center'>
                        <div className='
                                        min-w-200 w-4/12'>
                            <CustomP className='text-center'>Bonus</CustomP>
                            <div className='flex justify-center'>
                                <BonusList
                                    disabled
                                    bonusList={bonusList}
                                    setBonusList={setBonusList}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex flex-wrap sm:space-x-12 justify-center'>
                        <div className='flex flex-col justify-center
                                        '>
                            <CustomP className='mx-auto'>ScreenShot</CustomP>
                            <ScreenShotHandler
                                preloadImg={screenShotFile}
                                setImage={setScreenShotFile}
                                setRef={setScreenShotRef}
                                preventReupload
                            />
                        </div>
                        <div className='flex flex-col justify-center w-max m-auto
                                        '>
                            <MatchPointDisplayer realScale={realScale} selectedAllies={selectedAllies} />
                        </div>
                        <div className='flex flex-col justify-center
                                        '>
                            <CustomP className='mx-auto'>Commentaire</CustomP>
                            <textarea
                                disabled
                                id='commentary'
                                style={{
                                    border: `2px solid ${theme.secondary}`,
                                    boxShadow: theme.boxShadow,
                                    background: `linear-gradient(0.12turn, ${theme.primary_darker}, ${theme.primary}, ${theme.primary_lighter})`,
                                    color: theme.secondary,
                                    carretColor: theme.secondary,
                                }}
                                rows="4"
                                cols="25"
                                className='rounded-sm p-2 focus:outline-none resize-none'
                            >
                            </textarea>
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                </div>
            </div>
        </div>
    )
}

const VabzeumMatchPage = () => {
    return (
        <Access>
            <VabzeumMatch />
        </Access>
    )
}

export default VabzeumMatchPage