/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
// import axios from 'axios';
import { NavLink } from "react-router-dom";
import Access from '../components/access.js'

class VabGazette extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <div className='flex justify-center'>
                <div>On est dans la VabGazettepage</div>
                <NavLink to='/vabzeum'>bouton vers vabzeum</NavLink>
            </div>
        );
    }
}

const VabGazettepage = () => {
    return (
        <Access>
            <VabGazette />
        </Access>
    )
}


export default VabGazettepage