/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }] */

import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { CustomP, PageTitle, PopMessageParams, Section, Pagination } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import { IconHub } from "../../components/icon.js";
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { NavLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import { CustomLoadingButton } from "../../components/button.js";

export const VabzeumMatchListElem = ({ match, ...props }) => {
    const [allyList, setAllyList] = useState([])
    const [bonusList, setBonusList] = useState([])
    const { theme } = useContext(ThemeContext)

    useEffect(() => {
        var i = 0
        var tmp_allyList = []
        match.allies.forEach(ally => {
            ally.show = true
            tmp_allyList.push(<CustomP key={i}>{ally.name}</CustomP>)
            i += 1
        })

        var tmp_bonusList = []
        var mult = 0
        var add = 0
        if (match.bonus) {
            match.bonus.reverse().forEach(bonus => {
                tmp_bonusList.push(<div key={i}>{bonus.name} ({bonus.operation}{bonus.value})</div>)
                i += 1
                if (bonus.operation === 'x') {
                    mult += bonus.value
                } else if (bonus.operation === '+') {
                    add += bonus.value
                }
            })
            tmp_bonusList.push(<div
                key={i}
                style={{
                    borderColor: theme.primary_darker
                }}
                className='border'
            >{`${mult ? 'x' + mult : ''}`} {`${add ? '+' + add : ''}`}</div>)
            setBonusList(tmp_bonusList.reverse())
        }
        setAllyList(tmp_allyList)
    }, [theme, props.page])

    return (
        <div style={{
            backgroundColor: props.index % 2 === 1 ? theme.secondary : theme.secondary_lighter,
            borderTop: props.index > 0 ? `1px solid ${theme.primary}` : ''
        }}
            className={`py-4 flex justify-around text-center items-center transition-all`}>
            <div className='flex justify-center
                w-1/12'>
                <CustomP>{match.match_id}</CustomP>
            </div>
            <div className='flex justify-center
                w-5/12
                sm:w-2/12
                md:w-1/12'>
                <CustomP>{allyList}</CustomP>
            </div>
            <div className='flex justify-center items-center
                w-4/12
                sm:w-3/12
                md:w-2/12
                lg:w-1/12'>
                <CustomP
                    className='flex justify-center space-x-1'>
                    <CustomP forceColor={'#23DC3D'}>{match.nb_ally}</CustomP>
                    <p>v</p>
                    <CustomP forceColor={'red'}>{match.nb_enemy}</CustomP>
                    <div className='flex'>(
                        <CustomP forceColor={theme.primary_lighter}>{match.nb_vab}</CustomP>
                    </div>
                    {') = '}
                    <CustomP>{match.diff}</CustomP>
                </CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                sm:block sm:w-1/12'>
                <div className='flex justify-center'>
                    <IconHub name={`vabzeum_${match.side === 'attaque' ? 'atk' : 'def'}`} className='w-8' />
                </div>
            </div>
            <div className='flex justify-center items-center
                hidden
                sm:block sm:w-1/12'>
                <div className='flex justify-center'>
                    <IconHub name={`vabzeum_${match.entity === 'percepteur' ? 'perco' : 'prisme'}`} className='w-8' />
                </div>
            </div>
            <div className='flex justify-center items-center
                hidden
                lg:block lg:w-1/12'>
                <CustomP>{bonusList}</CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                md:block md:w-1/12'>
                <CustomP>{match.score}</CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                md:block md:w-1/12'>
                <CustomP>{match.calculated_score}</CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                sm:block sm:w-1/12'>
                <CustomP>{match.admin_validator ? <IconHub fontSize='large' name='checkbox-circle' /> : <IconHub fontSize='large' name='crossbox' />}</CustomP>

            </div>
            <div className='flex justify-center items-center
                hidden
                sm:block sm:w-1/12'>
                <CustomP>{match.bot_checker ? <IconHub fontSize='large' name='checkbox-circle' /> : <IconHub fontSize='large' name='crossbox' />}</CustomP>
            </div>
            <div className='flex justify-center items-center
                hidden
                sm:block sm:w-1/12'>
                <CustomP>{match.created}</CustomP>
            </div>
            <div
                className='flex justify-center items-center
                w-2/12
                sm:w-1/12'>
                {(!props.notAdmin) &&  <>
                    <NavLink to={`/admin/vabzeum/match?match=${match.match_id}`}>
                        <CustomP className={`cursor-pointer`}><IconHub fontSize='large' name='edit' /></CustomP>
                    </NavLink>
                </>}
                {(props.notAdmin) &&  <>
                    <NavLink to={`/vabzeum/match?match=${match.match_id}`}>
                        <CustomP className={`cursor-pointer`}><IconHub fontSize='large' name='search' /></CustomP>
                    </NavLink>
                </>}
            </div>
        </div>
    )
}

const AdminVabzeumMatchList = () => {
    const [_matchList, setMatchList] = useState([])
    const [renderedMatchList, setRenderedMatchList] = useState([])
    const [showOnlyMatchToValid, setShowOnlyMatchToValid] = useState(true)
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(0)
    const [maxPage, setMaxPage] = useState(1)
    const [nbMatch, setNbMatch] = useState(0)
    const [mode, setMode] = useState(undefined)
    const nbPerPage = 50

    const refreshVabzeumMatchList = (currPage) => {
        if (!Number.isInteger(currPage)) {
            currPage = page
        }
        axios.post('/api/vabzeum/get_match', {
            user_id: session.user_id,
            session_token: session.session_token,
            page: currPage,
            nbPerPage: nbPerPage,
            showOnlyMatchToValid: showOnlyMatchToValid
        }).then(response => {
            setMatchList(response.data)

            var renderedMatch = []
            for (var i = 0; i < response.data.match_list.length; i++) {
                renderedMatch.push(<VabzeumMatchListElem rowLen={response.data.match_list.length} key={i} index={i} match={response.data.match_list[i]} theme={theme} page={page} />)
            }
            // console.log(response.data)
            setMaxPage(response.data.max_page)
            setNbMatch(response.data.nb_match)
            setMode(response.data.to_valid)
            setRenderedMatchList(renderedMatch)
            setPage(currPage)
        }).catch(() => {
            enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
        })
    }

    useEffect(() => {
        refreshVabzeumMatchList(1)
    }, [showOnlyMatchToValid])

    // const [searchIndex, setSearchIndex] = useState(false)
    // const options = { 'Id': 0, 'Pseudo': 1, 'Id discord': 2, 'Activé': 3, 'Roles': 4, 'Personnages': 5 }

    // const handleSearchIndex = (index) => {
    //     setSearchIndex(index)
    // }

    // const searchForUser = (search) => {
    //     if (search.length === 0) {
    //         setMatchRenderedList([...matchList])
    //     }
    //     else {
    //         const tmpRenderedUserList = []
    //         for (const user in matchList) {
    //             const key = options[searchIndex]
    //             if (['Id', 'Pseudo', 'Id discord'].indexOf(searchIndex) !== -1) {
    //                 if (String(matchList[user][key]).toLowerCase().indexOf(String(search).toLowerCase()) !== -1) {
    //                     tmpRenderedUserList.push(matchList[user])
    //                 }
    //             } else if (['Roles', 'Personnages'].indexOf(searchIndex) !== -1) {
    //                 for (const elem in matchList[user][key]) {
    //                     if (String(matchList[user][key][elem]).toLowerCase().indexOf(String(search).toLowerCase()) !== -1) {
    //                         tmpRenderedUserList.push(matchList[user])
    //                     }
    //                 }
    //             } else if (['Activé'].indexOf(searchIndex) !== -1) {
    //                 if ((!matchList[user][key] && !search.length) || (matchList[user][key] && search.length)) {
    //                     tmpRenderedUserList.push(matchList[user])
    //                 }
    //             }
    //         }
    //         if (tmpRenderedUserList.length) {
    //             setMatchRenderedList(tmpRenderedUserList)
    //         }
    //     }
    // }

    const changePage = (newPage) => {
        if (newPage > 0 && newPage < (nbMatch / nbPerPage) + 1) {
            refreshVabzeumMatchList(newPage)
        }
    }

    const switchShowOnlyMatchToValid = () => {
        setShowOnlyMatchToValid(showOnlyMatchToValid !== true)
    }

    return (
        <div className='flex-row justify-center mb-8'>
            <PageTitle>Liste des matchs</PageTitle>
            {/* <Section title='Recherche' max_width={600} className='mb-10' noRefresh>
                <div>
                    <div className='flex flex-wrap justify-center items-center'>
                        <CustomSelect
                            className='w-48 mt-6'
                            outlined
                            placeHolder={'Index de recherche'}
                            current={searchIndex}
                            options={options}
                            callBack={handleSearchIndex} />
                        {searchIndex && <>
                            <Formik
                                initialValues={{ search: '' }}
                                onSubmit={(values) => { searchForUser(values.search) }}>
                                <Form>
                                    <div className='flex items-center'>
                                        <CustomTextInput
                                            autoComplete='off'
                                            name="search"
                                            type="search"
                                            placeholder="Recherche"
                                        />
                                        <CustomIconButton
                                            className='mt-7'
                                            type='submit'
                                            reverse={false}
                                        >
                                            <IconHub 
                                                name='search' />
                                        </CustomIconButton>
                                    </div>
                                </Form>
                            </Formik>
                        </>}
                    </div>
                </div>
            </Section> */}
            <Section max_width={1600} callBack={refreshVabzeumMatchList} className=''
                title={`${!mode ? "Tous les matchs" : "Matchs non validés"} (${nbMatch})`}>
                <div className='w-full'>
                    <div className="flex flex-wrap justify-center">
                        <CustomLoadingButton
                            className='mx-10'
                            onClick={switchShowOnlyMatchToValid}
                            forceRefresh={[showOnlyMatchToValid]}
                        >{`${!mode ? "Voir les matchs non validés" : "Voir tous les matchs"}`}</CustomLoadingButton>
                        <Pagination page={page} nbPerPage={nbPerPage} maxPage={maxPage} nbElem={nbMatch} changePage={changePage} />
                    </div>
                    <div className=''>
                        <div style={{ borderBottom: `2px solid ${theme.primary}` }}
                            className='border-b-2 flex justify-around items-center text-center h-12'>
                            <CustomP className='
                            w-1/12'>Id</CustomP>
                            <CustomP className='
                            w-5/12
                            sm:w-2/12
                            md:w-1/12'>Alliés</CustomP>

                            <CustomP className='
                            w-4/12
                            sm:w-3/12
                            md:w-2/12
                            lg:w-1/12'>Récap</CustomP>

                            <CustomP className='
                            hidden
                            sm:block sm:w-1/12
                            '>Atk Def</CustomP>

                            <CustomP className='
                            hidden
                            sm:block sm:w-1/12
                            '>Perco Prisme</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-1/12
                            '>Bonus</CustomP>

                            <CustomP className='
                            hidden
                            md:block md:w-1/12
                            '>Score</CustomP>

                            <CustomP className='
                            hidden
                            md:block md:w-1/12
                            '>Score Calculé</CustomP>

                            <CustomP
                                className='
                            hidden
                            sm:block sm:w-1/12
                            '>Vérifié</CustomP>

                            <CustomP
                                className='
                            hidden
                            sm:block sm:w-1/12
                            '>Upload Bot</CustomP>

                            <CustomP
                                className='
                            hidden
                            sm:block sm:w-1/12
                            '>Date</CustomP>

                            <CustomP
                                className='
                            w-2/12
                            sm:w-1/12
                            '></CustomP>

                        </div>
                    </div>
                    <Scrollbars autoHide autoHeight autoHeightMax={window.innerHeight - 350}>
                        <div style={{
                            borderColor: theme.secondary,
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                        }}
                            v-for="item in items" className='w-full transform even:bg-gray-400'>
                            {renderedMatchList}
                        </div>
                    </Scrollbars>
                </div>
            </Section >
        </div >
    );
}

const AdminVabzeumMatchListPage = () => {
    return (
        <Access>
            <AdminVabzeumMatchList />
        </Access>
    )
}

export default AdminVabzeumMatchListPage