/* eslint-disable react-hooks/exhaustive-deps */

import { SessionContext, ThemeContext } from './context'
import axios from 'axios'
import { useContext, useEffect, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { CustomNavLink, getUserAvatarLink } from './utils'
import Avatar from '@mui/material/Avatar';

const TopBarDropDown = (props) => {
    return (
        <NavLink to={props.link}>
            <div className='hover:bg-gray-900 rounded-md transition-all duration-300
        py-2 p-2 '>
                {props.name}
            </div>
        </NavLink>
    )
}


const TopBarProfile = (props) => {
    const { session } = useContext(SessionContext)
    const [showDropDown, setShowDropDown] = useState(false)
    const [enabled, setEnabled] = useState(true)
    var hover = useRef(false)
    
    var pic
    pic = getUserAvatarLink(session.avatar)
    if (!pic) {
        pic = require('../statics/images/users/icon_profile_default.png').default
    }
    const avatar = pic
    
    // useEffect(() => {
    //     setAvatar(pic.default)
    // }, [])

    useEffect(() => {
        axios.post("/api/get_navbar_title_info", { 
            route: props.link,
            user_id: session.user_id,
            session_token: session.session_token
         })
            .then(response => {
                setEnabled(response.data['enabled'])
            })
            .catch(() => {
                console.log('error')
            })
    }, [])

    const handleHover = () => {
        hover.current = true
        props.switchOpenenedDropDown(props.content)
        setShowDropDown(true)
    }

    const handleUnHover = () => {
        hover.current = false
        setTimeout(() => {
            if (!hover.current) {
                setShowDropDown(false)
            }
        }, 400);
    }

    const style = {
        opacity: enabled ? '1' : '0.5',
    }
    return (
        <div style={style}
            onMouseEnter={() => { handleHover() }}
            onMouseLeave={() => { handleUnHover() }}
        >
            <div className='text-vab-white_darker hover:text-vab-white hover:bg-gray-900 rounded-lg
                        flex items-center transition-all rounded-full justify-center
                        m-auto w-max
                        '
            >
                <CustomNavLink link={props.link} enabled={enabled} noLinkOnMobile={true}
                    className='flex items-center justify-center cursor-pointer'>
                    {props.content.length > 0 && <>
                        <div className='hidden w-max font-bold
                        transition-all
                        lg:block lg:pl-2'>
                            {props.content}
                        </div>
                    </>}
                    <div className='flex justify-center
                        sm:pl-4
                        sm:w-8
                        lg:w-8 lg:mr-2'>
                        {/* picture */} 
                        <Avatar alt={props.content} src={avatar}/>
                    </div>
                </CustomNavLink>
                {enabled && props.openenedDropDown === props.content && showDropDown && props.dropdown && props.dropdown.length > 1 && <>
                    <div className=' bg-gray-800 rounded-md border border-vab-white shadow-2xl
                            absolute 
                            right-1 top-1 mt-12
                            '>
                        {props.dropdown.map((elem, key) => <TopBarDropDown key={key} name={elem} link={props.dropdownLinks[key]} />)}
                    </div>
                </>}
            </div>
        </div>
    )
}

const StatusTopBar = (props) => {
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [connectedUser, setConnectedUser] = useState(null)
    const pic = require('../statics/images/icons/icon_right_arrow.png')

    useEffect(() => {
        setConnectedUser(session.pseudo)
    }, [session.pseudo])

    return (
        <div style={{
            borderColor: theme.secondary,
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
        }}
            className='fixed z-9950 w-full h-12 transition-all bg-gray-800 flex justify-between items-center border-b-2
                pr-4
                    '>
            <div className='rounded transition-all
              m-1
              sm:w-14
              lg:w-36 lg:ml-2'
                onClick={() => { props.switchRetracted() }} >
                <img src={pic.default} alt='retract_button'
                    style={{ transform: `rotateY(${props.retracted ? "0" : "180"}deg)` }}
                    className={`transform transition-all duration-1500 cursor-pointer\
                        w-7  m-auto`}
                />
            </div>
            <TopBarProfile
                content={connectedUser ? connectedUser : 'Profil'}
                type={connectedUser ? connectedUser : 'profile_default'}
                link={connectedUser ? '/user/profile' : '/login'}
                dropdown={connectedUser ? ['Mon profil', 'Deconnexion'] : []}
                dropdownLinks={connectedUser ? ['/user/profile', '/logout'] : []}
                switchOpenenedDropDown={props.switchOpenedDropDown}
                openenedDropDown={props.openedDropDown} />
        </div>
    )
}

export default StatusTopBar