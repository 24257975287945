/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from 'react'
import Access from '../../components/access.js'
import { ModalContext, SessionContext, ThemeContext } from '../../components/context.js'
import { PageTitle, CustomP, PopMessageParams, CustomCheckBox, getStyledNumbers, CustomModal, getUserAvatarLink, getVabzeumScreenshotLink } from '../../components/utils.js'
import { IconHub } from '../../components/icon.js'
import { CustomToggleButton } from '../../components/toggleButton.js'
import axios from 'axios'
import { Avatar } from '@mui/material'
import { CustomLoadingButton } from "../../components/button.js";
import { useSnackbar } from "notistack";
import { ScreenShotHandler } from "../../components/screenshotHandler.js";
import { CustomIconButton } from '../../components/iconButton.js'
import { useHistory } from 'react-router-dom'




const VabzeumToggleGroup = (props) => {
    const { theme } = useContext(ThemeContext)

    return (
        <div
            className='flex justify-center m-2'
        >
            <div
                className='flex'
                style={{
                    border: `2px solid ${theme.primary}`,
                    borderRadius: 5
                }}
            >
                {props.children}
            </div>
        </div>
    )
}


const AllyElem = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = getUserAvatarLink(element.avatar)
    const [hover, setHover] = useState(false)
    const size = props.size || 30
    return (
        <div>
            {element.show && <>
                <div
                    className='flex justify-around items-center cursor-pointer rounded-full items-center transition-all duration-800
                    mt-1 w-max mx-1 '
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        border: `2px solid ${theme.primary_darker}`,
                        boxShadow: theme.boxShadow
                    }}

                >
                    <div
                        onMouseEnter={() => { setHover(true) }}
                        onMouseLeave={() => { setHover(false) }}
                        className={`flex justify-start items-center
                     space-x-2 pr-1`}
                        onMouseDown={() => { props.onClick(element) }}
                    >
                        <Avatar
                            style={{
                                width: size,
                                height: size
                            }}
                            src={pic}
                            className=''
                        />
                        <CustomP
                            reverse
                            className='pr-1'
                        >
                            {element.name}</CustomP>
                    </div>
                    {/* <CustomP
                        onMouseDown={() => { props.onClick(element) }}
                        reverse
                        className=''>
                        <IconHub name='crossbox' />
                    </CustomP> */}
                </div >
            </>}
        </div>

    )
}

const EmptyAllyElem = (props) => {
    const { theme } = useContext(ThemeContext)
    const pic = require('../../statics/images/icons/icon_sleeping.png')
    const size = props.size || 30
    return (
        <div>
            <div
                className='flex justify-around items-center cursor-not-allowed rounded-full items-center transition-all duration-800
                    mt-1 w-max mx-1 '
                style={{
                    border: `2px solid ${theme.primary_darker}`,
                    boxShadow: theme.boxShadow
                }}
            >
                <div
                    className={`flex justify-center items-center
                     pl-4 pr-4 space-x-2`}
                >
                    <Avatar
                        style={{
                            width: size,
                            height: size
                        }}
                        src={pic.default}
                    />
                </div>
            </div >
        </div>

    )
}

export const AllyElemMin = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = getUserAvatarLink(element.avatar)
    const [hover, setHover] = useState(false)
    const size = props.size || 30
    return (
        <div className={props.className}>
            {element.show && <>
                <div
                    onMouseEnter={() => { setHover(true) }}
                    onMouseLeave={() => { setHover(false) }}
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        border: `2px solid ${theme.primary_darker}`,
                        boxShadow: theme.boxShadow
                    }}
                    className={`flex m-1 pr-4 w-max space-x-2 cursor-pointer rounded-full items-center transition-all duration-800`}
                    onMouseDown={() => { props.onClick(element) }}
                >
                    <Avatar
                        style={{
                            width: size,
                            height: size
                        }}
                        src={pic}
                        className=''
                    />
                    <CustomP
                        reverse
                        className=''
                    >
                        {element.name}</CustomP>
                </div>
            </>}
        </div >

    )
}


const CustomSearchInput = (props) => {
    const { theme } = useContext(ThemeContext)
    const [hover, setHover] = useState(false)
    const [renderedList, setRenderedList] = useState(false)
    const hoverRef = useRef(false)

    const addAlly = (ally) => {
        if (props.selectedAllies.indexOf(ally) === -1) {
            const newSelectedAllies = ([...props.selectedAllies]).concat([ally])
            props.setSelectedAllies(newSelectedAllies)
            if (newSelectedAllies.length > props.nbAllies) {
                props.setNbAllies(newSelectedAllies.length)
            }
            document.getElementById("searchAllies").value = ''
        }
    }

    const handleHover = () => {
        if (props.selectedAllies.length < 5) {
            hoverRef.current = true
            setHover(true)
        }
    }

    const handleUnHover = () => {
        hoverRef.current = false
        setTimeout(() => {
            if (!hoverRef.current) {
                setHover(false)
            }
        }, 400);
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault()
            setTimeout(() => {
                const value = document.getElementById("searchAllies").value
                if (renderedList.length === 1) {
                    addAlly(renderedList[0].props.element)
                } else {
                    renderedList.forEach(reactElement => {
                        if (reactElement.props.element.name.toLowerCase() === value.toLowerCase()) {
                            addAlly(reactElement.props.element)
                        }
                    })
                }
            }, 50);
        } else {
            setTimeout(() => {
                const newList = []
                const value = document.getElementById("searchAllies").value
                props.alliesList.forEach(element => {
                    var allreadyAdded = false
                    if (props.selectedAllies.length) {
                        props.selectedAllies.forEach(selectedElem => {
                            if (element.name === selectedElem.name) {
                                allreadyAdded = true
                            }
                        })
                    }
                    if (!allreadyAdded) {
                        if (element.name.toLowerCase().startsWith(value.toLowerCase()) || value.length === 0) {
                            newList.push(
                                <AllyElemMin key={element.id} element={element} theme={theme} onClick={handleAdd} />)
                        }
                    }
                })
                setRenderedList([...newList])
                newList.forEach(reactElement => {
                    const element = reactElement.props.element
                    var allreadyAdded = false
                    if (props.selectedAllies.length) {
                        props.selectedAllies.forEach(selectedElem => {
                            if (element.name === selectedElem.name) {
                                allreadyAdded = true
                            }
                        })
                    }
                    if (!allreadyAdded) {
                        if (element.name.toLowerCase().startsWith(value.toLowerCase()) || value.length === 0) {
                            if (element.name.toLowerCase() === value.toLowerCase() && newList.length === 1) {
                                addAlly(element)
                            }
                            if (element.name.toLowerCase() === value.toLowerCase() && newList.length === 1) {
                                addAlly(element)
                            }
                        }
                    }
                })
            }, 50);
        }
    }

    const handleAdd = (element) => {
        if (props.selectedAllies.length < 5) {
            addAlly(element)
        }
    }

    useEffect(() => {
        var tmpRenderedList = []
        for (var i = 0; i < props.alliesList.length; i++) {
            var allreadyAdded = false
            if (props.selectedAllies.length) {
                for (var j = 0; j < props.selectedAllies.length; j++) {
                    if (props.alliesList[i].name === props.selectedAllies[j].name) {
                        allreadyAdded = true
                    }
                }
            }
            if (!allreadyAdded) {
                tmpRenderedList.push(
                    <AllyElemMin key={i} element={props.alliesList[i]} theme={theme} onClick={handleAdd} />)
            }
        }
        setRenderedList(tmpRenderedList)
        if (props.selectedAllies.length === 5) {
            setHover(false)
        }
    }, [props.selectedAllies, props.alliesList, props.nbAllies])

    const inputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        opacity: props.selectedAllies.length < 5 ? 1 : 0.5
    }

    return (
        <div
            className='w-full flex flex-wrap justify-center items-start mt-1'
            onMouseEnter={() => { handleHover(true) }}
            onMouseLeave={() => { handleUnHover(false) }}
        >
            <div className='flex flex-col justify-center relative
                '

            >
                <input
                    autoComplete='off'
                    id={props.id}
                    label={props.id}
                    style={inputStyle}
                    className='focus:outline-none transition-all duration-800
                        p-2'
                    onKeyDown={handleKeyDown}
                    disabled={props.selectedAllies.length < 5 ? false : true}
                    placeholder='pseudo VàB'
                    onClick={() => { handleHover(true) }}

                />
                {hover && renderedList && renderedList.length && <>
                    {/* <div className='absolute w-max z-9998 top-0'> */}
                    <div
                        style={{
                            height: 180,
                            border: `2px solid ${theme.secondary_lighter}`,
                            boxShadow: 'inset 0px -10px 10px -10px #000000',
                            opacity: props.selectedAllies.length < 5 ? 1 : 0.5,
                            background: `repeating-linear-gradient(140deg, ${theme.primary_lighter}, ${theme.primary_lighter} 25px, ${theme.primary} 25px, ${theme.primary} 40px)`
                        }}
                        className={`absolute top-11 z-10 rounded-b-md transition-all duration-800 overflow-y-auto overflow-x-hidden
                        w-full`}>
                        {renderedList}
                    </div>
                    {/* </div> */}
                </>}
            </div>
        </div >
    )
}


const PlayerNbToggleButton = (props) => {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        const newDisabled = props.value >= props.selectedAllies.length ? false : true
        setDisabled(newDisabled)

    }, [props.selectedAllies, props.nbAllies])

    return (
        <CustomToggleButton
            value={props.value}
            isSelected={props.isSelected}
            onClick={disabled ? undefined : props.onClick}
            width={props.width}
            disabled={disabled}
        >{props.value}</CustomToggleButton>
    )
}

const SelectedAlliesDisplayer = (props) => {
    const [displayedAllies, setDisplayedAllies] = useState([])
    const { theme } = useContext(ThemeContext)

    const delAlly = (ally) => {
        const newSelectedAllies = ([...props.selectedAllies])
        newSelectedAllies.splice(props.selectedAllies.indexOf(ally), 1)
        props.setSelectedAllies(newSelectedAllies)
    }

    const handleDel = (element) => {
        delAlly(element)
    }

    useEffect(() => {
        const tmp_displayedAllies = []
        for (var i = 0; i < 5; i++) {
            if (props.selectedAllies.length > i) {
                tmp_displayedAllies.push(
                    <AllyElem key={i} element={props.selectedAllies[i]} theme={theme} onClick={handleDel} />
                )
            } else {
                tmp_displayedAllies.push(
                    <EmptyAllyElem key={i} theme={theme} />
                )
            }
        }
        setDisplayedAllies(tmp_displayedAllies)
    }, [props.nbAllies, props.selectedAllies])

    return (
        <div className='flex flex-wrap justify-center space-x-1 z-10'>
            {displayedAllies}
        </div>
    )
}


const MatchPointDisplayer = (props) => {
    const [nbToShow, setNbToShow] = useState([])
    const [nbToShowGroup, setNbToShowGroup] = useState([])

    useEffect(() => {
        setNbToShow(getStyledNumbers(props.realScale, 'react', undefined, 25))
        setNbToShowGroup(getStyledNumbers(props.realScale * props.selectedAllies.length, 'react', undefined, 25))
    }, [props.realScale, props.selectedAllies])


    return (
        <div className='flex justify-center space-x-6'>
            <div className='flex flex-col justify-center'>
                <CustomP className='mx-auto'>Points{props.selectedAllies.length > 1 ? " par joueur" : ""}</CustomP>
                <div className='flex justify-center mt-2 h-8'>
                    {nbToShow && nbToShow.length > 0 && <>
                        {nbToShow}
                    </>}
                </div>
            </div>
            {props.selectedAllies.length > 1 && <>
                <div className='flex flex-col justify-center'>
                    <CustomP className='mx-auto'>Points totaux</CustomP>
                    <div className='flex justify-center mt-2 h-8'>
                        {nbToShowGroup && nbToShowGroup.length > 0 && <>
                            {nbToShowGroup}
                        </>}
                    </div>
                </div>
            </>}
        </div>
    )
}


const TotalBonusDisplayer = (props) => {
    const [add, setAdd] = useState(undefined)
    const [mult, setMult] = useState(undefined)

    useEffect(() => {
        setAdd(getStyledNumbers(props.bonusValue.add, 'react', '+', 18))
        setMult(getStyledNumbers(props.bonusValue.mult, 'react', 'x', 18))
    }, [props.bonusValue])

    return (
        <div className='flex space-x-4'>
            {props.bonusValue.mult > 0 && <>
                <div className='flex h-6'>
                    {mult}
                </div>
            </>}
            {props.bonusValue.add > 0 && <>
                <div className='flex h-6'>
                    {add}
                </div>
            </>}
        </div>
    )
}

export function errorPlayer(selectedAllies, alliesList) {
    var check = false
    selectedAllies.forEach(allyToCheck => {
        var count = 0
        selectedAllies.forEach(ally => {
            // console.log("check -> ", allyToCheck.id, allyToCheck.name, ' // ally -> ', ally.id, ally.name)
            if (allyToCheck.id === ally.id || allyToCheck.name === ally.name) {
                if (!count) {
                    count += 1
                } else {
                    check = true
                }
            }
        })
    })
    // console.log("premier -> ", check)
    if (!check) {
        var BreakException = {}
        try {
            selectedAllies.forEach(ally => {
                var allyExists = false
                alliesList.forEach(allyToCheck => {
                    // console.log("check -> ", allyToCheck.id, allyToCheck.name, ' // ally -> ', ally.id, ally.name, ally.show)
                    if (allyToCheck.id === ally.id && allyToCheck.name === ally.name && ally.show) {
                        allyExists = true
                    }
                })
                if (!allyExists) {
                    // console.log("ally not exists => ", ally)
                    throw BreakException
                }
            })
        }
        catch (e) {
            if (e !== BreakException) {
                throw e
            }
            check = true
            // console.log('deuxieme -> ', check)
        }
    }
    return check
}

export const CustomBonusElement = ({ bonus, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const [hover, setHover] = useState(false)


    return (
        <div className='flex'>
            <div
                onMouseEnter={() => { setHover(true) }}
                onMouseLeave={() => { setHover(false) }}
                style={{
                    backgroundColor: hover ? theme.primary_lighter : theme.primary,
                    border: `2px solid ${theme.primary_darker}`,
                    boxShadow: theme.boxShadow
                }}
                className={`flex m-1 px-4 w-max space-x-2 cursor-pointer rounded-full items-center transition-all duration-800`}
                onClick={() => { props.delBonus(bonus) }}
            >
                <CustomP reverse>{bonus.name}</CustomP>
                <CustomP reverse>{bonus.operation}{bonus.value}</CustomP>
            </div>
        </div>
    )
}

const BonusListEditor = (props) => {
    const { theme } = useContext(ThemeContext)
    const [bonusList, setBonusList] = useState([])
    const { enqueueSnackbar } = useSnackbar()


    useEffect(() => {
        var tmp_bonusList = []
        var i = 0
        props.bonusList.forEach(bonus => {
            tmp_bonusList.push(<CustomBonusElement key={`bonus_${i}`} bonus={bonus} delBonus={delBonus} />)
            i += 1
        })
        setBonusList(tmp_bonusList)
    }, [theme, props.bonusList])

    const nameInputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        width: '22%',
        minWidth: '74px'
    }

    const operationInputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        width: '10%',
        minWidth: '30px'
    }

    const valueInputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        width: '22%',
        minWidth: '68px'
    }

    const addBonus = () => {
        const name = document.getElementById('new_bonus_name').value
        const operation = document.getElementById('new_bonus_operation').value
        var value = document.getElementById('new_bonus_value').value
        if (isNaN(parseFloat(value))) {
            value = parseInt(value)
        } else {
            value = parseFloat(value)
        }

        if (!name) {
            enqueueSnackbar("Il manque le nom au bonus", PopMessageParams('error'))
        } else if (isNaN(value)) {
            enqueueSnackbar("La valeur du bonus est numerique (ex: 2, 2.5)", PopMessageParams('error'))
        } else if (!value) {
            enqueueSnackbar("Il manque la valeur du bonus", PopMessageParams('error'))
        } else if (['x', '+'].indexOf(operation) === -1) {
            enqueueSnackbar("L'opérateur du bonus peut être '+' ou 'x'", PopMessageParams('error'))
        } else {

            const tmp_bonusList = [...props.bonusList]
            // if (operation === 'x' && parseInt(value) === 0) {
            //     value += 1
            // }
            
            // value = Math.round(value * 100) / 100
            // console.log('vlaue avant -> ', value)
            // console.log('tmp-> ', tmp_bonusList)
            tmp_bonusList.push({ 'name': name, 'operation': operation, 'value': value })
            // tmp_bonusList.value = Math.round(value * 100) / 100
            props.setBonusList(tmp_bonusList)
            document.getElementById('new_bonus_name').value = ''
            document.getElementById('new_bonus_operation').value = 'x'
            document.getElementById('new_bonus_value').value = ''
            // console.log('tmp-> ', tmp_bonusList)
        }
    }

    const delBonus = (bonus) => {
        // console.log(bonus)
        const tmp_bonusList = [...props.bonusList]
        tmp_bonusList.splice(tmp_bonusList.indexOf(bonus), 1)
        props.setBonusList(tmp_bonusList)
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            addBonus()
        }
    }

    const handleOperationKeyDown = (e) => {
        if (e.keyCode !== 9) {
            e.preventDefault()
        }
        if (e.key === '+' || e.key === 'x') {
            document.getElementById('new_bonus_operation').value = e.key
        }
    }

    return (
        <div
            className='mt-1 z-10'
            onKeyDown={handleKeyDown}>
            <div className='flex flex-wrap space-x-2 justify-center'>
                <input
                    id='new_bonus_name'
                    style={nameInputStyle}
                    className='focus:outline-none transition-all duration-800
                    p-2'
                    placeholder='Nom'
                />
                <input
                    onKeyDown={handleOperationKeyDown}
                    id='new_bonus_operation'
                    style={operationInputStyle}
                    className='focus:outline-none transition-all duration-800
                        p-2'
                    placeholder='+ x'
                    defaultValue='x'
                />
                <input
                    id='new_bonus_value'
                    style={valueInputStyle}
                    className='focus:outline-none transition-all duration-800
                        p-2'
                    placeholder='Valeur'
                />
                <CustomIconButton
                    className=''
                    reverse={false}
                    onClick={addBonus}>
                    <IconHub name='add-circle-filled' />
                </CustomIconButton>
            </div>
            <div className='flex flex-wrap mt-1 justify-center'>
                {bonusList}
            </div>
        </div>
    )
}

const AdminVabzeumMatchModify = () => {
    const { _modal, setModal } = useContext(ModalContext)
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)

    const [matchId, setMatchId] = useState(-1)
    const [side, setSide] = useState('défense')
    const [entity, setEntity] = useState('percepteur')
    const [nbAllies, setNbAllies] = useState(3)
    const [nbEnemies, setNbEnemies] = useState(5)
    const [selectedAllies, setSelectedAllies] = useState([])
    const [recorder, setRecorder] = useState(0)
    
    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [askDeleteValidation, setAskDeleteValidation] = useState(false)

    const [bonusList, setBonusList] = useState([])
    const [bonusValue, setBonusValue] = useState({ mult: 0, add: 0 })

    const [adminValidator, setAdminValidator] = useState(false)
    const [botChecker, setBotChecker] = useState(false)

    const [scaleDict, setScaleDict] = useState(undefined)
    const [scale, setScale] = useState(0)
    const [realScale, setRealScale] = useState(0)

    const [alliesList, setAlliesList] = useState(false)
    const [alliesListLength, setAlliesListLength] = useState(false)

    const [screenShotFile, setScreenShotFile] = useState(undefined)
    const screenShot = useRef(null)
    
    const routerHistory = useHistory();
    const { enqueueSnackbar } = useSnackbar()

    const backgroundImage1 = require("../../statics/images/classes/XelorF.png")

    useEffect(() => {
        var target = parseInt(new URLSearchParams(window.location.search).get('match'))
        axios.post('/api/vabzeum/get_scale', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            setScaleDict(response.data)
            // for (var i = 0; i < response.data[side].diff.length; i++) {
            //     const tmp = response.data[side].diff[i]
            //     if (nbEnemies - nbAllies === tmp) {
            //         setScale(response.data[side].score[i])
            //         setRealScale(response.data[side].score[i])
            //     }
            // }
        })
        axios.post('/api/vabzeum/get_match', {
            user_id: session.user_id,
            session_token: session.session_token,
            match_id: target
        }).then(response => {
            // console.log(response.data)
            const match = response.data.match_list[0]
            setMatchId(match.match_id)
            setSide(match.side)
            setEntity(match.entity)
            setNbAllies(match.nb_ally)
            setNbEnemies(match.nb_enemy)
            match.allies.forEach(ally => {
                ally['show'] = true
            })
            // console.log(match.allies)
            console.log(...match.allies)
            setSelectedAllies([...match.allies])
            // console.log(currentImg)
            setScreenShotFile(getVabzeumScreenshotLink(match.screenshot))
            document.getElementById('commentary').value = match.commentary
            setBonusList(match.bonus ? match.bonus : [])
            setAdminValidator(match.admin_validator)
            setBotChecker(match.bot_checker)
            setRecorder(match.recorder)
        })
        axios.post('/api/vabzeum/get_allies', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            setAlliesListLength(response.data.length)
            setAlliesList([...response.data])
        })
    }, [])

    // useEffect(() => {
    //     if (scaleDict) {
    //         for (var i = 0; i < scaleDict[side].diff.length; i++) {
    //             const tmp = scaleDict[side].diff[i]
    //             if (nbEnemies - nbAllies === tmp) {
    //                 setScale(scaleDict[side].score[i])
    //                 setRealScale(scaleDict[side].score[i])
    //             }
    //         }
    //     }
    // }, [nbAllies, nbEnemies, side])

    useEffect(() => {
        if (alliesListLength > 0 && alliesList.length > 0) {
            if (alliesList.length !== alliesListLength) {
                window.location.replace('/vabzeum/register_match')
            }
        }
    }, [alliesList, alliesListLength])

    useEffect(() => {
        var newScale = scale
        const newValue = {
            mult: 0,
            add: 0
        }
        bonusList.forEach(bonus => {
            var plusOne = false
            if (bonus.operation === 'x') {
                if (parseInt(bonus.value) === 1) {
                    bonus.value -= 1
                    plusOne = true
                }
                newValue.mult += bonus.value
                if (plusOne) {
                    bonus.value += 1
                }
            } else if (bonus.operation === '+') {
                newValue.add += bonus.value
            }
        })
        if (parseInt(newValue.mult) === 0) {
            newValue.mult += 1
        }

        if (scaleDict) {
            for (var i = 0; i < scaleDict[side].diff.length; i++) {
                const tmp = scaleDict[side].diff[i]
                if (nbEnemies - nbAllies === tmp) {
                    newScale = scaleDict[side].score[i]
                }
            }
        }
        setBonusValue(newValue)
        setScale(newScale)
        setRealScale(parseInt(newScale * newValue.mult + newValue.add))
    }, [bonusList, nbAllies, nbEnemies, side])

    const handleSide = (side) => {
        setSide(side);
    };

    const handleEntity = (entity) => {
        setEntity(entity);
    };

    const handleNbAllies = (nbAllies) => {
        if (nbAllies >= selectedAllies.length) {
            setNbAllies(nbAllies);
        }
    };

    const handleNbEnemies = (enemies) => {
        setNbEnemies(enemies);
    };


    const separatorStyle = {
        border: `2px solid ${theme.primary}`,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    const setScreenShotRef = (receivedScreenShot) => {
        screenShot.current = receivedScreenShot
    }

    const getRankedColor = (value) => {
        if (value === 1) {
            return theme.primary
        }
        else if (value <= 2) {
            return '#614e1a'
        } else if (value <= 4) {
            return '#C0C0C0'
        } else if (value <= 6) {
            return '#FFD700'
        } else {
            return '#00C0F9'
        }
    }

    const handleSubmit = () => {
        // console.log('side -> ', side)
        // console.log('entity -> ', entity)
        // console.log("allies -> ", nbAllies)
        // console.log("enemies -> ", nbEnemies)
        // console.log('bonus -> ', bonusList)
        // console.log('selected allies ->', selectedAllies)
        // console.log("bonusvalue -> ", bonusValue)
        // console.log('screenshot current -> ', screenShot.current)
        // console.log('screenshotfile -> ', screenShotFile)
        setLoading(true)
        axios.post('/api/admin/vabzeum/edit_match', {
            user_id: session.user_id,
            session_token: session.session_token,
            match: {
                matchId: matchId,
                side: side,
                entity: entity,
                nbAllies: nbAllies,
                // alliesList: alliesList,
                nbEnemies: nbEnemies,
                bonusList: bonusList,
                selectedAllies: selectedAllies,
                realScale: realScale,
                scale: scale,
                commentary: document.getElementById('commentary').value,
                adminValidator: adminValidator,
                botChecker: botChecker
            }
        }).then(response => {
            enqueueSnackbar(response.data, PopMessageParams('success'))
            routerHistory.push('/admin/vabzeum/list')
        }).catch(() => {
            enqueueSnackbar("Erreur avec l'enregistrement du match", PopMessageParams('error'))

        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDeleteMatch = (method) => {
        if (method === 'ask') {
            setAskDeleteValidation(true)
            setModal(undefined)
        }
        else if (method === 'cancel') {
            setAskDeleteValidation(false)
            setModal(undefined)
        }
        else if (method === 'delete') {
            setDeleteLoading(true)

            // var target = parseInt(new URLSearchParams(window.location.search).get('user'))
            
            axios.post('/api/admin/vabzeum/delete_match', {
                target_match: matchId,
                user_id: session.user_id,
                session_token: session.session_token
            }).then(response => {
                enqueueSnackbar(response.data, PopMessageParams('success'))
                setTimeout(() => {
                    routerHistory.push('/admin/vabzeum/list')
                }, 200);
            }).catch(() => {
                enqueueSnackbar("Le match n'a pas pu être supprimé", PopMessageParams('error'))
            }).finally(() => {
                setModal(undefined)
                setDeleteLoading(false)
                setAskDeleteValidation(true)
            })
        }
    }


    return (
        <div className='flex flex-wrap justify-center my-4 mx-auto relative
        w-11/12
        '
        >
            <div style={{
                backgroundColor: theme.secondary,
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
            }}
                className='rounded-lg  relative
                    w-full flex-col items-center max-w-screen-lg'>
                <div>
                    <img
                        style={{
                            transform: 'rotateY(180deg)',
                            opacity: 0.4
                        }}
                        className='absolute right-0 z-0 transition-all duration-800'
                        src={backgroundImage1.default} alt='background_image_default' />
                </div>
                <div className=''>
                    <PageTitle reverse>Editer un match ({matchId})</PageTitle>
                </div>
                <div className='w-11/12 m-auto flex-row'>
                    <div className='flex justify-center z-10'>
                        <CustomP>Id de l'enregistreur: {recorder ? recorder : "introuvable"}</CustomP>
                    </div>
                    <div className='flex flex-wrap justify-center items-center z-10'>
                        <VabzeumToggleGroup>
                            <CustomToggleButton
                                value="percepteur"
                                isSelected={entity}
                                onClick={() => { handleEntity('percepteur') }}
                                width={140}
                            >
                                Percepteur
                                <IconHub name='vabzeum_perco' className='w-8' />
                            </CustomToggleButton>
                            <CustomToggleButton
                                value="prisme"
                                isSelected={entity}
                                onClick={() => { handleEntity('prisme') }}
                                width={140}
                            >
                                Prisme
                                <IconHub name='vabzeum_prisme' className='w-8' />
                            </CustomToggleButton>
                        </VabzeumToggleGroup>
                        <VabzeumToggleGroup>
                            <CustomToggleButton
                                value="attaque"
                                isSelected={side}
                                onClick={() => { handleSide('attaque') }}
                                width={140}
                            >
                                Attaque
                                <IconHub name='vabzeum_atk' className='w-8' />
                            </CustomToggleButton>
                            <CustomToggleButton
                                value="défense"
                                isSelected={side}
                                onClick={() => { handleSide('défense') }}
                                width={140}
                            >
                                Défense
                                <IconHub name='vabzeum_def' className='w-8' />
                            </CustomToggleButton>
                        </VabzeumToggleGroup>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex flex-wrap justify-center
                                    '>
                        <div className='text-center'>
                            <CustomP>Nombre d'alliés</CustomP>
                            <VabzeumToggleGroup>
                                <PlayerNbToggleButton
                                    value={1}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(1) }}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={2}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(2) }}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={3}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(3) }}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={4}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(4) }}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={5}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(5) }}
                                    width={56}
                                />
                            </VabzeumToggleGroup>
                        </div>
                        <div className='text-center'>
                            <CustomP>Nombre d'ennemis</CustomP>
                            <VabzeumToggleGroup>
                                <CustomToggleButton
                                    value={1}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(1) }}
                                    width={56}
                                >1</CustomToggleButton>
                                <CustomToggleButton
                                    value={2}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(2) }}
                                    width={56}
                                >2</CustomToggleButton>
                                <CustomToggleButton
                                    value={3}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(3) }}
                                    width={56}
                                >3</CustomToggleButton>
                                <CustomToggleButton
                                    value={4}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(4) }}
                                    width={56}
                                >4</CustomToggleButton>
                                <CustomToggleButton
                                    value={5}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(5) }}
                                    width={56}
                                >5</CustomToggleButton>
                            </VabzeumToggleGroup>
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex justify-center 
                                       '>
                        <SelectedAlliesDisplayer
                            selectedAllies={selectedAllies}
                            nbAllies={nbAllies}
                            setSelectedAllies={setSelectedAllies} />
                    </div>

                    <div className='flex flex-wrap justify-center'>
                        <div className='
                                        min-w-200 w-4/12'>
                            <CustomP className='text-center'>Combattants</CustomP>
                            <div className='flex'>
                                <CustomSearchInput
                                    id='searchAllies'
                                    selectedAllies={selectedAllies}
                                    alliesList={alliesList}
                                    nbAllies={nbAllies}
                                    setSelectedAllies={setSelectedAllies}
                                    setAlliesList={setAlliesList}
                                    setNbAllies={setNbAllies}
                                />

                            </div>
                            <div className='flex flex-col
                                                    mt-2'>
                                <CustomP className='text-center'>Total bonus</CustomP>
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: theme.primary,
                                            border: `3px solid ${getRankedColor(bonusValue.mult)}`,
                                        }}
                                        className='flex justify-center items-center rounded-full transition-all
                                                        m-auto w-max'>
                                        <div
                                            style={{
                                                backgroundColor: theme.primary,
                                            }}
                                            className='flex items-center justify-center min-w-10 w-max p-2 rounded-full transition-all'
                                        >
                                            <CustomP reverse >
                                                <TotalBonusDisplayer bonusValue={bonusValue} />
                                            </CustomP>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='
                                        min-w-200 w-4/12'>
                            <CustomP className='text-center'>Bonus</CustomP>
                            <div className='flex justify-center'>
                                <BonusListEditor
                                    bonusList={bonusList}
                                    setBonusList={setBonusList}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex flex-wrap sm:space-x-12 justify-center'>
                        <div className='flex flex-col justify-center
                                        '>
                            <CustomP className='mx-auto'>ScreenShot</CustomP>
                            <ScreenShotHandler
                                preloadImg={screenShotFile}
                                setImage={setScreenShotFile}
                                setRef={setScreenShotRef}
                                preventReupload
                            />
                        </div>
                        <div className='flex flex-col justify-center w-max m-auto
                                        '>
                            <MatchPointDisplayer realScale={realScale} selectedAllies={selectedAllies} />
                        </div>
                        <div className='flex flex-col justify-center
                                        '>
                            <CustomP className='mx-auto'>Commentaire</CustomP>
                            <textarea
                                id='commentary'
                                style={{
                                    border: `2px solid ${theme.secondary}`,
                                    boxShadow: theme.boxShadow,
                                    background: `linear-gradient(0.12turn, ${theme.primary_darker}, ${theme.primary}, ${theme.primary_lighter})`,
                                    color: theme.secondary,
                                    carretColor: theme.secondary,
                                }}
                                rows="4"
                                cols="25"
                                className='rounded-sm p-2 focus:outline-none resize-none'
                            >
                            </textarea>
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex flex-wrap justify-center
                    xs:space-x-8'>
                        <div
                            className='flex items-center cursor-pointer'
                            onClick={() => setAdminValidator(adminValidator !== true)}>
                            <CustomP>Valider le match: </CustomP>
                            <CustomCheckBox className="ml-2 mt-2" selected={adminValidator} />
                        </div>
                        <div
                            className='flex items-center cursor-pointer'
                            onClick={() => setBotChecker(botChecker !== true)}>
                            <CustomP>Traité par le bot: </CustomP>
                            <CustomCheckBox className="ml-2 mt-2" selected={botChecker} />
                        </div>

                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex justify-center items-center mt-2'>
                        <CustomLoadingButton
                            type='button'
                            onClick={handleSubmit}
                            forceRefresh={[nbAllies, nbEnemies, selectedAllies,
                                screenShot.current, entity, side, bonusValue, bonusList,
                                adminValidator, botChecker]}
                            loading={loading}
                            loadingColor={theme.primary}
                        >Enregistrer la modification</CustomLoadingButton>
                        <CustomLoadingButton
                            variant='error'
                            loading={deleteLoading}
                            loadingColor={theme.primary}
                            endIcon={<IconHub name='delete' />}
                            type='button'
                            onClick={() => { handleDeleteMatch('ask') }}
                        >
                            Supprimer
                        </CustomLoadingButton>
                        {askDeleteValidation && <>
                            <CustomModal disableAutoVanish>
                                <div style={{
                                    backgroundColor: theme.primary
                                }}
                                    className='p-4'>
                                    Es-tu sûr de vouloir supprimer le match? La suppression est définitive
                                    <div className='flex justify-around'>
                                        <CustomLoadingButton
                                            outlined
                                            reverse
                                            loading={loading}
                                            loadingColor={theme.primary}
                                            endIcon={<IconHub name='crossbox' />}
                                            type='button'
                                            onClick={() => { handleDeleteMatch('cancel') }}
                                        >
                                            Annuler
                                        </CustomLoadingButton>
                                        <CustomLoadingButton
                                            variant='error'
                                            endIcon={<IconHub name='delete' />}
                                            type='button'
                                            onClick={() => { handleDeleteMatch('delete') }}
                                        >
                                            Supprimer
                                        </CustomLoadingButton>
                                    </div>
                                </div>
                            </CustomModal>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

const AdminVabzeumMatchModifyPage = () => {
    return (
        <Access>
            <AdminVabzeumMatchModify />
        </Access>
    )
}

export default AdminVabzeumMatchModifyPage