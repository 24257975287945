import React, { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import './statics/css/index.css';
import './statics/css/custom.css'
import Homepage from './pages/home';
import LoginPage from "./pages/login";
import ShowSessionData from "./components/showSessionData";
import { ThemeContext, SessionContext, ModalContext } from "./components/context";
import Logout from "./pages/logout";
import VabGazettepage from "./pages/vabgazette";
import { NotFound } from "./pages/not_found";
import Scrollbars from "react-custom-scrollbars-2";

// ADMIN
import AdminPage from "./pages/admin/admin";
import AdminCreateUserPage from "./pages/admin/user_create";
import AdminUserListPage from "./pages/admin/user_list";
import AdminUserModifyPage from "./pages/admin/user_edit";
import AdminVabzeumMatchListPage from "./pages/admin/vabzeum_match_list";
import AdminVabzeumMatchModifyPage from "./pages/admin/vabzeum_match_edit";
import AdminVabzeumBonusListPage from "./pages/admin/vabzeum_bonus_list";
import AdminVabzeumBonusCreatePage from "./pages/admin/vabzeum_bonus_create";
import AdminVabzeumBonusEditPage from "./pages/admin/vabzeum_bonus_edit";
import AdminVabzeumGenerateScorePage from "./pages/admin/vabzeum_generate_score";


// USER
import UserEditPage from "./pages/user/edit";
import UserProfilePage from "./pages/user/profile";


// VABZEUM
import VabzeumPage from "./pages/vabzeum/vabzeum";
import VabzeumRegisterMatchPage from "./pages/vabzeum/register_match";
import VabzeumMatchListPage from "./pages/vabzeum/match_list";


// OTHER
import CustomNavbar from "./components/navbar";
import StatusTopBar from "./components/statusTopBar";

// VABRUSHER 
import VabrusherPage from "./pages/vabrusher/vabrusher";
import VabrusherRegisterMatchPage from "./pages/vabrusher/register_match";
import AdminVabrusherMatchListPage from "./pages/admin/vabrusher_match_list";
import AdminVabrusherMatchEditPage from "./pages/admin/vabrusher_match_edit";
import AdminVabrusherGenerateScorePage from "./pages/admin/vabrusher_generate_score";
import VabzeumMatchPage from "./pages/vabzeum/view_match";




const App = () => {
  const { theme } = useContext(ThemeContext)
  const { session } = useContext(SessionContext)
  const { modal } = useContext(ModalContext)

  // navbar
  const [retracted, setRetracted] = useState(false)
  const [openedDropDown, setOpenedDropDown] = useState('Accueil')
  const [leftBar, setLeftBar] = useState(0)

  const [innerHeight, setInnerHeight] = useState(window.innerHeight)

  const onResize = e => {
    setInnerHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, [])

  const switchRetracted = () => {
    if (!retracted) {
      setOpenedDropDown('Accueil')
      setRetracted(true)
      setLeftBar(-200)
    }
    else {
      setLeftBar(0)
      setTimeout(() => {
        setRetracted(false)
      }, 500);
    }
  }

  const switchOpenedDropDown = (name) => {
    setOpenedDropDown(name)
  }

  const pic = require(`./statics/images/themes/${theme.background_image}`)
  const page_style = {
    backgroundImage: `url(${pic.default})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
    // backgroundImage: `linear-gradient(to bottom right, ${theme.primary}, ${theme.primary_darker})`
  }

  useEffect(() => {
    // reset le focus des navbar
    setOpenedDropDown('Accueil')
  }, [session.pseudo])

  useEffect(() => {
  }, [modal])

  return (
    <Router>
      {modal || <div />}
      <Scrollbars style={{ height: innerHeight }}>

        <div id='wrapper' className='bg-blue-400  min-h-screen'>
          <div id='top-statusbar'>
            <StatusTopBar
              retracted={retracted}
              switchRetracted={switchRetracted}
              openedDropDown={openedDropDown}
              switchOpenedDropDown={switchOpenedDropDown}
            />
          </div>
          <div id='page' className='transition-all duration-500'
            style={page_style}>
            <CustomNavbar
              retracted={retracted}
              switchRetracted={switchRetracted}
              openedDropDown={openedDropDown}
              switchOpenedDropDown={switchOpenedDropDown}
              leftBar={leftBar} />
            <div className="mt-16">

              <Switch>
                {/* EVERYONE */}
                <Route path='/' exact component={Homepage} />
                <Route path='/login' component={LoginPage} />

                {/* VABRUSHER */}
                <Route exact path='/vabrusher' component={VabrusherPage} />

                {/* VABZEUM */}
                <Route exact path='/vabzeum' component={VabzeumPage} />



                {/* GUILDEUX */}
                <Route path='/logout' component={Logout} />
                <Route path='/vabgazette' component={VabGazettepage} />
                <Route path='/user/edit' component={UserEditPage} />
                <Route path='/user/profile' component={UserProfilePage} />

                {/* VABZEUM */}
                <Route path='/vabzeum/match' component={VabzeumMatchPage} />
                <Route path='/vabzeum/list' component={VabzeumMatchListPage} />
                <Route path='/vabzeum/register_match' component={VabzeumRegisterMatchPage} />

                {/* VABRUSHER */}
                <Route path='/vabrusher/register_match' component={VabrusherRegisterMatchPage} />



                {/* ADMIN */}
                <Route exact path='/admin' component={AdminPage} />
                <Route exact path='/admin/create_user' component={AdminCreateUserPage} />
                <Route exact path='/admin/user_list' component={AdminUserListPage} />
                <Route path='/admin/user' component={AdminUserModifyPage} />

                {/* VABRUSHER */}
                <Route exact path='/admin/vabrusher/list' component={AdminVabrusherMatchListPage} />
                <Route path='/admin/vabrusher/match' component={AdminVabrusherMatchEditPage} />
                <Route exact path='/admin/vabrusher/generate_score' component={AdminVabrusherGenerateScorePage} />

                {/* VABZEUM */}
                <Route exact path='/admin/vabzeum/list' component={AdminVabzeumMatchListPage} />
                <Route path='/admin/vabzeum/match' component={AdminVabzeumMatchModifyPage} />
                <Route path='/admin/vabzeum/bonus/edit' component={AdminVabzeumBonusEditPage} />
                <Route exact path='/admin/vabzeum/bonus/list' component={AdminVabzeumBonusListPage} />
                <Route exact path='/admin/vabzeum/bonus/create' component={AdminVabzeumBonusCreatePage} />
                <Route exact path='/admin/vabzeum/generate_score' component={AdminVabzeumGenerateScorePage} />
                <Route component={NotFound} />
              </Switch>
            </div>
          </div>
          <ShowSessionData />
        </div>
      </Scrollbars>
    </Router >
  )
}

export default App;

