/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import axios from 'axios';
import '../statics/css/index.css';
import '../statics/css/custom.css';
import { SessionContext, ThemeContext } from "./context";
import { CustomNavLink } from "./utils";
import { useHistory } from 'react-router';

const NavbarDropDown = (props) => {
    const [hover, setHover] = useState(false)
    const routerHistory = useHistory();

    const style = {
        color: hover ? props.theme.primary : props.theme.primary_lighter,
        backgroundColor: hover ? props.theme.secondary_lighter : ''
    }

    return (
        <div style={style}
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            onClick={() => {
                setTimeout(() => {
                    routerHistory.push(props.link)
                }, 10);
            }}
            className='rounded-md transition-all
        py-2 p-2 '
        >
            {props.name}
        </div>
    )
}

const NavbarTitle = (props) => {
    var pic
    try {
        pic = require('../statics/images/icons/icon_' + props.type + '.png')
    } catch {
        pic = require('../statics/images/icons/icon_unknown.png')
    }
    const [showDropDown, setShowDropDown] = useState(false)
    const [forbidden, setForbidden] = useState(true)
    const [enabled, setEnabled] = useState(true)
    // const [hover, setHover] = useState(false)
    const hover = useRef(false)
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)

    useEffect(() => {
        axios.post("/api/get_navbar_title_info", { 
            route: props.link,
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => {
                // console.log(response.data)
                var tmpForbidden = true
                setEnabled(response.data.enabled)
                for (const role in session.roles) {
                    const comp = response.data.roles.indexOf(session.roles[role])
                    if (comp > -1) {
                        tmpForbidden = false
                        break
                    }
                }
                setForbidden(tmpForbidden)
            })
    }, [session.pseudo])


    const handleHover = () => {
        hover.current = true
        props.switchOpenenedDropDown(props.content)
        setShowDropDown(true)
    }

    const handleUnHover = () => {
        hover.current = false
        setTimeout(() => {
            if (!hover.current) {
                setShowDropDown(false)
            }
        }, 400);
    }

    const style = {
        opacity: enabled ? '1' : '0.5',
        color: hover.current ? theme.primary : theme.primary_darker,
        backgroundColor: hover.current ? theme.secondary : ''
    }


    return (
        <div>
            {!forbidden && <>
                <div style={style}
                    onMouseEnter={() => { handleHover() }}
                    onMouseLeave={() => { handleUnHover() }}
                >
                    <CustomNavLink link={props.link} enabled={enabled} noLinkOnMobile={props.noLinkOnMobile}
                        className='flex items-center justify-center cursor-pointer'>
                        <div className='rounded-lg
                        flex justify-start items-center m-auto w-8 py-3 transition-all
                        sm:w-12 sm:px-2
                        lg:w-40 lg:pl-2 lg:mx-0 lg:rounded-none'
                        >
                            <div className='flex justify-center
                        sm:w-8
                        lg:w-8 lg:mr-2'
                            >
                                <img src={pic.default} alt={props.content} title={props.content} />
                            </div>
                            {props.content.length > 0 && <>
                                <div className='hidden
                        transition-all
                        lg:block lg:pl-2'>
                                    {props.content}
                                </div>
                            </>}
                            {enabled && props.openenedDropDown === props.content && showDropDown && props.dropdown && props.dropdown.length > 1 && <>
                                <div style={{ backgroundColor: theme.secondary }}
                                    className=' bg-vab-white rounded-md border-2 border-t-2 border-b-2 border-r-2 border-gray-800 shadow-2xl transition-all
                                    absolute ml-10 mt-10 p-1 w-max
                                    sm:ml-14
                                    lg:ml-40'>
                                    {props.dropdown.map((elem, key) => <NavbarDropDown key={key} name={elem} link={props.dropdownLinks[key]} theme={theme} />)}
                                </div>
                            </>}
                        </div>
                    </CustomNavLink>
                </div>
            </>}
        </div>
    )
}

const NavbarClassTitleWrapper = (props) => {

    return (
        <div style={props.style} className='rounded-br-lg z-50
        flex_col items-center transition-all zIndex-50 duration-500
        mr-2 w-10 pt-1 py-2 mt-12
        sm:w-16
        lg:w-40'
            id='navbar'>
            {props.children}
        </div>
    )
}

const CustomNavbar = (props) => {
    const { theme } = useContext(ThemeContext)


    const style = {
        position: props.retracted ? 'fixed' : 'fixed',
        left: props.leftBar,
        transitionTimingFunction: 'cubic-bezier(0.68, -0.55, 0.27, 1.55)',
        backgroundImage: `linear-gradient(to bottom left, ${theme.secondary}, ${theme.secondary_darker})`,
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
    }



    return (
        <NavbarClassTitleWrapper style={style} >

            <NavbarTitle
                content='Accueil'
                type='blason'
                link='/' home={true}
                switchOpenenedDropDown={props.switchOpenedDropDown}
                openenedDropDown={props.openedDropDown} />
            <NavbarTitle
                content='Admin'
                type='admin'
                link='/admin'
                dropdown={[]}
                dropdownLinks={[]}
                switchOpenenedDropDown={props.switchOpenedDropDown}
                openenedDropDown={props.openedDropDown}
            />
            <div style={{ borderColor: theme.primary_darker }}
                className='border-t rounded-lg 
                m-2 mb-6'/>
            <NavbarTitle
                content='VabZeum'
                type='vabzeum'
                link='/vabzeum'
                dropdown={['Récap', 'Enregistrer un match']}
                dropdownLinks={['/vabzeum', '/vabzeum/register_match']}
                switchOpenenedDropDown={props.switchOpenedDropDown}
                openenedDropDown={props.openedDropDown}
            />
            <NavbarTitle
                content='Vabrusher'
                type='vabrusher'
                link='/vabrusher'
                dropdown={['Récap', 'Enregistrer un match']}
                dropdownLinks={['/vabrusher', '/vabrusher/register_match']}
                switchOpenenedDropDown={props.switchOpenedDropDown}
                openenedDropDown={props.openedDropDown}
            />
            {/* <NavbarTitle
                content='VabGazette'
                type='vabgazette'
                link='/vabgazette'
                dropdown={[]}
                switchOpenenedDropDown={props.switchOpenedDropDown}
                openenedDropDown={props.openedDropDown} /> */}
        </NavbarClassTitleWrapper>
    );
}

export default CustomNavbar