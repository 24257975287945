/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from 'react'
import Access from '../../components/access.js'
import { SessionContext, ThemeContext } from '../../components/context.js'
import { PageTitle, CustomP, PopMessageParams, toBase64, getStyledNumbers, getScoreRankedColor, getUserAvatarLink } from '../../components/utils.js'
import { IconHub } from '../../components/icon.js'
import { CustomToggleButton } from '../../components/toggleButton.js'
import axios from 'axios'
import { Avatar } from '@mui/material'
import Scrollbars from 'react-custom-scrollbars-2'
import { CustomLoadingButton } from "../../components/button.js";
import { useSnackbar } from "notistack";
import { ScreenShotHandler } from "../../components/screenshotHandler.js";




export const VabzeumToggleGroup = (props) => {
    const { theme } = useContext(ThemeContext)

    return (
        <div
            className='flex justify-center m-2'
        >
            <div
                className='flex'
                style={{
                    border: `2px solid ${theme.primary}`,
                    borderRadius: 5
                }}
            >
                {props.children}
            </div>
        </div>
    )
}


const AllyElem = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = getUserAvatarLink(element.avatar)
    const [hover, setHover] = useState(false)
    const size = props.size || 30
    return (
        <div>
            {element.show && <>
                <div
                    className='flex justify-around items-center cursor-pointer rounded-full items-center transition-all duration-800
                    mt-1 w-max mx-1 '
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        border: `2px solid ${theme.primary_darker}`,
                        boxShadow: theme.boxShadow
                    }}

                >
                    <div
                        onMouseEnter={() => { setHover(true) }}
                        onMouseLeave={() => { setHover(false) }}
                        className={`flex justify-start items-center
                     space-x-2 pr-1`}
                        onMouseDown={() => { props.onClick(element) }}
                    >
                        <Avatar
                            style={{
                                width: size,
                                height: size
                            }}
                            src={pic}
                            className=''
                        />
                        <CustomP
                            reverse
                            className='pr-1'
                        >
                            {element.name}</CustomP>
                    </div>
                    {/* <CustomP
                        onMouseDown={() => { props.onClick(element) }}
                        reverse
                        className=''>
                        <IconHub name='crossbox' />
                    </CustomP> */}
                </div >
            </>}
        </div>

    )
}

const EmptyAllyElem = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = require('../../statics/images/icons/icon_sleeping.png')
    const size = props.size || 30
    return (
        <div>
            <div
                className='flex justify-around items-center cursor-not-allowed rounded-full items-center transition-all duration-800
                    mt-1 w-max mx-1 '
                style={{
                    border: `2px solid ${theme.primary_darker}`,
                    boxShadow: theme.boxShadow
                }}
            >
                <div
                    className={`flex justify-center items-center
                     pl-4 pr-4 space-x-2`}
                >
                    <Avatar
                        style={{
                            width: size,
                            height: size
                        }}
                        src={pic.default}
                    />
                </div>
            </div >
        </div>

    )
}

export const AllyElemMin = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = getUserAvatarLink(element.avatar)
    const [hover, setHover] = useState(false)
    const size = props.size || 30
    return (
        <div className={props.className}>
            {element.show && <>
                <div
                    onMouseEnter={() => { setHover(true) }}
                    onMouseLeave={() => { setHover(false) }}
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        border: `2px solid ${theme.primary_darker}`,
                        boxShadow: theme.boxShadow
                    }}
                    className={`flex m-1 pr-4 w-max space-x-2 cursor-pointer rounded-full items-center transition-all duration-800`}
                    onMouseDown={() => { props.onClick(element) }}
                >
                    <Avatar
                        style={{
                            width: size,
                            height: size
                        }}
                        src={pic}
                        className=''
                    />
                    <CustomP
                        reverse
                        className=''
                    >
                        {element.name}</CustomP>
                </div>
            </>}
        </div >

    )
}


export const CustomSearchInput = (props) => {
    const { theme } = useContext(ThemeContext)
    const [hover, setHover] = useState(false)
    const [renderedList, setRenderedList] = useState(false)
    const hoverRef = useRef(false)

    const addAlly = (ally) => {
        if (props.selectedAllies.indexOf(ally) === -1) {
            const newSelectedAllies = ([...props.selectedAllies]).concat([ally])
            props.setSelectedAllies(newSelectedAllies)
            if (newSelectedAllies.length > props.nbAllies) {
                props.setNbAllies(newSelectedAllies.length)
            }
            document.getElementById("searchAllies").value = ''
        }
    }

    const handleHover = () => {
        if (props.selectedAllies.length < (props.limitNb || 5)) {
            hoverRef.current = true
            setHover(true)
        }
    }

    const handleUnHover = () => {
        hoverRef.current = false
        setTimeout(() => {
            if (!hoverRef.current) {
                setHover(false)
            }
        }, 200);
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault()
            setTimeout(() => {
                const value = document.getElementById("searchAllies").value
                if (renderedList.length === 1) {
                    addAlly(renderedList[0].props.element)
                } else {
                    renderedList.forEach(reactElement => {
                        if (reactElement.props.element.name.toLowerCase() === value.toLowerCase()) {
                            addAlly(reactElement.props.element)
                        }
                    })
                }
            }, 50);
        } else {
            setTimeout(() => {
                const newList = []
                const value = document.getElementById("searchAllies").value
                props.alliesList.forEach(element => {
                    var allreadyAdded = false
                    if (props.selectedAllies.length) {
                        props.selectedAllies.forEach(selectedElem => {
                            if (element.name === selectedElem.name) {
                                allreadyAdded = true
                            }
                        })
                    }
                    if (!allreadyAdded) {
                        if (element.name.toLowerCase().startsWith(value.toLowerCase()) || value.length === 0) {
                            newList.push(
                                <AllyElemMin key={element.id} element={element} theme={theme} onClick={handleAdd} />)
                        }
                    }
                })
                setRenderedList([...newList])
                newList.forEach(reactElement => {
                    const element = reactElement.props.element
                    var allreadyAdded = false
                    if (props.selectedAllies.length) {
                        props.selectedAllies.forEach(selectedElem => {
                            if (element.name === selectedElem.name) {
                                allreadyAdded = true
                            }
                        })
                    }
                    if (!allreadyAdded) {
                        if (element.name.toLowerCase().startsWith(value.toLowerCase()) || value.length === 0) {
                            if (element.name.toLowerCase() === value.toLowerCase() && newList.length === 1) {
                                addAlly(element)
                            }
                            if (element.name.toLowerCase() === value.toLowerCase() && newList.length === 1) {
                                addAlly(element)
                            }
                        }
                    }
                })
            }, 50);
        }
    }

    const handleAdd = (element) => {
        if (props.selectedAllies.length < (props.limitNb || 5)) {
            addAlly(element)
        }
    }

    useEffect(() => {
        var tmpRenderedList = []
        for (var i = 0; i < props.alliesList.length; i++) {
            var allreadyAdded = false
            if (props.selectedAllies.length) {
                for (var j = 0; j < props.selectedAllies.length; j++) {
                    if (props.alliesList[i].name === props.selectedAllies[j].name) {
                        allreadyAdded = true
                    }
                }
            }
            if (!allreadyAdded) {
                tmpRenderedList.push(
                    <AllyElemMin key={i} element={props.alliesList[i]} theme={theme} onClick={handleAdd} />)
            }
        }
        setRenderedList(tmpRenderedList)
        if (props.selectedAllies.length === (props.limitNb || 5)) {
            setHover(false)
        }
    }, [props.selectedAllies, props.alliesList, props.nbAllies])

    const inputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        opacity: props.selectedAllies.length < (props.limitNb || 5)? 1 : 0.5
    }

    return (
        <div
            className='w-full flex flex-wrap justify-center items-start mt-1'
            onMouseEnter={() => { handleHover(true) }}
            onMouseLeave={() => { handleUnHover(false) }}
        >
            <div className='flex flex-col justify-center relative
                '

            >
                <input
                    autoComplete='off'
                    id={props.id}
                    label={props.id}
                    style={inputStyle}
                    className='focus:outline-none transition-all duration-800
                        p-2'
                    onKeyDown={handleKeyDown}
                    disabled={props.selectedAllies.length < (props.limitNb || 5)? false : true}
                    placeholder='pseudo VàB'
                    onClick={() => { handleHover(true) }}

                />
                {hover && renderedList && renderedList.length && <>
                    {/* <div className='absolute w-max z-9998 top-0'> */}
                    <div
                        style={{
                            height: 180,
                            border: `2px solid ${theme.secondary_lighter}`,
                            boxShadow: 'inset 0px -10px 10px -10px #000000',
                            opacity: props.selectedAllies.length < (props.limitNb || 5)? 1 : 0.5,
                            background: `repeating-linear-gradient(140deg, ${theme.primary_lighter}, ${theme.primary_lighter} 25px, ${theme.primary} 25px, ${theme.primary} 40px)`
                        }}
                        className={`absolute top-11 z-10 rounded-b-md transition-all duration-800 overflow-y-auto overflow-x-hidden
                        w-full`}>
                        {renderedList}
                    </div>
                    {/* </div> */}
                </>}
            </div>
        </div >
    )
}

const BonusElem = ({ bonus, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const [hover, setHover] = useState(false)

    return (
        <div className={`flex mx-1`}>
            <div
                onMouseEnter={() => { setHover(true) }}
                onMouseLeave={() => { setHover(false) }}
                style={{
                    backgroundColor: hover ? theme.primary_lighter : theme.primary,
                    border: `2px solid ${bonus.selected ? theme.secondary_lighter : theme.primary_darker}`,
                    boxShadow: theme.boxShadow,
                    left: bonus.selected ? '100' : '0',
                    opacity: bonus.selected ? 1 : bonus.auto ? 0.3 : 0.6,
                    cursor: bonus.auto ? 'not-allowed' : 'pointer'
                }}
                className={`flex my-1 pl-2 w-max space-x-2 rounded-full items-center transition-all duration-800`}
                onClick={() => { if (props.onClick) { props.onClick(bonus) } }}
            >
                <CustomP
                    reverse
                    className='flex space-x-4 items-center'
                >
                    {bonus.name}
                    <div
                        style={{
                            backgroundColor: theme.primary_lighter,
                            border: `2px solid ${bonus.selected ? theme.secondary_lighter : theme.primary_darker}`,
                        }}
                        className='flex items-center justify-center ml-2 w-10 h-10 rounded-full'
                    >
                        {bonus.operation}{(bonus.value < 1 && bonus.operation === 'x') ? bonus.value + 1 : bonus.value}
                    </div></CustomP>
            </div>
        </div >

    )
}

function isValidBonus(bonus, side, entity, nbAllies, nbEnemies, nbSelectedAllies) {
    const d = new Date()
    let hour = d.getHours()
    // console.log(bonus)
    // console.log("name:", bonus.name)
    // console.log("hour:", hour)
    // console.log('hour_min', bonus.hour_min)
    // console.log('hour_max', bonus.hour_max)

    if (bonus.hour_min === null || bonus.hour_max === null || (hour >= bonus.hour_min && hour <= bonus.hour_max)) {
        // console.log("heure ok")
        if (!bonus.condition_group_nb || bonus.condition_group_nb <= nbSelectedAllies.length) {
            // console.log("group nb ok")
            if (!bonus.condition_entity || bonus.condition_entity.name === entity) {
                // console.log("entity ok")
                // console.log('side:', side)
                if (!bonus.condition_side || bonus.condition_side.name === side) {
                    // console.log('side ok')
                    return true
                }
            }
        }
    }
    return false
}

export function notDoubleBonus(bonusList, targetBonus) {
    var check = true
    if (!targetBonus.selected) {
        bonusList.forEach(currBonus => {
            if (currBonus.type.id === targetBonus.type.id && currBonus.selected) {
                check = false
            }
        })
    }
    return check
}

const ManuBonusGroup = (props) => {
    const { theme } = useContext(ThemeContext)
    const [manuBonusGroupElem, setManuBonusGroupElem] = useState([])
    const [reload, setReload] = useState(false)

    const selectBonus = (bonus) => {
        if (isValidBonus(bonus, props.side, props.entity, props.nbAllies, props.nbEnemies, props.selectedAllies)) {
            if (notDoubleBonus(props.manuBonusList, bonus)) {
                bonus.selected = bonus.selected !== true
                const tmp = { ...props.bonusValue }
                if (bonus.selected === true) {
                    if (bonus.operation === 'x') {
                        tmp.mult += bonus.value
                    } else if (bonus.operation === '+') {
                        tmp.add += bonus.value
                    }
                } else {
                    if (bonus.operation === 'x') {
                        tmp.mult -= bonus.value
                    } else if (bonus.operation === '+') {
                        tmp.add -= bonus.value
                    }
                }
                setReload(reload !== true)
            }
        }
    }

    useEffect(() => {
        // console.log('elem -> ', props.manuBonusList)
        var tmp_selectedManuBonus = [...props.manuBonusList]
        var tmp_ManuBonusGroupElem = []
        var i = 0
        tmp_selectedManuBonus.forEach(bonus => {
            // if (isValidBonus(bonus, props.side, props.entity, props.nbAllies, props.nbEnemies, props.selectedAllies)) {
            tmp_ManuBonusGroupElem.push(
                <BonusElem key={`manu_bonus_${i}`} bonus={bonus} onClick={selectBonus} />
            )
            // }
            i++
        })
        // props.setSelectedManuBonusList(tmp_selectedManuBonus)
        props.handleBonusList(tmp_selectedManuBonus, 'manu')
        setManuBonusGroupElem(tmp_ManuBonusGroupElem)
    }, [props.selectedAllies, props.side, props.entity, props.nbAllies, props.nbEnemies, reload, props.reload])

    return (

        <div className='flex flex-col justify-around z-50
        sm:justify-center text-center'>
            <CustomP>Bonus disponibles</CustomP>
            {manuBonusGroupElem.length && <>
                <Scrollbars
                    autoHide
                    style={{
                        height: 120,
                        border: `2px solid ${theme.secondary.lighter}`,
                        boxShadow: 'inset 0px -10px 10px -10px #000000',
                        background: `repeating-linear-gradient(140deg, ${theme.primary_lighter}, ${theme.primary_lighter} 25px, ${theme.primary} 25px, ${theme.primary} 40px)`
                    }}
                    className={`mt-1 mb-2 rounded-md transition-all duration-800`}>
                    <div className='flex flex-wrap justify-around my-1'>
                        {manuBonusGroupElem}
                    </div>
                </Scrollbars>
            </>}
        </div>
    )
}



const AutoBonusGroup = (props) => {
    const { theme } = useContext(ThemeContext)
    const [autoBonusGroupElem, setAutoBonusGroupElem] = useState([])

    useEffect(() => {
        var tmp_selectedAutoBonus = [...props.autoBonusList]
        var tmp_AutoBonusGroupElem = []
        var i = 0
        tmp_selectedAutoBonus.forEach(bonus => {
            bonus.selected = isValidBonus(bonus, props.side, props.entity, props.nbAllies, props.nbEnemies, props.selectedAllies)
            tmp_AutoBonusGroupElem.push(
                <BonusElem key={`auto_bonus_${i}`} bonus={bonus} auto />
            )
            i++
        })
        props.handleBonusList(tmp_selectedAutoBonus, 'auto')
        setAutoBonusGroupElem(tmp_AutoBonusGroupElem)
    }, [props.selectedAllies, props.side, props.entity, props.nbAllies, props.nbEnemies])

    return (
        <div className='flex flex-col justify-around
        sm:justify-center text-center
        '>
            <CustomP>Bonus automatiques</CustomP>
            {autoBonusGroupElem.length > 0 && <>
                <Scrollbars
                    autoHide
                    style={{
                        height: 120,
                        border: `2px solid ${theme.secondary.lighter}`,
                        boxShadow: 'inset 0px -10px 10px -10px #000000',
                        background: `repeating-linear-gradient(140deg, ${theme.primary_lighter}, ${theme.primary_lighter} 25px, ${theme.primary} 25px, ${theme.primary} 40px)`
                    }}
                    className={`mt-1 mb-2 rounded-md transition-all duration-800`}>
                    <div className='flex flex-wrap justify-around my-1'>
                        {autoBonusGroupElem}
                    </div>
                </Scrollbars>
            </>}
        </div>
    )
}


const PlayerNbToggleButton = (props) => {
    const [disabled, setDisabled] = useState(false)

    useEffect(() => {
        const newDisabled = props.value >= props.selectedAllies.length ? false : true
        setDisabled(newDisabled)

    }, [props.selectedAllies, props.nbAllies])

    return (
        <CustomToggleButton
            value={props.value}
            isSelected={props.isSelected}
            onClick={disabled ? undefined : props.onClick}
            width={props.width}
            disabled={disabled}
        >{props.value}</CustomToggleButton>
    )
}

const SelectedAlliesDisplayer = (props) => {
    const [displayedAllies, setDisplayedAllies] = useState([])
    const { theme } = useContext(ThemeContext)

    const delAlly = (ally) => {
        const newSelectedAllies = ([...props.selectedAllies])
        newSelectedAllies.splice(props.selectedAllies.indexOf(ally), 1)
        props.setSelectedAllies(newSelectedAllies)
    }

    const handleDel = (element) => {
        delAlly(element)
    }

    useEffect(() => {
        const tmp_displayedAllies = []
        for (var i = 0; i < 5; i++) {
            if (props.selectedAllies.length > i) {
                tmp_displayedAllies.push(
                    <AllyElem key={i} element={props.selectedAllies[i]} theme={theme} onClick={handleDel} />
                )
            } else {
                tmp_displayedAllies.push(
                    <EmptyAllyElem key={i} theme={theme} />
                )
            }
        }
        setDisplayedAllies(tmp_displayedAllies)
    }, [props.nbAllies, props.selectedAllies])

    return (
        <div className='flex flex-wrap justify-center space-x-1 z-10'>
            {displayedAllies}
        </div>
    )
}

const MatchPointDisplayer = (props) => {
    const [nbToShow, setNbToShow] = useState([])
    const [nbToShowGroup, setNbToShowGroup] = useState([])

    useEffect(() => {
        // console.log("reacscale recu ->", props.realScale)
        // console.log("selected aly")
        setNbToShow(getStyledNumbers(props.realScale, 'react', undefined, 25))
        setNbToShowGroup(getStyledNumbers(props.realScale * props.selectedAllies.length, 'react', undefined, 25))
    }, [props.realScale, props.selectedAllies])


    return (
        <div className='flex justify-center space-x-6'>
            <div className='flex flex-col justify-center'>
                <CustomP className='mx-auto'>Points{props.selectedAllies.length > 1 ? " par joueur" : ""}</CustomP>
                <div className='flex justify-center mt-2 h-8'>
                    {nbToShow && nbToShow.length > 0 && <>
                        {nbToShow}
                    </>}
                </div>
            </div>
            {props.selectedAllies.length > 1 && <>
                <div className='flex flex-col justify-center'>
                    <CustomP className='mx-auto'>Points totaux</CustomP>
                    <div className='flex justify-center mt-2 h-8'>
                        {nbToShowGroup && nbToShowGroup.length > 0 && <>
                            {nbToShowGroup}
                        </>}
                    </div>
                </div>
            </>}
        </div>
    )
}


const TotalBonusDisplayer = (props) => {
    const [add, setAdd] = useState(undefined)
    const [mult, setMult] = useState(undefined)

    useEffect(() => {
        setAdd(getStyledNumbers(props.bonusValue.add, 'react', '+', 18))
        setMult(getStyledNumbers(props.bonusValue.mult, 'react', 'x', 18))
    }, [props.bonusValue])

    return (
        <div className='flex space-x-4'>
            {props.bonusValue.mult > 0 && <>
                <div className='flex h-6'>
                    {mult}
                </div>
            </>}
            {props.bonusValue.add > 0 && <>
                <div className='flex h-6'>
                    {add}
                </div>
            </>}
        </div>
    )
}

export function errorPlayer(selectedAllies, alliesList) {
    var check = false
    selectedAllies.forEach(allyToCheck => {
        var count = 0
        selectedAllies.forEach(ally => {
            // console.log("check -> ", allyToCheck.id, allyToCheck.name, ' // ally -> ', ally.id, ally.name)
            if (allyToCheck.id === ally.id || allyToCheck.name === ally.name) {
                if (!count) {
                    count += 1
                } else {
                    check = true
                }
            }
        })
    })
    // console.log("premier -> ", check)
    if (!check) {
        var BreakException = {}
        try {
            selectedAllies.forEach(ally => {
                var allyExists = false
                alliesList.forEach(allyToCheck => {
                    // console.log("check -> ", allyToCheck.id, allyToCheck.name, ' // ally -> ', ally.id, ally.name, ally.show)
                    if (allyToCheck.id === ally.id && allyToCheck.name === ally.name && ally.show) {
                        allyExists = true
                    }
                })
                if (!allyExists) {
                    // console.log("ally not exists => ", ally)
                    throw BreakException
                }
            })
        }
        catch (e) {
            if (e !== BreakException) {
                throw e
            }
            check = true
            // console.log('deuxieme -> ', check)
        }
    }
    return check
}

const VabzeumRegisterMatch = () => {
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [side, setSide] = useState('défense')
    const [entity, setEntity] = useState('percepteur')
    const [nbAllies, setNbAllies] = useState(3)
    const [nbEnemies, setNbEnemies] = useState(5)
    const [selectedAllies, setSelectedAllies] = useState([])

    const [bonusValue, setBonusValue] = useState({ mult: 0, add: 0 })
    const [autoBonusValue, setAutoBonusValue] = useState({ mult: 0, add: 0 })
    const [manuBonusValue, setManuBonusValue] = useState({ mult: 0, add: 0 })

    const [autoBonusList, setAutoBonusList] = useState([])
    const [manuBonusList, setManuBonusList] = useState([])

    const [scaleDict, setScaleDict] = useState(undefined)
    const [scale, setScale] = useState(0)
    const [realScale, setRealScale] = useState(0)

    const [alliesList, setAlliesList] = useState(false)
    const [alliesListLength, setAlliesListLength] = useState(false)

    const [screenShotFile, setScreenShotFile] = useState(undefined)
    const screenShot = useRef(null)

    const [loading, setLoading] = useState(false)

    const { enqueueSnackbar } = useSnackbar()


    const backgroundImage1 = require("../../statics/images/classes/XelorF.png")



    // const [nbAllies, setNbAllies] = useState(1)

    useEffect(() => {
        axios.post('/api/vabzeum/get_allies', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            response.data.forEach(ally => {
                if (ally.id === session.user_id) {
                    setSelectedAllies([{ ...ally }])
                }
            })
            setAlliesListLength(response.data.length)
            setAlliesList([...response.data])
        })
        axios.post('/api/vabzeum/get_scale', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            setScaleDict(response.data)
            for (var i = 0; i < response.data[side].diff.length; i++) {
                const tmp = response.data[side].diff[i]
                if (nbEnemies - nbAllies === tmp) {
                    setScale(response.data[side].score[i])
                    setRealScale(response.data[side].score[i])
                }
            }
        })
        axios.post('/api/vabzeum/get_bonus', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            // console.log(response.data)
            var tmp_AutoBonusList = []
            var tmp_ManuBonusList = []
            response.data.forEach(bonus => {
                if (bonus.auto) {
                    tmp_AutoBonusList.push(bonus)
                } else {
                    tmp_ManuBonusList.push(bonus)
                }
            })
            // console.log(tmp_ManuBonusList)
            setAutoBonusList(tmp_AutoBonusList)
            setManuBonusList(tmp_ManuBonusList)
        })
    }, [])

    useEffect(() => {
        if (scaleDict) {
            for (var i = 0; i < scaleDict[side].diff.length; i++) {
                const tmp = scaleDict[side].diff[i]
                if (nbEnemies - nbAllies === tmp) {
                    setScale(scaleDict[side].score[i])
                    setRealScale(scaleDict[side].score[i])
                    // console.log('nouveau scale', scaleDict[side].score[i])
                }
            }
        }
    }, [nbAllies, nbEnemies, side])

    useEffect(() => {
        if (alliesListLength > 0 && alliesList.length > 0) {
            if (alliesList.length !== alliesListLength) {
                window.location.replace('/vabzeum/register_match')
            }
        }
    }, [alliesList, alliesListLength])

    useEffect(() => {
        const newValue = {
            mult: autoBonusValue.mult + manuBonusValue.mult,
            add: autoBonusValue.add + manuBonusValue.add
        }
        if (parseInt(newValue.mult) === 0) {
            newValue.mult += 1
        }
        setBonusValue(newValue)
        setRealScale(parseInt(scale * newValue.mult + newValue.add))
    }, [autoBonusValue, manuBonusValue])


    const handleBonusList = (newBonusList, bonusMode) => {
        const newBonusValue = { mult: 0, add: 0 }
        newBonusList.forEach(bonus => {
            var plusOne = false
            if (bonus.selected === true) {
                if (bonus.operation === 'x') {
                    if (parseInt(bonus.value) === 1) {
                        bonus.value -= 1
                        plusOne = true
                    }
                    newBonusValue.mult += bonus.value
                } else if (bonus.operation === '+') {
                    newBonusValue.add += bonus.value
                }
                if (plusOne) {
                    bonus.value += 1
                }
            }
        })
        if (bonusMode === 'auto') {
            setAutoBonusValue(newBonusValue)
            setAutoBonusList(newBonusList)
        } else if (bonusMode === 'manu') {
            setManuBonusValue(newBonusValue)
            setManuBonusList(newBonusList)
        }
    }

    const handleSide = (side) => {
        setSide(side);
    };

    const handleEntity = (entity) => {
        setEntity(entity);
    };

    const handleNbAllies = (nbAllies) => {
        if (nbAllies >= selectedAllies.length) {
            setNbAllies(nbAllies);
        }
    };

    const handleNbEnemies = (enemies) => {
        setNbEnemies(enemies);
    };


    const separatorStyle = {
        border: `2px solid ${theme.primary}`,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    const setScreenShotRef = (receivedScreenShot) => {
        screenShot.current = receivedScreenShot
    }

    

    const handleSubmit = () => {
        // console.log('side -> ', side)
        // console.log('entity -> ', entity)
        // console.log("allies -> ", nbAllies)
        // console.log("enemies -> ", nbEnemies)
        // console.log('auto bonus -> ', autoBonusList)
        // console.log('manu bonus -> ', manuBonusList)
        // console.log('selected allies ->', selectedAllies)
        // console.log("bonusvalue -> ", bonusValue)
        // console.log('screenshot current -> ', screenShot.current)
        // console.log('screenshotfile -> ', screenShotFile)
        if (!screenShotFile) {
            enqueueSnackbar('Il manque ton screen!', PopMessageParams('info'))
        } else if (selectedAllies.length <= 0) {
            enqueueSnackbar('Il faut au moins 1 allié!', PopMessageParams('info'))
        } else if (selectedAllies.length > nbAllies) {
            enqueueSnackbar("Il y a moins d'alliés que de guildeux", PopMessageParams('info'))
        } else if (errorPlayer(selectedAllies, alliesList)) {
            enqueueSnackbar("refusé", PopMessageParams('info'))
        } else {
            setLoading(true)
            toBase64(screenShot.current)
                .then(base64File => {
                    axios.post('/api/vabzeum/create_match', {
                        user_id: session.user_id,
                        session_token: session.session_token,
                        match: {
                            side: side,
                            entity: entity,
                            nbAllies: nbAllies,
                            alliesList: alliesList,
                            nbEnemies: nbEnemies,
                            autoBonusList: autoBonusList,
                            manuBonusList: manuBonusList,
                            selectedAllies: selectedAllies,
                            realScale: realScale,
                            scale: scale,
                            screenShot: base64File,
                            commentary: document.getElementById('commentary').value,
                            recorder: session.user_id
                        }
                    }).then(response => {
                        enqueueSnackbar(response.data, PopMessageParams('success'))
                        setScreenShotFile(undefined)
                        setScreenShotRef(false)
                        document.getElementById('commentary').value = ''
                        const tmp_ManuBonusList = [...manuBonusList]
                        tmp_ManuBonusList.forEach(bonus => {
                            bonus.selected = false
                        })
                        setManuBonusList(tmp_ManuBonusList)
                    }).catch(e => {
                        enqueueSnackbar("Erreur avec l'enregistrement du match", PopMessageParams('error'))

                    }).finally(() => {
                        setLoading(false)
                    })
                }).catch(e => {
                    enqueueSnackbar("Erreur avec le traitement de l'image", PopMessageParams('error'))
                });
        }
    }

    return (
        <div className='flex flex-wrap justify-center my-4 mx-auto relative
        w-11/12
        sm: flex'
        >
            <div style={{
                backgroundColor: theme.secondary,
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
            }}
                className='rounded-lg  relative
                    w-full flex-col items-center max-w-screen-lg'>
                <div>
                    <img
                        style={{
                            transform: 'rotateY(180deg)',
                            opacity: 0.4
                        }}
                        className='absolute right-0 z-0 transition-all duration-800'
                        src={backgroundImage1.default} alt='background_image_default' />
                </div>
                <div className=''>
                    <PageTitle reverse>Enregistrer un match</PageTitle>
                </div>
                <div className='w-11/12 m-auto flex-row'>
                    <div className='flex flex-wrap justify-center items-center z-10'>
                        <VabzeumToggleGroup>
                            <CustomToggleButton
                                value="percepteur"
                                isSelected={entity}
                                onClick={() => { handleEntity('percepteur') }}
                                width={140}
                            >
                                Percepteur
                                <IconHub name='vabzeum_perco' className='w-8' />
                            </CustomToggleButton>
                            <CustomToggleButton
                                value="prisme"
                                isSelected={entity}
                                onClick={() => { handleEntity('prisme') }}
                                width={140}
                            >
                                Prisme
                                <IconHub name='vabzeum_prisme' className='w-8' />
                            </CustomToggleButton>
                        </VabzeumToggleGroup>
                        <VabzeumToggleGroup>
                            <CustomToggleButton
                                value="attaque"
                                isSelected={side}
                                onClick={() => { handleSide('attaque') }}
                                width={140}
                            >
                                Attaque
                                <IconHub name='vabzeum_atk' className='w-8' />
                            </CustomToggleButton>
                            <CustomToggleButton
                                value="défense"
                                isSelected={side}
                                onClick={() => { handleSide('défense') }}
                                width={140}
                            >
                                Défense
                                <IconHub name='vabzeum_def' className='w-8' />
                            </CustomToggleButton>
                        </VabzeumToggleGroup>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex flex-wrap justify-center
                                    '>
                        <div className='text-center'>
                            <CustomP>Nombre d'alliés</CustomP>
                            <VabzeumToggleGroup>
                                <PlayerNbToggleButton
                                    value={1}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(1) }}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={2}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(2) }}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={3}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(3) }}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={4}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(4) }}
                                    width={56}
                                />
                                <PlayerNbToggleButton
                                    value={5}
                                    selectedAllies={selectedAllies}
                                    isSelected={nbAllies}
                                    onClick={() => { handleNbAllies(5) }}
                                    width={56}
                                />
                            </VabzeumToggleGroup>
                        </div>
                        <div className='text-center'>
                            <CustomP>Nombre d'ennemis</CustomP>
                            <VabzeumToggleGroup>
                                <CustomToggleButton
                                    value={1}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(1) }}
                                    width={56}
                                >1</CustomToggleButton>
                                <CustomToggleButton
                                    value={2}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(2) }}
                                    width={56}
                                >2</CustomToggleButton>
                                <CustomToggleButton
                                    value={3}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(3) }}
                                    width={56}
                                >3</CustomToggleButton>
                                <CustomToggleButton
                                    value={4}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(4) }}
                                    width={56}
                                >4</CustomToggleButton>
                                <CustomToggleButton
                                    value={5}
                                    isSelected={nbEnemies}
                                    onClick={() => { handleNbEnemies(5) }}
                                    width={56}
                                >5</CustomToggleButton>
                            </VabzeumToggleGroup>
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex justify-center 
                                       '>
                        <SelectedAlliesDisplayer
                            selectedAllies={selectedAllies}
                            nbAllies={nbAllies}
                            setSelectedAllies={setSelectedAllies} />
                    </div>

                    <div className='flex flex-wrap justify-center'>
                        <div className='
                                        min-w-200 w-4/12'>
                            {autoBonusList && autoBonusList.length > 0 && <>
                                <AutoBonusGroup
                                    side={side}
                                    entity={entity}
                                    nbAllies={nbAllies}
                                    nbEnemies={nbEnemies}
                                    selectedAllies={selectedAllies}
                                    autoBonusList={autoBonusList}
                                    handleBonusList={handleBonusList}

                                />
                            </>}
                        </div>
                        <div className='
                                        min-w-200 w-4/12'>
                            <CustomP className='text-center'>Combattants</CustomP>
                            <div className='flex'>
                                <CustomSearchInput
                                    id='searchAllies'
                                    selectedAllies={selectedAllies}
                                    alliesList={alliesList}
                                    nbAllies={nbAllies}
                                    setSelectedAllies={setSelectedAllies}
                                    setAlliesList={setAlliesList}
                                    setNbAllies={setNbAllies}
                                />

                            </div>
                            <div className='flex flex-col
                                                    mt-2'>
                                <CustomP className='text-center'>Total bonus</CustomP>
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: theme.primary,
                                            border: `3px solid ${getScoreRankedColor(bonusValue.mult, theme)}`,
                                        }}
                                        className='flex justify-center items-center rounded-full transition-all
                                                        m-auto w-max'>
                                        <div
                                            style={{
                                                backgroundColor: theme.primary,
                                            }}
                                            className='flex items-center justify-center min-w-10 w-max p-2 rounded-full transition-all'
                                        >
                                            <CustomP reverse >
                                                <TotalBonusDisplayer bonusValue={bonusValue} />
                                            </CustomP>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='
                                            min-w-200 w-4/12'>
                            {manuBonusList && manuBonusList.length > 0 && <>
                                <ManuBonusGroup
                                    side={side}
                                    entity={entity}
                                    nbAllies={nbAllies}
                                    nbEnemies={nbEnemies}
                                    selectedAllies={selectedAllies}
                                    manuBonusList={manuBonusList}
                                    handleBonusList={handleBonusList}
                                    reload={loading}
                                />
                            </>}
                        </div>

                        {/* <div style={separatorStyle} className='sm:hidden w-6/12 m-auto rounded-2xl my-2' /> */}

                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex flex-wrap sm:space-x-12 justify-center'>
                        <div className='flex flex-col justify-center
                                        '>
                            <CustomP className='mx-auto'>ScreenShot</CustomP>
                            <ScreenShotHandler
                                preloadImg={screenShotFile}
                                setImage={setScreenShotFile}
                                setRef={setScreenShotRef}
                            />
                        </div>
                        <div className='flex flex-col justify-center w-max m-auto
                                        '>
                            <MatchPointDisplayer realScale={realScale} selectedAllies={selectedAllies} />
                        </div>
                        <div className='flex flex-col justify-center
                                        '>
                            <CustomP className='mx-auto'>Commentaire</CustomP>
                            <textarea
                                id='commentary'
                                style={{
                                    border: `2px solid ${theme.secondary}`,
                                    boxShadow: theme.boxShadow,
                                    background: `linear-gradient(0.12turn, ${theme.primary_darker}, ${theme.primary}, ${theme.primary_lighter})`,
                                    color: theme.secondary,
                                    carretColor: theme.secondary,
                                }}
                                rows="4"
                                cols="25"
                                className='rounded-sm p-2 focus:outline-none resize-none'
                            >
                            </textarea>
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex justify-center items-center mt-2'>
                        <CustomLoadingButton
                            type='button'
                            onClick={handleSubmit}
                            forceRefresh={[nbAllies, nbEnemies, selectedAllies, autoBonusList, manuBonusList,
                                screenShotFile, screenShot.current, entity, side, bonusValue]}
                            loading={loading}
                            loadingColor={theme.primary}
                        >Enregistrer!</CustomLoadingButton>
                    </div>
                </div>
            </div>
        </div>
    )
}

const VabzeumRegiserMatch = () => {
    return (
        <Access>
            <VabzeumRegisterMatch />
        </Access>
    )
}

export default VabzeumRegiserMatch