/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { CustomP, getLocalObject, getUserAvatarLink, ListEntity, PageTitle, PopMessageParams, setLocal } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import { CustomLoadingButton } from "../../components/button.js";
import { IconHub } from "../../components/icon.js";
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import { ModifyUserSchema } from "../../components/formSchema.js";
import { CustomTextInput } from "../../components/textInput.js";
import { CustomIconButton } from "../../components/iconButton.js";
import { AvatarHandler } from "../../components/avatarHandler.js";

const UserEditForm = () => {
    const { theme } = useContext(ThemeContext)
    const { session, setSession } = useContext(SessionContext)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const [show, setShow] = useState(false)
    const [id, setId] = useState(false)
    const [name, setName] = useState(false)
    const [discordId, setDiscordId] = useState(false)
    const [characterList, setCharacterList] = useState([])
    const [created, setCreated] = useState(false)
    const [avatarFile, setAvatarFile] = useState(false)
    const avatar = useRef(null)

    useEffect(() => {
        axios.post('/api/users', {
            target_member: getLocalObject('session', 'user_id'),
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            setId(response.data[0].id)
            setName(response.data[0].name)
            setDiscordId(response.data[0].discord_id)
            setCharacterList(response.data[0].characters ? response.data[0].characters : [])
            setCreated(response.data[0].created)
            // const current_img = getUserAvatar()
            // setAvatarFile(current_img ? current_img.default : false)
            setAvatarFile(getUserAvatarLink(response.data[0].avatar))
            setShow(true)
        }).catch(error => {
            console.log(error)
        })
    }, [])

    const setAvatarRef = (receivedAvatar) => {
        avatar.current = receivedAvatar
    }

    const addCharacterList = () => {
        const value = document.getElementById("character-adder").value
        if (value.length > 0) {
            var inscript = true
            for (const name in characterList) {
                if (characterList[name].toLowerCase() === value.toLowerCase()) {
                    const new_list = [...characterList]
                    new_list[name] = value
                    setTimeout(() => {
                        setCharacterList(new_list)
                        document.getElementById("character-adder").value = ''
                    }, 20);
                    inscript = false
                    break
                }
            }
            if (inscript) {
                const new_list = [...characterList]
                new_list.push(value)
                setCharacterList([])
                setTimeout(() => {
                    setCharacterList(new_list)
                    document.getElementById("character-adder").value = ''
                }, 20);
            }
        }
    }

    const deleteCharacterList = (elem) => {
        if (characterList.indexOf(elem) !== -1) {
            const new_list = [...characterList]
            new_list.splice(new_list.indexOf(elem), 1)
            setCharacterList([])
            setTimeout(() => {
                setCharacterList(new_list)
            }, 20);
        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            if (event.preventDefault)
                event.preventDefault()
            addCharacterList()
        }
    }

    return (
        <div className='flex flex-wrap justify-center my-8 mx-auto
        w-11/12
        sm: flex'
        >
            {show && <>
                <React.Fragment>
                    <div style={{
                        backgroundColor: theme.secondary,
                        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
                    }}
                        className='rounded-lg  
                        w-full flex-row items-center justify-center max-w-screen-sm'>
                        <div className='mt-16'>
                            <PageTitle reverse>Modifier mon profil</PageTitle>
                        </div>
                        <div className='w-11/12 m-auto flex-row'>
                            <Formik
                                initialValues={{
                                    id: id,
                                    created: created,
                                    pseudo: name,
                                    discord_id: discordId,
                                    password: "",
                                    character_name: ""
                                }}
                                validationSchema={ModifyUserSchema}
                                onSubmit={(values) => {
                                    setLoading(true)
                                    var avatarUrl = null
                                    if (avatar.current) {
                                        avatarUrl = avatar.current.getImageScaledToCanvas().toDataURL()
                                    }
                                    const new_values = { ...values }
                                    delete new_values.character_name
                                    values = {
                                        user_id: session.user_id,
                                        session_token: session.session_token,
                                        ...new_values,
                                        characters: [...characterList],
                                        avatar: avatarUrl
                                    }
                                    axios.post('/api/modify_user', { ...values })
                                    .then(response => {
                                        const sessionData = {
                                            user_id: session.user_id,
                                            pseudo: session.pseudo,
                                            session_token: session.session_token,
                                            roles: session.roles,
                                            avatar: response.data.user.avatar
                                        }
                                        setSession(sessionData)
                                        setLocal('session', JSON.stringify(sessionData))
                                        enqueueSnackbar(response.data.response, PopMessageParams('success'))
                                        window.location.replace('/user/profile')
                                    })
                                    .catch(error => {
                                        if (error.response.status === 403) {
                                            enqueueSnackbar(error.response.data['error'], PopMessageParams('error'))
                                        } else if (error.response.status === 500) {
                                            enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
                                        }
                                        setLoading(false)

                                    })
                                }}
                            >
                                {({
                                    setFieldValue
                                }) => {
                                    return (

                                        <Form>
                                            <CustomP
                                                style={{
                                                    color: theme.primary,
                                                    backgroundColor: theme.primary
                                                }}
                                                className='ml-2 flex items-center'>
                                                <IconHub name='person-circle' className='mr-4' />
                                                <AvatarHandler
                                                    preloadImg={avatarFile}
                                                    setImage={setAvatarFile}
                                                    setRef={setAvatarRef} />
                                            </CustomP>
                                            <div className='flex flex-wrap justify-between'>
                                                <div className='w-2/12 min-w-100'>
                                                    <CustomTextInput
                                                        name="id"
                                                        type="id"
                                                        placeholder="Id"
                                                        startIcon={<IconHub name='hashtag' />}
                                                        disabled
                                                    />
                                                </div>
                                                <div className='w-3/12 min-w-150'>
                                                    <CustomTextInput
                                                        name="created"
                                                        type="created"
                                                        placeholder="Created"
                                                        startIcon={<IconHub name='time' />}
                                                        disabled
                                                    />
                                                </div>
                                            </div>
                                            <div className='w-full justify-center'>
                                                <CustomTextInput
                                                    autoComplete='off'
                                                    name="pseudo"
                                                    type="pseudo"
                                                    placeholder="Pseudo"
                                                    startIcon={<IconHub name='person' />}
                                                />
                                                <CustomTextInput
                                                    name="password"
                                                    type="password"
                                                    placeholder="Mot de passe"
                                                    startIcon={<IconHub name='key' />}
                                                    iconType='component'
                                                />
                                                <CustomTextInput
                                                    autoComplete='off'
                                                    name="discord_id"
                                                    type="discord_id"
                                                    placeholder="ID Discord"
                                                    startIcon={<IconHub name='discord' className='w-6' />}
                                                    disabled
                                                />
                                            </div>
                                            <div className='flex flex-wrap justify-center'>
                                                <div className='sm:w-6/12'>
                                                    <div className='flex items-center w-full'>
                                                        <CustomTextInput
                                                            autoComplete='off'
                                                            id='character-adder'
                                                            onKeyDown={(event) => {
                                                                handleKeyDown(event);
                                                                if (event.keyCode === 13) { setFieldValue('character_name', '') }
                                                            }}
                                                            name="character_name"
                                                            type="character_name"
                                                            placeholder="Persos"
                                                            deletable={true}
                                                            removeinform={() => { setFieldValue('character_name', '') }}
                                                            startIcon={<IconHub name='manette' />}
                                                        />
                                                        <CustomIconButton
                                                            className='mt-7'
                                                            reverse={false}
                                                            onClick={() => { addCharacterList(); setFieldValue('character_name', '') }}>
                                                            <IconHub name='add-circle-filled' />
                                                        </CustomIconButton>
                                                    </div>

                                                    {characterList && <>
                                                        {characterList.map((elem, key) =>
                                                            <div key={key} className='w-10/12 flex justify-center'>
                                                                <ListEntity
                                                                    entity={elem}
                                                                    handleList={addCharacterList}
                                                                    deletable={true}
                                                                    deleteList={deleteCharacterList}
                                                                    refreshList={characterList}
                                                                />
                                                            </div>
                                                        )}
                                                    </>}
                                                </div>
                                                {/* <div className='mt-5 sm:w-6/12'>
                                                    <div
                                                        className='m-3 items-center justify-center cursor-pointer flex'
                                                        onClick={() => { setEnabled(enabled !== true) }}>
                                                        <CustomP >Compte activé: </CustomP>
                                                        <CustomCheckBox className="ml-2 mt-2" selected={enabled} />
                                                    </div>
                                                    {roleList && <>
                                                        <SelectIntoList
                                                            className=''
                                                            list={roleList}
                                                            handleList={handleRoleList}
                                                            selectedList={selectedRoleList}
                                                            handleSelectedList={handleSelectedRoleList}
                                                            leftTitle={'Rôles disponibles'}
                                                            rightTitle={'Rôles selectionnés'}
                                                        />
                                                    </>}
                                                </div> */}
                                            </div>
                                            <div className='mt-8 mb-4 flex justify-center'>
                                                <CustomLoadingButton
                                                    loading={loading}
                                                    loadingColor={theme.primary}
                                                    outlined={true}
                                                    endIcon={<IconHub name='edit' />}
                                                    type='submit'
                                                >
                                                    Sauvegarder les modifications
                                                </CustomLoadingButton>
                                            </div>
                                        </Form>
                                    )
                                }}
                            </Formik>
                        </div>
                    </div>
                </React.Fragment>
            </>}
        </div>
    )
}

const UserEditPage = () => {

    return (
        <Access>
            <UserEditForm />
        </Access>
    )
}


export default UserEditPage