/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from 'react'
import Access from '../../components/access.js'
import { ModalContext, SessionContext, ThemeContext } from '../../components/context.js'
import { PageTitle, CustomP, PopMessageParams, getStyledNumbers, getScoreRankedColor, getUserAvatarLink, getVabrusherDungeonBossImage, getVabrusherQuestImage, getUnknownImage, rankedColors, getVabrusherChallengeImage, getVabrusherScreenshotLink, CustomModal, CustomCheckBox } from '../../components/utils.js'
import { IconHub } from '../../components/icon.js'
import { CustomToggleButton } from '../../components/toggleButton.js'
import axios from 'axios'
import { Avatar } from '@mui/material'
import { CustomLoadingButton } from "../../components/button.js";
import { useSnackbar } from "notistack";
import { ScreenShotHandler } from "../../components/screenshotHandler.js";
import { CustomSearchInput, VabzeumToggleGroup } from '../vabzeum/register_match.js'
import { CustomTextInputNoForm } from '../../components/textInput.js'
// import { BonusListEditor } from './vabzeum_match_edit.js'
import { useHistory } from 'react-router-dom'
import { CustomBonusElement } from './vabzeum_match_edit.js'
import { CustomIconButton } from '../../components/iconButton.js'

// import Lineto from 'react-lineto'

const AllyElem = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = getUserAvatarLink(element.avatar)
    const [hover, setHover] = useState(false)
    const size = props.size || 30
    return (
        <div>
            {element.show && <>
                <div
                    className='flex justify-around items-center cursor-pointer rounded-full items-center transition-all duration-800
                    mt-1 w-max mx-1 '
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        border: `2px solid ${theme.primary_darker}`,
                        boxShadow: theme.boxShadow
                    }}

                >
                    <div
                        onMouseEnter={() => { setHover(true) }}
                        onMouseLeave={() => { setHover(false) }}
                        className={`flex justify-start items-center
                     space-x-2 pr-1`}
                        onMouseDown={() => { props.onClick(element) }}
                    >
                        <Avatar
                            style={{
                                width: size,
                                height: size
                            }}
                            src={pic}
                            className=''
                        />
                        <CustomP
                            reverse
                            className='pr-1'
                        >
                            {element.name}</CustomP>
                    </div>
                    {/* <CustomP
                        onMouseDown={() => { props.onClick(element) }}
                        reverse
                        className=''>
                        <IconHub name='crossbox' />
                    </CustomP> */}
                </div >
            </>}
        </div>

    )
}

const EmptyAllyElem = ({ ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = require('../../statics/images/icons/icon_sleeping.png')
    const size = props.size || 30
    return (
        <div>
            <div
                className='flex justify-around items-center cursor-not-allowed rounded-full items-center transition-all duration-800
                    mt-1 w-max mx-1 '
                style={{
                    border: `2px solid ${theme.primary_darker}`,
                    boxShadow: theme.boxShadow
                }}
            >
                <div
                    className={`flex justify-center items-center
                     pl-4 pr-4 space-x-2`}
                >
                    <Avatar
                        style={{
                            width: size,
                            height: size
                        }}
                        src={pic.default}
                    />
                </div>
            </div >
        </div>

    )
}

export const AllyElemMin = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const pic = getUserAvatarLink(element.avatar)
    const [hover, setHover] = useState(false)
    const size = props.size || 30
    return (
        <div className={props.className}>
            {element.show && <>
                <div
                    onMouseEnter={() => { setHover(true) }}
                    onMouseLeave={() => { setHover(false) }}
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        border: `2px solid ${theme.primary_darker}`,
                        boxShadow: theme.boxShadow
                    }}
                    className={`flex m-1 pr-4 w-max space-x-2 cursor-pointer rounded-full items-center transition-all duration-800`}
                    onMouseDown={() => { props.onClick(element) }}
                >
                    <Avatar
                        style={{
                            width: size,
                            height: size
                        }}
                        src={pic}
                        className=''
                    />
                    <CustomP
                        reverse
                        className=''
                    >
                        {element.name}</CustomP>
                </div>
            </>}
        </div >

    )
}

export const ChallengeElemMin = ({ element, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const [hover, setHover] = useState(false)
    const [image, setImage] = useState(undefined)
    const size = props.size || 45

    useEffect(() => {
        if (element.type === 'dungeon') {
            setImage(getVabrusherDungeonBossImage(element.image))
        } else if (element.type === 'quest') {
            setImage(getVabrusherQuestImage(element.image))
        } else {
            setImage(getUnknownImage())
        }
    }, [])

    return (
        <div className={props.className}>
            <div
                onMouseEnter={() => { setHover(true) }}
                onMouseLeave={() => { setHover(false) }}
                style={{
                    backgroundColor: hover ? theme.primary_lighter : theme.primary,
                    border: `2px solid ${theme.primary_darker}`,
                    boxShadow: theme.boxShadow
                }}
                className={`flex m-1 pr-4 w-max space-x-2 cursor-pointer rounded-full items-center transition-all duration-800`}
                onMouseDown={() => { props.onClick(element) }}
            >
                <Avatar
                    style={{
                        width: size,
                        height: size
                    }}
                    src={image}
                    className=''
                />
                <CustomP
                    reverse
                    className=''
                >
                    {element.name}</CustomP>
            </div>
        </div >

    )
}

const CustomSearchInputChallenge = (props) => {
    const { theme } = useContext(ThemeContext)
    const [hover, setHover] = useState(false)
    const [renderedList, setRenderedList] = useState(false)
    const hoverRef = useRef(false)

    const addChallenge = (challenge) => {
        if (props.selectedChallengeList.indexOf(challenge) === -1) {
            const newSelectedChallengeList = [challenge]
            props.setSelectedChallengeList(newSelectedChallengeList)
            document.getElementById(props.id).value = ''
        }
    }

    const handleHover = () => {
        if (props.selectedChallengeList.length < 5) {
            hoverRef.current = true
            setHover(true)
        }
    }

    const handleUnHover = () => {
        hoverRef.current = false
        setTimeout(() => {
            if (!hoverRef.current) {
                setHover(false)
            }
        }, 200);
    }

    const handleKeyDown = e => {
        if (e.keyCode === 13) {
            e.preventDefault()
            // setTimeout(() => {
            //     const value = document.getElementById(props.id).value
            //     if (renderedList.length === 1) {
            //         addChallenge(renderedList[0].props.element)
            //     } else {
            //         renderedList.forEach(reactElement => {
            //             if (reactElement.props.element.name.toLowerCase() === value.toLowerCase()) {
            //                 addChallenge(reactElement.props.element)
            //             }
            //         })
            //     }
            // }, 50);
        } else {
            setTimeout(() => {
                if (document.getElementById(props.id).value.length !== 1) {
                    props.setRefreshChallengeList(props.refreshChallengeList !== true)
                }
                // const newList = []
                // const value = document.getElementById(props.id).value
                // props.challengeList.forEach(element => {
                //     // var allreadyAdded = false
                //     // if (props.selectedChallengeList.length) {
                //     //     props.selectedChallengeList.forEach(selectedElem => {
                //     //         if (element.name === selectedElem.name) {
                //     //             allreadyAdded = true
                //     //         }
                //     //     })
                //     // }
                //     // if (!allreadyAdded) {
                //     //     if (element.name.toLowerCase().startsWith(value.toLowerCase()) || value.length === 0) {
                //     newList.push(
                //         <ChallengeElemMin key={element.id} element={element} theme={theme} onClick={handleAdd} />)
                //     // }
                //     // }
                // })
                // setRenderedList([...newList])
                // newList.forEach(reactElement => {
                //     const element = reactElement.props.element
                //     var allreadyAdded = false
                //     if (props.selectedChallengeList.length) {
                //         props.selectedChallengeList.forEach(selectedElem => {
                //             if (element.name === selectedElem.name) {
                //                 allreadyAdded = true
                //             }
                //         })
                //     }
                //     if (!allreadyAdded) {
                //         if (element.name.toLowerCase().startsWith(value.toLowerCase()) || value.length === 0) {
                //             if (element.name.toLowerCase() === value.toLowerCase() && newList.length === 1) {
                //                 addChallenge(element)
                //             }
                //             if (element.name.toLowerCase() === value.toLowerCase() && newList.length === 1) {
                //                 addChallenge(element)
                //             }
                //         }
                //     }
                // })
            }, 50);
        }
    }

    const handleAdd = (element) => {
        // if (props.selectedChallengeList.length < props.limitNb) {
        addChallenge(element)
        // }
    }

    useEffect(() => {
        setRenderedList([])
        setTimeout(() => {
            var tmpRenderedList = []
            for (var i = 0; i < props.challengeList.length; i++) {
                // var allreadyAdded = false
                // if (props.selectedChallengeList.length) {
                //     for (var j = 0; j < props.selectedChallengeList.length; j++) {
                //         if (props.challengeList[i].name === props.selectedChallengeList[j].name) {
                //             allreadyAdded = true
                //         }
                //     }
                // }
                // if (!allreadyAdded) {
                tmpRenderedList.push(
                    <ChallengeElemMin key={i} element={props.challengeList[i]} theme={theme} onClick={handleAdd} />)
                // }
            }
            // console.log("renderedList -> ", tmpRenderedList)
            setRenderedList(tmpRenderedList)
        }, 25);
        // if (props.selectedChallengeList.length === props.limitNb) {
        //     setHover(false)
        // }
    }, [props.selectedChallengeList, props.challengeList, props.nbAllies])

    const inputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        opacity: props.selectedChallengeList.length < 5 ? 1 : 0.5,
        height: props.height || undefined,
        width: props.width || undefined
    }

    return (
        <div
            className='w-full flex flex-wrap justify-center items-start mt-1'
            onMouseEnter={() => { handleHover(true) }}
            onMouseLeave={() => { handleUnHover(false) }}
        >
            <div className='flex flex-col justify-center relative
                '

            >
                <input
                    autoComplete='off'
                    id={props.id}
                    label={props.id}
                    style={inputStyle}
                    className='focus:outline-none transition-all duration-800
                        p-2'
                    onKeyDown={handleKeyDown}
                    disabled={props.selectedChallengeList.length < 5 ? false : true}
                    placeholder={props.placeholder || 'Recherche'}
                    onClick={() => { handleHover(true) }}

                />
                {hover && renderedList && renderedList.length > 0 && <>
                    {/* <div className='absolute w-max z-9998 top-0'> */}
                    <div
                        style={{
                            height: 250,
                            border: `2px solid ${theme.secondary_lighter}`,
                            boxShadow: 'inset 0px -10px 10px -10px #000000',
                            opacity: props.selectedChallengeList.length < 5 ? 1 : 0.5,
                            background: `repeating-linear-gradient(140deg, ${theme.primary_lighter}, ${theme.primary_lighter} 25px, ${theme.primary} 25px, ${theme.primary} 40px)`
                        }}
                        className={`absolute top-14 z-20 rounded-b-md transition-all duration-800 overflow-y-auto overflow-x-hidden
                        w-full`}>
                        {renderedList}
                    </div>
                    {/* </div> */}
                </>}
            </div>
        </div >
    )
}

export function notDoubleBonus(bonusList, targetBonus) {
    var check = true
    if (!targetBonus.selected) {
        bonusList.forEach(currBonus => {
            if (currBonus.type.id === targetBonus.type.id && currBonus.selected) {
                check = false
            }
        })
    }
    return check
}

const SelectedAlliesDisplayer = (props) => {
    const [displayedAllies, setDisplayedAllies] = useState([])
    const { theme } = useContext(ThemeContext)

    const delAlly = (ally) => {
        const newSelectedAllies = ([...props.selectedAllies])
        newSelectedAllies.splice(props.selectedAllies.indexOf(ally), 1)
        props.setSelectedAllies(newSelectedAllies)
    }

    const handleDel = (element) => {
        delAlly(element)
    }

    useEffect(() => {
        const tmp_displayedAllies = []
        for (var i = 0; i < props.limitNb; i++) {
            if (props.selectedAllies.length > i) {
                tmp_displayedAllies.push(
                    <AllyElem key={i} element={props.selectedAllies[i]} theme={theme} onClick={handleDel} />
                )
            } else {
                tmp_displayedAllies.push(
                    <EmptyAllyElem key={i} theme={theme} />
                )
            }
        }
        setDisplayedAllies(tmp_displayedAllies)
    }, [props.selectedAllies])

    return (
        <div className='flex flex-wrap justify-center space-x-1 z-10'>
            {displayedAllies}
        </div>
    )
}

const MatchPointDisplayer = (props) => {
    const [nbToShow, setNbToShow] = useState([])
    const [nbToShowGroup, setNbToShowGroup] = useState([])

    useEffect(() => {
        // console.log("reacscale recu ->", props.realScale)
        // console.log("selected aly")
        setNbToShow(getStyledNumbers(props.realScale, 'react', undefined, 25))
        setNbToShowGroup(getStyledNumbers(props.realScale * props.selectedAllies.length, 'react', undefined, 25))
    }, [props.realScale, props.selectedAllies, props.selectedChallengeList])


    return (
        <div className='flex justify-center space-x-6'>
            <div className='flex flex-col justify-center'>
                <CustomP className='mx-auto'>Points{props.selectedAllies.length > 1 ? " par joueur" : ""}</CustomP>
                <div className='flex justify-center mt-2 h-8'>
                    {nbToShow && nbToShow.length > 0 && <>
                        {nbToShow}
                    </>}
                </div>
            </div>
            {props.selectedAllies.length > 1 && <>
                <div className='flex flex-col justify-center'>
                    <CustomP className='mx-auto'>Points totaux</CustomP>
                    <div className='flex justify-center mt-2 h-8'>
                        {nbToShowGroup && nbToShowGroup.length > 0 && <>
                            {nbToShowGroup}
                        </>}
                    </div>
                </div>
            </>}
        </div>
    )
}


const TotalBonusDisplayer = (props) => {
    const [add, setAdd] = useState(undefined)
    const [mult, setMult] = useState(undefined)

    useEffect(() => {
        setAdd(getStyledNumbers(props.bonusValue.add, 'react', '+', 18))
        setMult(getStyledNumbers(props.bonusValue.mult, 'react', 'x', 18))
    }, [props.bonusValue])

    return (
        <div className='flex space-x-4'>
            {props.bonusValue.mult > 0 && <>
                <div className='flex h-6'>
                    {mult}
                </div>
            </>}
            {props.bonusValue.add > 0 && <>
                <div className='flex h-6'>
                    {add}
                </div>
            </>}
        </div>
    )
}

export function errorPlayer(selectedAllies, alliesList) {
    var check = false
    selectedAllies.forEach(allyToCheck => {
        var count = 0
        selectedAllies.forEach(ally => {
            // console.log("check -> ", allyToCheck.id, allyToCheck.name, ' // ally -> ', ally.id, ally.name)
            if (allyToCheck.id === ally.id || allyToCheck.name === ally.name) {
                if (!count) {
                    count += 1
                } else {
                    check = true
                }
            }
        })
    })
    // console.log("premier -> ", check)
    if (!check) {
        var BreakException = {}
        try {
            selectedAllies.forEach(ally => {
                var allyExists = false
                alliesList.forEach(allyToCheck => {
                    // console.log("check -> ", allyToCheck.id, allyToCheck.name, ' // ally -> ', ally.id, ally.name, ally.show)
                    if (allyToCheck.id === ally.id && allyToCheck.name === ally.name && ally.show) {
                        allyExists = true
                    }
                })
                if (!allyExists) {
                    // console.log("ally not exists => ", ally)
                    throw BreakException
                }
            })
        }
        catch (e) {
            if (e !== BreakException) {
                throw e
            }
            check = true
            // console.log('deuxieme -> ', check)
        }
    }
    return check
}

const ChallengeCard = (props) => {
    const { theme } = useContext(ThemeContext)
    return (
        <div>
            {props.challenge && <>
                {/* <Section sectionReverse noRefresh width={250}
                    title={props.challenge ? props.challenge.type_trad : 'Choisir une quête ou un donjon en haut'}
                >
                    <div className='flex flex-wrap justify-center items-center'>

                        <Avatar
                            sx={{ width: 60, height: 60, boxShadow: theme.boxShadow }}
                            src={getVabrusherChallengeImage(props.challenge.image, props.challenge.type)} alt={`img-${props.challenge.type}-${props.challenge.id}`}
                            className='mr-2'
                        />
                        <div className='flex flex-col items-center text-center'>
                            <CustomP weight={'bolder'} reverse>{props.challenge.name}</CustomP>
                            <CustomP weight={'bolder'} reverse>Tiers: {props.challenge.tiers_lvl}</CustomP>
                            <CustomP weight={'bolder'} reverse>Score: {props.challenge.score}</CustomP>
                        </div>
                    </div>
                </Section> */}
                <div className='flex flex-col justify-center
                                        '>
                    <CustomP className='mx-auto'>Epreuve</CustomP>
                    <div
                        style={{
                            width: '215px',
                            height: '116px',
                            border: `2px solid ${theme.secondary_lighter}`,
                            boxShadow: theme.boxShadow,
                            // background: `linear-gradient(0.55turn, ${theme.primary_darker}, ${theme.primary}, ${theme.primary_lighter})`,
                            color: theme.secondary,
                        }}
                        className='rounded-sm p-2 focus:outline-none resize-none flex justify-around items-center'
                    >
                        <Avatar
                            sx={{ width: 60, height: 60, boxShadow: theme.boxShadow }}
                            src={getVabrusherChallengeImage(props.challenge.image, props.challenge.type)} alt={`img-${props.challenge.type}-${props.challenge.id}`}
                            className='mr-2'
                        />
                        <div className='flex flex-col items-center text-center'>
                            <CustomP weight={'bolder'}>{props.challenge.name}</CustomP>
                            <CustomP weight={'bolder'}>Tiers: {props.challenge.tiers_lvl}</CustomP>
                            <CustomP weight={'bolder'}>Score: {props.challenge.score}</CustomP>
                        </div>
                    </div>
                </div>
            </>}
            {!props.challenge && <>
                <div className='flex flex-col justify-center
                                        '>
                    <CustomP className='mx-auto'>Epreuve</CustomP>
                    <div
                        style={{
                            width: '215px',
                            height: '116px',
                            border: `2px solid ${theme.secondary_lighter}`,
                            boxShadow: theme.boxShadow,
                            // background: `linear-gradient(0.55turn, ${theme.primary_darker}, ${theme.primary}, ${theme.primary_lighter})`,
                            color: theme.secondary,
                        }}
                        className='rounded-sm p-2 focus:outline-none resize-none flex justify-around items-center'
                    >
                        <Avatar
                            sx={{ width: 60, height: 60, boxShadow: theme.boxShadow }}
                            src={getVabrusherChallengeImage(undefined, props.challengeType)} alt={`img-challenge-undefined`}

                            className='mr-2'
                        />
                        <div className='flex flex-col items-center text-center'>
                            <CustomP weight={'bolder'}>Tiers: ?</CustomP>
                            <CustomP weight={'bolder'}>Score: ?</CustomP>
                        </div>
                    </div>
                </div>
                {/* <Section sectionReverse noRefresh width={250}
                    title={'Choisir une quête ou un donjon en haut'}
                >
                    <div className='flex flex-wrap justify-center items-center'>

                        <Avatar
                            sx={{ width: 60, height: 60, boxShadow: theme.boxShadow }}
                            src={getVabrusherChallengeImage(undefined, props.challengeType)} alt={`img-challenge-undefined`}

                            className='mr-2'
                        />
                        <div className='flex flex-col items-center text-center'>
                            <CustomP weight={'bolder'} reverse>Tiers: ?</CustomP>
                            <CustomP weight={'bolder'} reverse>Score: ?</CustomP>
                        </div>
                    </div>
                </Section> */}
            </>}

        </div>
    )
}


const BonusListEditor = (props) => {
    const { theme } = useContext(ThemeContext)
    const [bonusList, setBonusList] = useState([])
    const [beforeMult, setBeforeMult] = useState(false)
    const { enqueueSnackbar } = useSnackbar()


    useEffect(() => {
        var tmp_bonusList = []
        var i = 0
        props.bonusList.forEach(bonus => {
            tmp_bonusList.push(<CustomBonusElement key={`bonus_${i}`} bonus={bonus} delBonus={delBonus} />)
            i += 1
        })
        setBonusList(tmp_bonusList)
    }, [theme, props.bonusList])

    const nameInputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        width: '22%',
        minWidth: '74px'
    }

    const operationInputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        width: '10%',
        minWidth: '30px'
    }

    const valueInputStyle = {
        backgroundColor: theme.primary_lighter,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
        width: '22%',
        minWidth: '68px'
    }

    const addBonus = () => {
        const name = document.getElementById('new_bonus_name').value
        const operation = document.getElementById('new_bonus_operation').value
        var value = document.getElementById('new_bonus_value').value
        if (isNaN(parseFloat(value))) {
            value = parseInt(value)
        } else {
            value = parseFloat(value)
        }

        if (!name) {
            enqueueSnackbar("Il manque le nom au bonus", PopMessageParams('error'))
        } else if (isNaN(value)) {
            enqueueSnackbar("La valeur du bonus est numerique (ex: 2, 2.5)", PopMessageParams('error'))
        } else if (!value) {
            enqueueSnackbar("Il manque la valeur du bonus", PopMessageParams('error'))
        } else if (['x', '+'].indexOf(operation) === -1) {
            enqueueSnackbar("L'opérateur du bonus peut être '+' ou 'x'", PopMessageParams('error'))
        } else {

            const tmp_bonusList = [...props.bonusList]
            tmp_bonusList.push({ 'name': `${name}${beforeMult ? '*' : ''}`, 'operation': operation, 'value': value, 'before_mult': beforeMult })
            props.setBonusList(tmp_bonusList)
            document.getElementById('new_bonus_name').value = ''
            document.getElementById('new_bonus_operation').value = 'x'
            document.getElementById('new_bonus_value').value = ''
            setBeforeMult(false)
            // console.log('tmp-> ', tmp_bonusList)
        }
    }

    const delBonus = (bonus) => {
        // console.log(bonus)
        const tmp_bonusList = [...props.bonusList]
        tmp_bonusList.splice(tmp_bonusList.indexOf(bonus), 1)
        props.setBonusList(tmp_bonusList)
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault()
            addBonus()
        }
    }

    const handleOperationKeyDown = (e) => {
        if (e.keyCode !== 9) {
            e.preventDefault()
        }
        if (e.key === '+' || e.key === 'x') {
            document.getElementById('new_bonus_operation').value = e.key
        }
    }

    return (
        <div
            className='mt-1 z-10'
            onKeyDown={handleKeyDown}>
            <div className='flex flex-wrap space-x-2 justify-center'>
                <input
                    id='new_bonus_name'
                    style={nameInputStyle}
                    className='focus:outline-none transition-all duration-800
                    p-2'
                    placeholder='Nom'
                />
                <input
                    onKeyDown={handleOperationKeyDown}
                    id='new_bonus_operation'
                    style={operationInputStyle}
                    className='focus:outline-none transition-all duration-800
                        p-2'
                    placeholder='+ x'
                    defaultValue='x'
                />
                <input
                    id='new_bonus_value'
                    style={valueInputStyle}
                    className='focus:outline-none transition-all duration-800
                        p-2'
                    placeholder='Valeur'
                />
                <div
                    className='flex flex-col items-center justify-center cursor-pointer'
                    onClick={() => setBeforeMult(beforeMult !== true)}>
                    <CustomP>Précalculé</CustomP>
                    <CustomCheckBox className="ml-2 mt-2" selected={beforeMult} />
                </div>
                <CustomIconButton
                    className=''
                    reverse={false}
                    onClick={addBonus}>
                    <IconHub name='add-circle-filled' />
                </CustomIconButton>
            </div>
            <div className='flex flex-wrap mt-1 justify-center'>
                {bonusList}
            </div>
        </div>
    )
}



const AdminVabrusherMatchEdit = () => {
    const { _modal, setModal } = useContext(ModalContext)
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [challengeType, setChallengeType] = useState('dungeon')

    const [selectedAllies, setSelectedAllies] = useState([])

    const [matchId, setMatchId] = useState(-1)

    const [selectedChallengeList, setSelectedChallengeList] = useState([])
    const [challengeList, setChallengeList] = useState([])
    const [refreshChallengeList, setRefreshChallengeList] = useState(false)

    const [adminValidator, setAdminValidator] = useState(false)
    const [botChecker, setBotChecker] = useState(false)
    const [recorder, setRecorder] = useState(0)

    // const [bonusValueBefore, _setBonusValueBefore] = useState({ mult: 1, add: 0 })

    const [bonusList, setBonusList] = useState([])

    const [bonusValue, setBonusValue] = useState({ mult: 1, add: 0 })
    // const [autoBonusValue, _setAutoBonusValue] = useState({ mult: 1, add: 0 })
    // const [manuBonusValue, _setManuBonusValue] = useState({ mult: 1, add: 0 })

    const [autoBonusList, setAutoBonusList] = useState([])
    const [manuBonusList, setManuBonusList] = useState([])

    const [idoleScore, setIdoleScore] = useState(0)

    const [scale, setScale] = useState(0)
    const [realScale, setRealScale] = useState(0)

    const [alliesList, setAlliesList] = useState(false)
    const [alliesListLength, setAlliesListLength] = useState(false)

    const [screenShotFile, setScreenShotFile] = useState(undefined)
    const screenShot = useRef(null)

    const [loading, setLoading] = useState(false)
    const [deleteLoading, setDeleteLoading] = useState(false)
    const [askDeleteValidation, setAskDeleteValidation] = useState(false)

    const { enqueueSnackbar } = useSnackbar()


    const backgroundImage1 = require("../../statics/images/classes/PandaM.png")
    const routerHistory = useHistory();

    const setScreenShotRef = (receivedScreenShot) => {
        screenShot.current = receivedScreenShot
    }

    useEffect(() => {
        axios.post('/api/vabrusher/search_challenge', {
            user_id: session.user_id,
            session_token: session.session_token,
            search: document.getElementById('searchChallenge').value || undefined,
            challengeType: challengeType
        }).then(response => {
            // console.log(response)
            setChallengeList(response.data)
        })
    }, [refreshChallengeList, challengeType])

    useEffect(() => {
        var target = parseInt(new URLSearchParams(window.location.search).get('match'))
        document.getElementById('idole-score').value = idoleScore
        axios.post('/api/vabrusher/get_match', {
            user_id: session.user_id,
            session_token: session.session_token,
            match_id: target
        }).then(response => {
            // console.log(response.data)
            const match = response.data.match_list[0]
            setMatchId(match.match_id)
            match.allies.forEach(ally => {
                ally['show'] = true
            })
            // console.log(match.allies)
            setSelectedAllies([...match.allies])
            // console.log(currentImg)
            setScreenShotFile(getVabrusherScreenshotLink(match.screenshot))
            document.getElementById('commentary').value = match.commentary
            setAdminValidator(match.admin_validator)
            setBotChecker(match.bot_checker)
            setRecorder(match.recorder)
            setIdoleScore(match.idole_score)
            document.getElementById('idole-score').value = match.idole_score
            setChallengeType(match.challenge.type)
            setSelectedChallengeList([match.challenge])
            setScale(match.challenge.score)
            setBonusList(match.bonus ? match.bonus : [])
        })
        axios.post('/api/vabzeum/get_allies', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            setAlliesListLength(response.data.length)
            setAlliesList([...response.data])
        })
        axios.post('/api/vabrusher/get_bonus', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            // console.log(response.data)
            var tmp_AutoBonusList = []
            var tmp_ManuBonusList = []
            response.data.forEach(bonus => {
                if (bonus.auto) {
                    tmp_AutoBonusList.push(bonus)
                } else {
                    tmp_ManuBonusList.push(bonus)
                }
            })
            // console.log(tmp_ManuBonusList)
            setAutoBonusList(tmp_AutoBonusList)
            setManuBonusList(tmp_ManuBonusList)
        })
    }, [])


    useEffect(() => {
        const newBonusValue = { mult: 0, add: 0 }
        const newBonusValueBefore = { mult: 0, add: 0 }
        bonusList.forEach(bonus => {
            // console.log(bonus)
            var plusOne = false
            if (bonus.operation === 'x') {
                if (parseInt(bonus.value) === 1) {
                    bonus.value -= 1
                    plusOne = true
                }
                if (!bonus.before_mult) {
                    newBonusValue.mult += bonus.value
                } else {
                    // console.log("ajout de ", bonus.value)
                    newBonusValueBefore.mult += bonus.value
                }
                if (plusOne) {
                    bonus.value += 1
                }
            } else if (bonus.operation === '+') {
                if (!bonus.before_mult) {
                    newBonusValue.add += bonus.value
                } else {
                    newBonusValueBefore.add += bonus.value
                }
            }
        })
        if (parseInt(newBonusValue.mult) === 0) {
            newBonusValue.mult += 1
        }
        if (parseInt(newBonusValueBefore.mult) === 0) {
            newBonusValueBefore.mult += 1
        }
        // console.log(newBonusValueBefore)
        setBonusValue(newBonusValue)
        if (selectedChallengeList.length > 0) {
            setScale(parseInt(selectedChallengeList[0].score * newBonusValueBefore.mult + newBonusValueBefore.add))
            setRealScale(parseInt(parseInt(selectedChallengeList[0].score * newBonusValueBefore.mult + newBonusValueBefore.add) * newBonusValue.mult + newBonusValue.add))
        }
    }, [bonusList, selectedChallengeList])


    // useEffect(() => {
    //     if (selectedChallengeList.length) {
    //         setScale(parseInt(selectedChallengeList[0].score * bonusValueBefore.mult + bonusValueBefore.add))
    //         setRealScale(parseInt(parseInt(selectedChallengeList[0].score * bonusValueBefore.mult + bonusValueBefore.add) * bonusValue.mult + bonusValue.add))
    //     }
    // }, [selectedChallengeList])

    useEffect(() => {
        if (alliesListLength > 0 && alliesList.length > 0) {
            if (alliesList.length !== alliesListLength) {
                window.location.replace('/vabrusher/register_match')
            }
        }
    }, [alliesList, alliesListLength])

    // useEffect(() => {
    //     const newValue = {
    //         mult: autoBonusValue.mult + manuBonusValue.mult,
    //         add: autoBonusValue.add + manuBonusValue.add
    //     }
    //     if (parseInt(newValue.mult) === 0) {
    //         newValue.mult += 1
    //     }
    //     setBonusValue(newValue)
    //     setRealScale(parseInt(scale * newValue.mult + newValue.add))
    // }, [autoBonusValue, manuBonusValue])

    const handleIdoleScore = e => {
        if (!((e.keyCode >= 48 && e.keyCode <= 57)
            || (e.keyCode >= 96 && e.keyCode <= 105)
            || e.keyCode === 8
            || e.keyCode === 13)) {
            e.preventDefault()
        }
        setTimeout(() => {
            const newIdoleScore = document.getElementById('idole-score').value
            if (newIdoleScore === '') {
                setIdoleScore(0)
            } else {
                setIdoleScore(parseInt(newIdoleScore))
            }
        }, 150);
    }

    const separatorStyle = {
        border: `2px solid ${theme.primary}`,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    const handleSubmit = () => {
        // console.log("SUBMITTING")
        // console.log('side -> ', side)
        // console.log('entity -> ', entity)
        // console.log("allies -> ", nbAllies)
        // console.log("enemies -> ", nbEnemies)
        // console.log('auto bonus -> ', autoBonusList)
        // console.log('manu bonus -> ', manuBonusList)
        // console.log('selected allies ->', selectedAllies)
        // console.log("bonusvalue -> ", bonusValue)
        // console.log('screenshot current -> ', screenShot.current)
        // console.log('screenshotfile -> ', screenShotFile)
        setLoading(true)
        const match = {
            matchId: matchId,
            type: challengeType,
            challenge: selectedChallengeList[0],
            idoleScore: idoleScore,
            alliesList: alliesList,
            bonusList: bonusList,
            selectedAllies: selectedAllies,
            realScale: realScale,
            scale: scale,
            commentary: document.getElementById('commentary').value,
            adminValidator: adminValidator,
            botChecker: botChecker
        }
        axios.post('/api/admin/vabrusher/edit_match', {
            user_id: session.user_id,
            session_token: session.session_token,
            match: match
        }).then(response => {
            enqueueSnackbar(response.data, PopMessageParams('success'))
            routerHistory.push('/admin/vabrusher/list')
        }).catch(() => {
            enqueueSnackbar("Erreur avec l'enregistrement du match", PopMessageParams('error'))

        }).finally(() => {
            setLoading(false)
        })
    }

    const handleDeleteMatch = (method) => {
        if (method === 'ask') {
            setAskDeleteValidation(true)
            setModal(undefined)
        }
        else if (method === 'cancel') {
            setAskDeleteValidation(false)
            setModal(undefined)
        }
        else if (method === 'delete') {
            setDeleteLoading(true)

            // var target = parseInt(new URLSearchParams(window.location.search).get('user'))

            axios.post('/api/admin/vabrusher/delete_match', {
                target_match: matchId,
                user_id: session.user_id,
                session_token: session.session_token
            }).then(response => {
                enqueueSnackbar(response.data, PopMessageParams('success'))
                setTimeout(() => {
                    routerHistory.push('/admin/vabrusher/list')
                }, 200);
            }).catch(() => {
                enqueueSnackbar("Le match n'a pas pu être supprimé", PopMessageParams('error'))
            }).finally(() => {
                setModal(undefined)
                setDeleteLoading(false)
                setAskDeleteValidation(true)
            })
        }
    }

    return (
        <div className='flex flex-wrap justify-center my-4 mx-auto relative
        w-11/12
        sm: flex'
        >
            <div style={{
                backgroundColor: theme.secondary,
                boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
            }}
                className='rounded-lg  relative
                    w-full flex-col items-center max-w-screen-lg'>
                <div>
                    <img
                        style={{
                            transform: 'rotateY(180deg)',
                            opacity: 0.4
                        }}
                        className='absolute right-0 z-0 transition-all duration-800'
                        src={backgroundImage1.default} alt='background_image_default' />
                </div>
                <div id='title-register-match' className=''>
                    <PageTitle reverse>Editer un match ({matchId})</PageTitle>
                </div>
                <div className='w-11/12 m-auto flex-row'>
                    <div className='flex justify-center z-10'>
                        <CustomP>Id de l'enregistreur: {recorder ? recorder : "introuvable"}</CustomP>
                    </div>
                    <div className='flex flex-wrap justify-center items-center z-10'>
                        <div className='mt-5'>
                            <VabzeumToggleGroup>
                                <CustomToggleButton
                                    value="dungeon"
                                    isSelected={challengeType}
                                    onClick={() => { setChallengeType('dungeon') }}
                                    width={140}
                                >
                                    Donjon
                                    <IconHub name='dungeon2' className='w-8' />
                                </CustomToggleButton>
                                <CustomToggleButton
                                    value="quest"
                                    isSelected={challengeType}
                                    onClick={() => { setChallengeType('quest') }}
                                    width={140}
                                >
                                    Quête
                                    <IconHub name='quest2' className='w-8' />
                                </CustomToggleButton>
                            </VabzeumToggleGroup>
                        </div>
                        <div className='mt-4 mr-2'>
                            <CustomSearchInputChallenge
                                id='searchChallenge'
                                selectedChallengeList={selectedChallengeList}
                                challengeList={challengeList}
                                limitNb={1}
                                setSelectedChallengeList={setSelectedChallengeList}
                                setChallengeList={setChallengeList}
                                refreshChallengeList={refreshChallengeList}
                                setRefreshChallengeList={setRefreshChallengeList}
                                height='56px'
                                width='250px'
                                placeholder='Quête ou Donjon'
                            />
                        </div>
                        <div className='z-10' onKeyDown={handleIdoleScore}>

                            <CustomTextInputNoForm
                                name="idole-score"
                                type="input"
                                placeholder="Score d'idole"
                                height='56px'
                                width='180px'
                                bordercolor={rankedColors['gold']}
                                endIcon={<IconHub name='idole' />}
                            />
                        </div>
                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div>
                        <SelectedAlliesDisplayer
                            selectedAllies={selectedAllies}
                            setSelectedAllies={setSelectedAllies}
                            limitNb={8}
                        />
                    </div>
                    {/* <div id='search-allies' className='flex mt-2'>
                        <CustomSearchInput
                            id='searchAllies'
                            selectedAllies={selectedAllies}
                            alliesList={alliesList}
                            nbAllies={undefined}
                            setSelectedAllies={setSelectedAllies}
                            setAlliesList={setAlliesList}
                            setNbAllies={undefined}
                            limitNb={8}
                        />
                    </div> */}
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex flex-wrap justify-center'>
                        {/* <div className='
                                        min-w-200 w-4/12'>
                            {autoBonusList && autoBonusList.length > 0 && <>
                                <AutoBonusGroup
                                    idoleScore={idoleScore}
                                    selectedAllies={selectedAllies}
                                    autoBonusList={autoBonusList}
                                    handleBonusList={handleBonusList}
                                />
                            </>}
                        </div> */}
                        <div className='
                                        min-w-200 w-4/12'>
                            <CustomP className='text-center'>Combattants</CustomP>
                            <div id='search-allies' className='flex'>
                                <CustomSearchInput
                                    id='searchAllies'
                                    selectedAllies={selectedAllies}
                                    alliesList={alliesList}
                                    nbAllies={undefined}
                                    setSelectedAllies={setSelectedAllies}
                                    setAlliesList={setAlliesList}
                                    setNbAllies={undefined}
                                    limitNb={8}
                                />
                            </div>
                            <div className='flex flex-col
                                                    mt-2'>
                                <CustomP className='text-center'>Total bonus</CustomP>
                                <div>
                                    <div
                                        style={{
                                            backgroundColor: theme.primary,
                                            border: `3px solid ${getScoreRankedColor(bonusValue.mult, theme)}`,
                                        }}
                                        className='flex justify-center items-center rounded-full transition-all
                                                        m-auto w-max'>
                                        <div
                                            style={{
                                                backgroundColor: theme.primary,
                                            }}
                                            className='flex items-center justify-center min-w-10 w-max p-2 rounded-full transition-all'
                                        >
                                            <CustomP reverse >
                                                <TotalBonusDisplayer bonusValue={bonusValue} />
                                            </CustomP>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='
                            min-w-200 w-4/12'>
                            {manuBonusList && manuBonusList.length > 0 && <>
                                <ManuBonusGroup
                                    idoleScore={idoleScore}
                                    selectedAllies={selectedAllies}
                                    manuBonusList={manuBonusList}
                                    handleBonusList={handleBonusList}
                                    reload={loading}
                                />
                            </>}
                        </div> */}
                        <div className='
                                        min-w-200 w-4/12'>
                            <CustomP className='text-center'>Bonus</CustomP>
                            <div className='flex justify-center'>
                                <BonusListEditor
                                    bonusList={bonusList}
                                    setBonusList={setBonusList}
                                />
                            </div>
                        </div>
                        <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    </div>
                    <div className='flex flex-wrap sm:space-x-12 justify-center'>
                        <ChallengeCard challenge={selectedChallengeList.length ? selectedChallengeList[0] : undefined} challengeType={challengeType} />
                        <div className='flex flex-col justify-center w-max m-auto
                                        '>
                            <MatchPointDisplayer realScale={realScale} selectedChallenge={selectedChallengeList} selectedAllies={selectedAllies} />
                        </div>
                        <div className='flex flex-col justify-center
                                        '>
                            <CustomP className='mx-auto'>ScreenShot</CustomP>
                            <ScreenShotHandler
                                preloadImg={screenShotFile}
                                setImage={setScreenShotFile}
                                setRef={setScreenShotRef}
                                preventReupload
                            />
                        </div>

                    </div>
                    <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                    <div className='flex justify-center items-center mt-2'>
                        <div className='flex flex-col justify-center
                                        '>
                            <CustomP className='mx-auto'>Commentaire</CustomP>
                            <textarea
                                id='commentary'
                                style={{
                                    border: `2px solid ${theme.secondary}`,
                                    boxShadow: theme.boxShadow,
                                    background: `linear-gradient(0.12turn, ${theme.primary_darker}, ${theme.primary}, ${theme.primary_lighter})`,
                                    color: theme.secondary,
                                    carretColor: theme.secondary,
                                }}
                                rows="4"
                                cols="25"
                                className='rounded-sm p-2 focus:outline-none resize-none mr-4 mb-4'
                            >
                            </textarea>
                        </div>
                        <div className='flex flex-col justify-end px-2 mx-2'>
                            <div
                                className='flex items-center cursor-pointer mb-4'
                                onClick={() => setAdminValidator(adminValidator !== true)}>
                                <CustomP>Valider le match: </CustomP>
                                <CustomCheckBox className="ml-2 mt-2" selected={adminValidator} />
                            </div>
                            <div
                                className='flex items-center cursor-pointer'
                                onClick={() => setBotChecker(botChecker !== true)}>
                                <CustomP>Traité par le bot: </CustomP>
                                <CustomCheckBox className="ml-2 mt-2" selected={botChecker} />
                            </div>

                        </div>
                        <div>
                            <CustomLoadingButton
                                type='button'
                                onClick={handleSubmit}
                                forceRefresh={[selectedAllies, autoBonusList, manuBonusList,
                                    screenShotFile, screenShot.current, bonusValue, idoleScore, selectedChallengeList, adminValidator,
                                    botChecker]}
                                loading={loading}
                                loadingColor={theme.primary}
                                disabled={(selectedAllies.length > 1 && selectedChallengeList.length > 0) ? false : true}
                            >Enregistrer!</CustomLoadingButton>
                            <CustomLoadingButton
                                variant='error'
                                loading={deleteLoading}
                                loadingColor={theme.primary}
                                endIcon={<IconHub name='delete' />}
                                type='button'
                                onClick={() => { handleDeleteMatch('ask') }}
                            >
                                Supprimer
                            </CustomLoadingButton>
                            {askDeleteValidation && <>
                                <CustomModal disableAutoVanish>
                                    <div style={{
                                        backgroundColor: theme.primary
                                    }}
                                        className='p-4'>
                                        Es-tu sûr de vouloir supprimer le match? La suppression est définitive
                                        <div className='flex justify-around'>
                                            <CustomLoadingButton
                                                outlined
                                                reverse
                                                loading={loading}
                                                loadingColor={theme.primary}
                                                endIcon={<IconHub name='crossbox' />}
                                                type='button'
                                                onClick={() => { handleDeleteMatch('cancel') }}
                                            >
                                                Annuler
                                            </CustomLoadingButton>
                                            <CustomLoadingButton
                                                variant='error'
                                                endIcon={<IconHub name='delete' />}
                                                type='button'
                                                onClick={() => { handleDeleteMatch('delete') }}
                                            >
                                                Supprimer
                                            </CustomLoadingButton>
                                        </div>
                                    </div>
                                </CustomModal>
                            </>}
                        </div>
                    </div>
                    <CustomP>*: Ce bonus est calculé avant les autres</CustomP>
                </div>
            </div>
        </div>
    )
}


const AdminVabrusherMatchEditPage = () => {



    return (
        <Access>
            <AdminVabrusherMatchEdit />
        </Access>
    )
}

export default AdminVabrusherMatchEditPage