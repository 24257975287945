/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Access from '../../components/access.js'
import { CustomP, getRankedTextures, getUserAvatarLink, PageTitle, PopMessageParams, Section } from "../../components/utils.js";
import { CustomLoadingButton } from "../../components/button.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import axios from "axios";
import AnimatedNumber from "animated-number-react";
import { Avatar } from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { IconHub } from "../../components/icon.js";
import { useSnackbar } from "notistack";

const VabzeumThisMonth = () => {
    const { session } = useContext(SessionContext)
    const [refreshComp, setRefreshComp] = useState(false)
    const [nbMatchToValid, setNbMatchToValid] = useState(-1)
    const [nbMatchThisMonth, setNbMatchThisMonth] = useState(-1)
    const [nbPointThisMonth, setNbPointThisMonth] = useState(-1)
    const [nbPlayerWithPoints, setNbPlayerWithRequiredPoints] = useState(-1)

    const refreshData = () => {
        axios.post('/api/vabzeum_nb_match_preview', {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => {
                setNbMatchToValid(response.data['nb_match_to_valid'])
                setNbMatchThisMonth(response.data['nb_match_this_month'])
                setNbPlayerWithRequiredPoints(response.data['nb_player_with_required_points'])
            }).catch(() => {
                setNbMatchToValid(-1)
                setNbMatchThisMonth(-1)
                setNbPlayerWithRequiredPoints(-1)
            })
        axios.post('/api/vabzeum_match_point_preview', {
            user_id: session.user_id,
            session_token: session.session_token,
            dateRange: 'month'
        }).then(response => {
            setNbPointThisMonth(response.data)
        }).catch(() => {
            setNbPointThisMonth(-1)
        })
    }

    useEffect(() => {
        refreshData()
    }, [refreshComp])

    const formatValue = (value) => value.toFixed(0);

    return (
        <div>
            <Section noRefresh title='Ce mois-ci' callBack={setRefreshComp}>
                <div className='
                pr-8 pt-4 w-max'>
                    <CustomP>
                        <AnimatedNumber value={nbMatchThisMonth} formatValue={formatValue} /> {`match${nbMatchThisMonth > 1 ? 's' : ''}`}</CustomP>
                    <CustomP>
                        <AnimatedNumber value={nbMatchToValid} formatValue={formatValue} /> {`match${nbMatchToValid > 1 ? 's' : ''}`} à valider</CustomP>
                    <CustomP>
                        <AnimatedNumber value={nbPointThisMonth} formatValue={formatValue} /> {`point${nbPointThisMonth > 1 ? 's' : ''}`}</CustomP>
                    <CustomP>
                        <AnimatedNumber value={nbPlayerWithPoints} formatValue={formatValue} /> {`joueur${nbPlayerWithPoints > 1 ? 's' : ''}`} avec plus de 100 points!</CustomP>
                </div>
            </Section>
        </div>
    )
}

const VabzeumGlobal = () => {
    const { session } = useContext(SessionContext)
    const [refreshComp, setRefreshComp] = useState(false)
    const [nbMatch, setNbMatch] = useState(-1)
    const [bestMonth, setBestMonth] = useState(false)

    const refreshData = () => {
        axios.post('/api/vabzeum/global_data', {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => {
                setNbMatch(response.data.nb_match)
                const best_month = response.data.best_month
                setBestMonth(`Meilleur mois: ${best_month.month_fr} ${best_month.year}: ${best_month.score} points!`)
            }).catch(() => {
                setNbMatch(-1)
                setBestMonth(false)
            })
    }

    useEffect(() => {
        refreshData()
    }, [refreshComp])

    const formatValue = (value) => value.toFixed(0);

    return (
        <div>
            <Section noRefresh title='Depuis le début' callBack={setRefreshComp}>
                <div className='
                pr-8 pt-4 w-max'>
                    <CustomP>
                        <AnimatedNumber value={nbMatch} formatValue={formatValue} /> {`match${nbMatch > 1 ? 's' : ''}`}</CustomP>
                    {bestMonth && <>
                        <CustomP>
                            {bestMonth}
                        </CustomP>
                    </>}
                </div>
            </Section>
        </div>
    )
}

const VabzeumRankingTableModel = (props) => {
    const { theme } = useContext(ThemeContext)
    return (
        <div>
            <table className="table-auto">
                <thead className="rounded-md" style={{
                    backgroundColor: theme.secondary_darker
                }}>
                    <tr>
                        <th colSpan={2} className="p-4"><CustomP>Joueur</CustomP></th>
                        <th className="p-4"><CustomP>Atk</CustomP></th>
                        <th className="p-4"><CustomP>Def</CustomP></th>
                        <th className="p-4"><CustomP>Tot</CustomP></th>
                    </tr>
                </thead>
                <tbody className="rounded-md">
                    {props.children}
                </tbody>
            </table>
        </div>
    )
}

const VabzeumRankingTableRow = (props) => {
    const { theme } = useContext(ThemeContext)
    const [userAvatar, setUserAvatar] = useState(undefined)

    const reverse = (props.rank <= 3 && theme.type === 'light') ? true : false
    const weight = props.rank <= 3 ? 'bold' : undefined
    const texture = getRankedTextures(props.rank).default
    const bgColor = props.rank % 2 === 0 ? theme.secondary : theme.secondary_darker

    useEffect(() => {
        setUserAvatar(getUserAvatarLink(props.avatar))
    }, [])

    return (
        <tr className="text-center" style={{
            backgroundImage: texture ? `url(${texture})` : undefined,
            backgroundColor: bgColor,
            borderSpacing: '0 5px'
        }}>
            <td className="p-2 align-middle"><Avatar src={userAvatar} alt={`${props.playerName}_avatar`} /></td>
            <td className="p-2 align-middle"><CustomP weight={weight} reverse={reverse}>{props.playerName}</CustomP></td>
            <td className="p-2 align-middle"><CustomP weight={weight} reverse={reverse}>{props.atkScore}</CustomP></td>
            <td className="p-2 align-middle"><CustomP weight={weight} reverse={reverse}>{props.defScore}</CustomP></td>
            <td className="p-2 align-middle"><CustomP weight={weight} reverse={reverse}>{props.atkScore + props.defScore}</CustomP></td>
        </tr>
    )
}

const VabzeumRanking = () => {
    const { session } = useContext(SessionContext)
    const { theme } = useContext(ThemeContext)
    const [refreshComp, setRefreshComp] = useState(false)
    const [rankingRows, setRankingRows] = useState([])

    const refreshData = () => {
        axios.post('/api/vabzeum/get_month_ranking', {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => {
                const tmp_rankingRows = []
                if (response.data) {
                    const tmp_ranking = response.data
                    var i = 1
                    tmp_ranking.forEach(user => {
                        tmp_rankingRows.push(<VabzeumRankingTableRow key={i} rank={i} playerId={user.id} playerName={user.name} atkScore={user.atk_score} defScore={user.def_score} avatar={user.avatar} />)
                        i += 1
                    });
                    setRankingRows(tmp_rankingRows)
                }
            }).catch(() => {
            })
    }

    useEffect(() => {
        refreshData()
    }, [refreshComp])


    return (
        <div>
            <Section noRefresh title='Classement' callBack={setRefreshComp}>
                <div className='
                pr-8 pt-4 w-max'>
                    <Scrollbars autoHide autoHeight autoHeightMax={500}>
                        <div style={{
                            borderColor: theme.secondary,
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                        }}
                            v-for="item in items" className='w-full transform even:bg-gray-400'>
                            <VabzeumRankingTableModel>
                                {rankingRows}
                            </VabzeumRankingTableModel>
                        </div>
                    </Scrollbars>
                </div>
            </Section>
        </div>
    )
}

const Vabzeum = () => {
    const [isAdmin, setIsAdmin] = useState(false)
    const { session } = useContext(SessionContext)
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        axios.post('/api/is_admin', {
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            setIsAdmin(response.data.is_admin)
        }).catch(() => {
            enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
        })
    }, [])

    return (
        <div className=''>
            <PageTitle>Vabzeum</PageTitle>
            <div className="flex justify-center">
                <NavLink to='/vabzeum/register_match'>
                    <CustomLoadingButton>
                        Enregistrer un match
                    </CustomLoadingButton>
                </NavLink>
            </div>
            <div className="flex flex-wrap justify-center">
                <div className="flex flex-col">
                    <div className="flex justify-center w-full">
                        <NavLink to={isAdmin ? '/admin/vabzeum/list' : '/vabzeum/list'} className={'w-10/12'}>
                            <CustomLoadingButton reverse fullWidth={true}>
                                <IconHub name={'wait'} className='w-8 h-8 mr-4'></IconHub>
                                Voir l'historique
                            </CustomLoadingButton>
                        </NavLink>
                    </div>
                    <div className='m-4' >
                        <VabzeumThisMonth />
                    </div>
                    <div className='m-4' >
                        <VabzeumGlobal />
                    </div>
                </div>
                <div className='m-4 h-full' >
                    <VabzeumRanking />
                </div>
            </div>
        </div>
    );
}

const VabzeumPage = () => {
    return (
        <Access>
            <Vabzeum />
        </Access>
    )
}

export default VabzeumPage