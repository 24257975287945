/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from "react";
import { useHistory } from 'react-router';
import { clearSession, SessionContext } from "../components/context";
import { CustomGif, PageTitle, removeLocal } from "../components/utils";



const LogoutComponent = () => {
    const { _session, setSession } = useContext(SessionContext)
    const routerHistory = useHistory()

    useEffect(() => {
        clearSession(setSession)
        removeLocal("session")
        // enqueueSnackbar('A bientôt :)', PopMessageParams('success'))
        setTimeout(() => {
            routerHistory.push('/')
        }, 1200);
    }, [])

    return (
        <div className='w-full'>
            <PageTitle>Deconnexion</PageTitle>
            <CustomGif
                type='logout'
                alt="deconnexion"
                title='A plus dans le bus'
                className='mt-10 rounded mx-auto'
                />
        </div>
    );
}

function Logout() {
    return (
        <LogoutComponent />
    )
}

export default Logout