/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from "react";
import { useField } from 'formik';
import { CustomIconButton } from "./iconButton.js";
import { IconHub } from "./icon.js";
import { CustomP } from "./utils.js";


export const CustomTextInput = ({ label, type, startIcon, endIcon, iconType, deletable, removeinform, ...props }) => {
    const [field, meta] = useField(props);
    const [marginIcon, setMarginIcon] = useState('mx-2')
    const [showPassword, setShowPassword] = useState(false)
    iconType = iconType || 'component'

    const reset_value = (id) => {
        if (id) {
            document.getElementById(id).value = ''
        }
    }

    const customstyle = {
        opacity: props.disabled ? 0.7 : 1,
        cursor: props.disabled ? 'not-allowed' : 'pointer'
    }

    return (
        <>
            <div
                style={customstyle}
                className={`w-full`}>
                <CustomP>
                    <label className="mt-5 block text-sm font-medium"
                        htmlFor={props.id || props.name}>{label}</label>
                </CustomP>
                <div style={{
                    borderRadius: '2px',
                    borderColor: (meta.touched && meta.error) ? 'red' : 'gray',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                }}
                    className={`flex items-center bg-gray-300 transition-all rounded-xl`}>
                    {startIcon && <>
                        <div className={`transition-all duration-500 text-vab-blue rounded-l-md ${marginIcon}`}>
                            {iconType === 'component' && <>
                                {startIcon}
                            </>}
                            {iconType === 'image' && <>
                                <img className='w-6'
                                    src={startIcon} alt='icon' title='icon' />
                            </>}
                        </div>
                    </>}
                    <input
                        id={props.id || props.name}
                        className="text-input placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none
                            w-full px-3 py-2 "
                        onMouseEnter={() => { setMarginIcon('mx-1') }}
                        onMouseLeave={() => { setMarginIcon('mx-2') }}
                        type={type !== 'password' ? type : (showPassword ? 'text' : 'password')}
                        {...field}
                        {...props}
                    />
                    {deletable && <>
                        <CustomIconButton
                            className=''
                            reverse={false}
                            // onClick={() => { }}
                            invisible={true}
                            onClick={() => {
                                if (removeinform) {
                                    removeinform()
                                } else {
                                    reset_value(props.id)
                                }
                            }}
                        >
                            <IconHub name='clear' />
                        </CustomIconButton>
                    </>}
                    {endIcon && <>
                        <div className={`transition-all duration-500 text-vab-blue rounded-l-md ${marginIcon}`}>
                            {iconType === 'component' && <>
                                {endIcon}
                            </>}
                            {iconType === 'image' && <>
                                <img className='w-6'
                                    src={endIcon} alt='icon' title='icon' />
                            </>}
                        </div>
                    </>}
                    {type === 'password' && <>
                        <div
                            className={`transition-all duration-500 text-vab-blue rounded-l-md cursor-pointer ${marginIcon}`}
                            onClick={() => { setShowPassword(showPassword !== true) }}
                        >
                            {showPassword && <><IconHub name='eye' /></>}
                            {!showPassword && <><IconHub name='eye-off' /></>}
                        </div>
                    </>}
                </div>
                {meta.touched && meta.error ? (
                    <div className="font-bold py-1 px-2 text-red-400 text-right">{meta.error}</div>
                ) : null}
            </div>
        </>

    );
};


export const CustomTextInputNoForm = ({ label, type, startIcon, endIcon, iconType, deletable, removeinform, ...props }) => {
    // const [field, meta] = useField(props);
    const [marginIcon, setMarginIcon] = useState('mx-2')
    const [showPassword, setShowPassword] = useState(false)
    iconType = iconType || 'component'

    const reset_value = (id) => {
        if (id) {
            document.getElementById(id).value = ''
        }
    }

    const customstyle = {
        opacity: props.disabled ? 0.7 : 1,
        cursor: props.disabled ? 'not-allowed' : 'pointer'
    }

    return (
        <>
            <div
                style={customstyle}
                className={`w-full`}>
                <CustomP>
                    <label className="mt-5 block text-sm font-medium"
                        htmlFor={props.id || props.name}>{label}</label>
                </CustomP>
                <div style={{
                    borderRadius: '2px',
                    backgroundColor: props.bordercolor ? props.bordercolor : 'gray',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                    height: props.height || undefined,
                    width: props.width || undefined
                }}
                    className={`flex items-center bg-gray-300 transition-all rounded-xl`}>
                    {startIcon && <>
                        <div className={`transition-all duration-500 text-vab-blue rounded-l-md ${marginIcon}`}>
                            {iconType === 'component' && <>
                                {startIcon}
                            </>}
                            {iconType === 'image' && <>
                                <img className='w-6'
                                    src={startIcon} alt='icon' title='icon' />
                            </>}
                        </div>
                    </>}
                    <input
                        id={props.id || props.name}
                        className="text-input placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none
                            w-full px-3 py-2 "
                        onMouseEnter={() => { setMarginIcon('mx-1') }}
                        onMouseLeave={() => { setMarginIcon('mx-2') }}
                        type={type !== 'password' ? type : (showPassword ? 'text' : 'password')}
                        // {...field}
                        {...props}
                    />
                    {deletable && <>
                        <CustomIconButton
                            className=''
                            reverse={false}
                            // onClick={() => { }}
                            invisible={true}
                            onClick={() => {
                                if (removeinform) {
                                    removeinform()
                                } else {
                                    reset_value(props.id)
                                }
                            }}
                        >
                            <IconHub name='clear' />
                        </CustomIconButton>
                    </>}
                    {endIcon && <>
                        <div className={`transition-all duration-500 text-vab-blue rounded-l-md ${marginIcon}`}>
                            {iconType === 'component' && <>
                                {endIcon}
                            </>}
                            {iconType === 'image' && <>
                                <img className='w-6'
                                    src={endIcon} alt='icon' title='icon' />
                            </>}
                        </div>
                    </>}
                    {type === 'password' && <>
                        <div
                            className={`transition-all duration-500 text-vab-blue rounded-l-md cursor-pointer ${marginIcon}`}
                            onClick={() => { setShowPassword(showPassword !== true) }}
                        >
                            {showPassword && <><IconHub name='eye' /></>}
                            {!showPassword && <><IconHub name='eye-off' /></>}
                        </div>
                    </>}
                </div>
                {/* {meta.touched && meta.error ? (
                    <div className="font-bold py-1 px-2 text-red-400 text-right">{meta.error}</div>
                ) : null} */}
            </div>
        </>

    );
};