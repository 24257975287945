
// eslint-disable-next-line
/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/
/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from 'react'
import AvatarEditor from 'react-avatar-editor'
import React from 'react'
import { useInterval } from './utils'
import { ThemeContext } from './context'
import Slider from '@mui/material/Slider'
import { IconHub } from './icon'
import { CustomIconButton } from './iconButton'

export const AvatarHandler = (props) => {

    const [scale, setScale] = useState(1)
    const [rotate, setRotate] = useState(0)
    const borderRadius = 0
    const [width, _setWidth] = useState(150)
    const [height, _setHeight] = useState(150)
    const [hover, setHover] = useState(false)
    const { theme } = useContext(ThemeContext)

    const [drag, setDrag] = useState(false)

    useEffect(() => {
        window.addEventListener('paste', onPaste);
        window.addEventListener('mouseup', onDragLeave);
        window.addEventListener('mouseleave', onDragLeave);
        window.addEventListener('dragenter', onDragEnter);
        window.addEventListener('dragover', onDragOver);
        window.addEventListener('drop', onDrop);
        // document.getElementById('dragbox').addEventListener('dragleave', onDragLeave);
        return () => {
            window.removeEventListener('paste', onPaste)
            window.removeEventListener('mouseup', onDragLeave);
            window.removeEventListener('mouseleave', onDragLeave);
            window.removeEventListener('dragenter', onDragEnter);
            window.removeEventListener('dragover', onDragOver);
            window.removeEventListener('drop', onDrop);
            // document.getElementById('dragbox').removeEventListener('dragleave', onDragLeave);
        }
    }, [])

    useInterval(() => {
        setDrag(false)
    }, 2500)

    const onDragEnter = e => {
        e.stopPropagation();
        e.preventDefault();
        setDrag(true)
        return false
    }

    const onDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(false)
        return false
    }

    const onDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(true)
        return false
    }

    const onDrop = e => {
        e.preventDefault();
        let files = e.dataTransfer.files;
        props.setImage(files[0])
        setDrag(false)
        return false
    }

    const onPaste = (e) => {
        if (e.clipboardData.files.length) {
            props.setImage(e.clipboardData.files[0])
        }
    }


    const handleNewImage = e => {
        props.setImage(e.target.files[0])
    }

    const handleScale = e => {
        const scale = parseFloat(e.target.value)
        setScale(scale)
    }

    const handleRotate = e => {
        const rotate = parseFloat(e.target.value)
        setRotate(rotate)
    }


    return (
        <div className='transition-all flex w-full flex-wrap items-center sm:justify-between justify-around' >
            <div className='flex items-center w-max'>
                <label
                    id='dragbox'
                    style={{
                        backgroundColor: hover ? theme.primary_lighter : theme.primary,
                        color: theme.secondary,
                        boxShadow: theme.boxShadow
                    }}
                    htmlFor='files'
                    className="transition-all cursor-pointer p-2 rounded-md w-max"
                    onMouseEnter={() => { setHover(true) }}
                    onMouseLeave={() => { setHover(false) }}
                >{drag ? "Dépose ton avatar ici" : "Choisir un Avatar"}</label>
                <input
                    id="files"
                    type="file"
                    className='hidden'
                    onChangeCapture={handleNewImage}

                />
            </div>
            {props.preloadImg && <>
                <div className='flex flex-wrap w-8/12 justify-center'>
                    <div
                        style={{ boxShadow: theme.boxShadow }}
                        className='relative m-2 rounded-full'>
                        <AvatarEditor
                            ref={props.setRef}
                            image={props.preloadImg}
                            width={width}
                            height={height}
                            border={0}
                            borderRadius={borderRadius}
                            color={[255, 255, 255, 0.4]} // RGBA
                            scale={scale}
                            rotate={rotate}
                            backgroundColor=''
                            className='rounded-full'
                        />
                        <div className='absolute -top-4 -right-4'>
                            <CustomIconButton
                                themevariant='error'
                                onClick={() => { props.setImage(false) }}
                            >
                                <IconHub name='clear' />
                            </CustomIconButton>
                        </div>
                    </div>

                    <div className='w-full flex flex-wrap justify-center'>
                        <div className='flex w-150 items-center'>
                            <IconHub name='zoom' className='mx-4' />
                            <Slider
                                style={{ color: theme.primary }}
                                defaultValue={0}
                                aria-label="Small"
                                valueLabelDisplay="auto"
                                onChange={handleScale}
                                step={0.1}
                                min={1}
                                max={15}
                            />
                        </div>
                        <div className='flex w-150 items-center'>
                            <IconHub name='rotate' className='mx-4' />
                            <Slider
                                style={{ color: theme.primary }}
                                defaultValue={0}
                                aria-label="Default"
                                valueLabelDisplay="auto"
                                onChange={handleRotate}
                                step={1}
                                min={0}
                                max={360}
                            />
                        </div>
                    </div>
                </div>
            </>}
        </div >
    )
}