/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }] */

import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { PageTitle, PopMessageParams, ModalImg, getVabzeumScoreLink, CustomP } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useSnackbar } from "notistack";
import { Form, Formik } from "formik";
import { CustomTextInput } from "../../components/textInput.js";
import { CustomLoadingButton } from "../../components/button.js";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const AdminVabzeumGenerateScore = () => {
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const { enqueueSnackbar } = useSnackbar()
    const [loading, setLoading] = useState(false)
    // const [chartData, setChartData] = useState(false)
    // const [chartOptions, setChartOptions] = useState(false)
    const [chartFile, setChartFile] = useState(false)
    // const [chartPath, setChartPath] = useState(false)
	
    useEffect(() => {
       setChartFile(getVabzeumScoreLink('vabzeum_score.png'))
    }, [])

    return (
        <div className='flex flex-wrap justify-center my-8 mx-auto
        w-11/12
        sm: flex'>
            <React.Fragment>
                <div style={{
                    backgroundColor: theme.secondary,
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
                }}
                    className='rounded-lg  
                w-full flex-row items-center justify-center max-w-screen-sm p-2'>
                    <div className='mt-16'>
                        <PageTitle reverse>Générer les scores: Vabzeum</PageTitle>
                    </div>
                    <div className='w-11/12 m-auto flex-row'>
                        <Formik
                            initialValues={{
                                starting_date: "",
                                ending_date: ""
                            }}
                            // validationSchema={}
                            onSubmit={(values) => {
                                if (values.starting_date && values.ending_date && values.starting_date > values.ending_date) {
                                    enqueueSnackbar("La date de fin doit être strictement supérieure à la date de début", PopMessageParams())
                                } else {
                                    setLoading(true)
                                    axios.post('/api/admin/vabzeum/generate_score', {
                                        user_id: session.user_id,
                                        session_token: session.session_token,
                                        search: {
                                            starting_date: values.starting_date,
                                            ending_date: values.ending_date
                                        }
                                    }).then(response => {
                                        // setChartFile(response.data.chart_file)
                                        // console.log(respo/nse.data.chart.path)
                                        // var test = session.user_id + '_min.png'
                                        // var pic = require(`../../statics/images/users/${test}`)
                                        // var pic = require(`%PUBLIC_URL%/vabzeum/charts/${response.data.chart.path}`)
                                        // console.log(pic)
                                        // console.log(typeof(pic))
                                        // // pic = require(pic)
                                        // setChartPath(`%PUBLIC_URL%/vabzeum/charts/${response.data.chart.path}`)
                                        if (!response.data.chart.path) {
                                            enqueueSnackbar('Aucun match trouvé pendant cette période!', PopMessageParams())
                                        } else {
					    window.location.replace('/admin/vabzeum/generate_score')
					}
                                        // else {
                                        //     window.location.replace(`/admin/vabzeum/generate_score?chart=${response.data.chart.path}`)
                                        // }
                                        // const scores = response.data.scores
                                        // const chart = response.data.chart
                                        // const labels = scores.map(({ name }) => name);

                                        // const config = {
                                        //     type: 'bar',
                                        //     data: data,
                                        //     options: {
                                        //       plugins: {
                                        //         title: {
                                        //           display: true,
                                        //           text: 'Chart.js Bar Chart - Stacked'
                                        //         },
                                        //       },
                                        //       responsive: true,
                                        //       scales: {
                                        //         x: {
                                        //           stacked: true,
                                        //         },
                                        //         y: {
                                        //           stacked: true
                                        //         }
                                        //       }
                                        //     }
                                        //   };
                                        // const data = {
                                        //     labels,
                                        //     datasets: [
                                        //         {
                                        //             label: 'Attaque',
                                        //             data: scores.map(({ atk }) => atk),
                                        //             backgroundColor: '#294B4C',
                                        //         },
                                        //         {
                                        //             label: 'Défense',
                                        //             data: scores.map(({ def }) => def),
                                        //             backgroundColor: '#C6B299',
                                        //         },
                                        //     ],
                                        // };
                                        // console.log("data -> ", data)
                                        // setChartOptions(config)
                                        // setChartData(data)
                                        // console.log(response.data)
                                        // }).catch(() => {
                                        //     enqueueSnackbar("Une erreur est survenue", PopMessageParams('error'))
                                    }).finally(() => {
                                        setLoading(false)
                                    })
                                }
                            }}
                        >
                            {() => {
                                return (
                                    <div>
                                        <Form>
                                            <div className="flex-col justify-center">
                                                <div className="flex flex-wrap justify-center">
                                                    <div className="min-w-200 w-5/12 m-1">
                                                        <CustomTextInput
                                                            name="starting_date"
                                                            type="date"
                                                            label="Date de début"
                                                        />
                                                    </div>
                                                    <div className="min-w-200 w-5/12 m-1">
                                                        <CustomTextInput
                                                            name="ending_date"
                                                            type="date"
                                                            label="Date de fin"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex justify-center
                                                my-2">
                                                    <CustomLoadingButton
                                                        type='submit'
                                                        loading={loading}
                                                        loadingColor={theme.primary}
                                                    >Générer</CustomLoadingButton>
                                                </div>
                                            </div>
                                        </Form>
                                        <div>
                                        </div>
                                    </div>
                                )
                            }}
                        </Formik>
                    </div>
                    {chartFile && <>
                        <div className="flex flex-col justify-center items-center">
                            <CustomP>Derniers scores générés:</CustomP>
                            <CustomLoadingButton>
                                <a href={chartFile} download>Télécharger les scores</a>
                            </CustomLoadingButton>
                            <div className="w-32">
                                <ModalImg src={chartFile} alt='score recap' />
                            </div>
                        </div>
                    </>}

                    {/* {chartData && chartOptions && <>
                    <div style={{
                        backgroundColor: theme.secondary_darker
                    }}>
                    <Bar options={chartOptions} data={chartData} />
                    </div>
                </>} */}
                </div>
            </React.Fragment>
        </div>
    )
}

const AdminVabzeumGenerateScorePage = () => {
    return (
        <Access>
            <AdminVabzeumGenerateScore />
        </Access>
    )
}

export default AdminVabzeumGenerateScorePage
