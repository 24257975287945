/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { PageTitle, PopMessageParams, CustomP, CustomCheckBox, ListEntity, SelectIntoList } from "../../components/utils.js";
import { ThemeContext, SessionContext } from "../../components/context.js";
import { CustomLoadingButton } from "../../components/button.js";
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { CustomTextInput } from "../../components/textInput.js";
import { CreateUserSchema } from "../../components/formSchema.js";
import { CustomIconButton } from "../../components/iconButton.js";
import { IconHub } from "../../components/icon.js";
import { AvatarHandler } from "../../components/avatarHandler.js";



const AdminCreateUserForm = () => {
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)

    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()

    const [roleList, setRoleList] = useState([])
    const [selectedRoleList, setSelectedRoleList] = useState([])
    const [characterList, setCharacterList] = useState([])
    const [enabled, setEnabled] = useState(true)
    const [avatarFile, setAvatarFile] = useState(false)
    const avatar = useRef(null)


    
    useEffect(() => {
        if (roleList.length === 0 && selectedRoleList.length === 0) {
            axios.post("/api/roles", {
                user_id: session.user_id,
                session_token: session.session_token
            })
            .then(response => {
                const list = response.data
                const selectedList = []
                for (const role in list) {
                    if (list[role][1] === 'everyone') {
                        selectedList.push([list[role][0], list[role][1]])
                        list.splice(role, 1)
                    }
                }
                setSelectedRoleList(selectedList)
                setRoleList(list)
            })
        }
        // const current_img = getUserAvatar(getLocalObject('session', 'user_id'))
        // setAvatarFile(current_img ? current_img.default : false)
    }, [])
    
    const setAvatarRef = (receivedAvatar) => {
        avatar.current = receivedAvatar
    }
    
    const handleSelectedRoleList = (elem) => {
        const new_selected_list = [...selectedRoleList]
        const new_list = [...roleList]
        for (const listElem in roleList) {
            if (roleList[listElem][1] === elem[1]) {
                new_selected_list.push([roleList[listElem][0], roleList[listElem][1]])
                new_list.splice(listElem, 1)
                break
            }
        }
        setRoleList(new_list)
        setSelectedRoleList(new_selected_list)
    }

    const handleRoleList = (elem) => {
        const new_selected_list = [...selectedRoleList]
        const new_list = [...roleList]
        for (const listElem in selectedRoleList) {
            if (selectedRoleList[listElem][1] === elem[1]) {
                new_list.push([selectedRoleList[listElem][0], selectedRoleList[listElem][1]])
                new_selected_list.splice(listElem, 1)
                break
            }
        }
        setRoleList(new_list)
        setSelectedRoleList(new_selected_list)
    }

    const addCharacterList = () => {
        const value = document.getElementById("character-adder").value
        if (value.length > 0) {
            var inscript = true
            for (const name in characterList) {
                if (characterList[name].toLowerCase() === value.toLowerCase()) {
                    const new_list = [...characterList]
                    new_list[name] = value
                    setTimeout(() => {
                        setCharacterList(new_list)
                        document.getElementById("character-adder").value = ''
                    }, 20);
                    inscript = false
                    break
                }
            }
            if (inscript) {
                const new_list = [...characterList]
                new_list.push(value)
                setCharacterList([])
                setTimeout(() => {
                    setCharacterList(new_list)
                    document.getElementById("character-adder").value = ''
                }, 20);
            }
        }
    }

    const deleteCharacterList = (elem) => {
        if (characterList.indexOf(elem) !== -1) {
            const new_list = [...characterList]
            new_list.splice(new_list.indexOf(elem), 1)
            setCharacterList([])
            setTimeout(() => {
                setCharacterList(new_list)
            }, 20);

        }
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            if (event.preventDefault)
                event.preventDefault()
            addCharacterList()
        }
    }

    return (
        <div className='flex flex-wrap justify-center my-8 mx-auto
        w-11/12
        sm: flex'
        >
            <React.Fragment>
                <div style={{
                    backgroundColor: theme.secondary,
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
                }}
                    className='rounded-lg  
                    w-full flex-row items-center justify-center max-w-screen-sm'>
                    <div className='mt-16'>
                        <PageTitle reverse>Créer un utilisateur</PageTitle>
                    </div>
                    <div className='w-11/12 m-auto flex-row'>
                        <Formik
                            initialValues={{ pseudo: "", discord_id: "", password: "", character_name: "" }}
                            validationSchema={CreateUserSchema}
                            onSubmit={(values, { resetForm }) => {
                                setLoading(true)
                                var avatarUrl = null
                                if (avatar.current) {
                                    avatarUrl = avatar.current.getImageScaledToCanvas().toDataURL()
                                }
                                const new_values = { ...values }
                                delete new_values.character_name
                                values = { 
                                    ...new_values,
                                    characters: [...characterList],
                                    roles: [...selectedRoleList],
                                    enabled: enabled,
                                    avatar: avatarUrl,
                                    user_id: session.user_id,
                                    session_token: session.session_token
                                }
                                axios.post('/api/create_user', { ...values })
                                    .then(response => {
                                        enqueueSnackbar(response.data, PopMessageParams('success'))
                                        resetForm({})
                                        setEnabled(true)
                                        setCharacterList([])
                                        setRoleList([...roleList, ...selectedRoleList])
                                        setSelectedRoleList([])
                                        setAvatarFile(false)
                                        setAvatarRef(false)
                                    })
                                    .catch(error => {
                                        if (error.response.status === 403) {
                                            enqueueSnackbar(error.response.data['error'], PopMessageParams('error'))
                                        } else if (error.response.status === 500) {
                                            enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
                                        }
                                    })
                                    .finally(any => {
                                        setLoading(false)
                                    })
                            }}
                        >
                            {({
                                setFieldValue
                            }) => {
                                return (

                                    <Form>

                                        <div className='w-full justify-center'>
                                            <CustomP
                                                style={{
                                                    color: theme.primary,
                                                    backgroundColor: theme.primary
                                                }}
                                                className='ml-2 flex items-center'>
                                                <IconHub name='person-circle' className='mr-4' />
                                                <AvatarHandler
                                                    preloadImg={avatarFile}
                                                    setImage={setAvatarFile}
                                                    setRef={setAvatarRef} />
                                            </CustomP>
                                            <CustomTextInput
                                                autoComplete='off'
                                                name="pseudo"
                                                type="pseudo"
                                                placeholder="Pseudo"
                                                startIcon={<IconHub name='person' />}
                                            />
                                            <CustomTextInput
                                                name="password"
                                                type="password"
                                                placeholder="Mot de passe"
                                                startIcon={<IconHub name='key' />}
                                                iconType='component'
                                            />
                                            <CustomTextInput
                                                autoComplete='off'
                                                name="discord_id"
                                                type="discord_id"
                                                placeholder="ID Discord"
                                                startIcon={<IconHub name='discord' className='w-6' />}
                                            />
                                        </div>
                                        <div className='flex flex-wrap justify-center'>
                                            <div className='sm:w-6/12'>
                                                <div className='flex items-center w-full'>
                                                    <CustomTextInput
                                                        autoComplete='off'
                                                        id='character-adder'
                                                        onKeyDown={(event) => {
                                                            handleKeyDown(event);
                                                            if (event.keyCode === 13) { setFieldValue('character_name', '') }
                                                        }}
                                                        name="character_name"
                                                        type="character_name"
                                                        placeholder="Persos"
                                                        deletable={true}
                                                        removeinform={() => { setFieldValue('character_name', '') }}
                                                        startIcon={<IconHub name='manette' />}
                                                    />
                                                    <CustomIconButton
                                                        className='mt-7'
                                                        reverse={false}
                                                        onClick={() => { addCharacterList(); setFieldValue('character_name', '') }}>
                                                        <IconHub name='add-circle-filled' />
                                                    </CustomIconButton>
                                                </div>

                                                {characterList && <>
                                                    {characterList.map((elem, key) =>
                                                        <div key={key} className='w-10/12 flex justify-center'>
                                                            <ListEntity
                                                                entity={elem}
                                                                handleList={addCharacterList}
                                                                deletable={true}
                                                                deleteList={deleteCharacterList}
                                                                refreshList={characterList}
                                                            />
                                                        </div>
                                                    )}
                                                </>}
                                            </div>
                                            <div className='mt-5 sm:w-6/12'>
                                                <div
                                                    className='m-3 items-center justify-center cursor-pointer flex'
                                                    onClick={() => { setEnabled(enabled !== true) }}>
                                                    <CustomP >Compte activé: </CustomP>
                                                    <CustomCheckBox className="ml-2 mt-2" selected={enabled} />
                                                </div>
                                                {roleList && <>
                                                    <SelectIntoList
                                                        className=''
                                                        list={roleList}
                                                        handleList={handleRoleList}
                                                        selectedList={selectedRoleList}
                                                        handleSelectedList={handleSelectedRoleList}
                                                        leftTitle={'Rôles disponibles'}
                                                        rightTitle={'Rôles selectionnés'}
                                                    />
                                                </>}
                                            </div>
                                        </div>
                                        <div className='mt-8 mb-4 flex justify-center'>
                                            <CustomLoadingButton
                                                loading={loading}
                                                loadingColor={theme.primary}
                                                outlined={true}
                                                endIcon={<IconHub name='person-add' />}
                                                type='submit'
                                            >
                                                Créer
                                            </CustomLoadingButton>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}



const AdminCreateUser = () => {
    return (
        <div className='flex-row justify-center'>
            <AdminCreateUserForm />
        </div>
    );
}

const AdminCreateUserPage = () => {
    return (
        <Access>
            <AdminCreateUser />
        </Access>
    )
}


export default AdminCreateUserPage