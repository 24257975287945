/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react"
import { getLocalObject, setLocal } from "./utils"

// THEMES
function lightThemeModel() {
    return ({
    type: 'light',
    name: 'default-light',
    background: '#0b1c2e',
    background_darker: '#0A1929',
    primary: '#C6B299',
    primary_lighter: '#DEC7AB',
    primary_darker: '#917D6B',
    secondary: '#294B4C',
    secondary_lighter: '#325D5E',
    secondary_darker: '#203c3d',
    background_image: 'light_background.jpg',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    boxInnerShadow: 'inset 0px 0px 10px 2px rgba(0,0,0,0.4)'
})}

function darkThemeModel() {
    return ({
    type: 'dark',
    name: 'default-dark',
    background: '#0b1c2e',
    background_darker: '#0A1929',
    primary: '#294B4C',
    primary_lighter: '#325D5E',
    primary_darker: '#203c3d',
    secondary: '#C6B299',
    secondary_lighter: '#DEC7AB',
    secondary_darker: '#917D6B',
    background_image: "dark_background.jpg",
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    boxInnerShadow: 'inset 0px 0px 10px 2px rgba(0,0,0,0.4)'
})}

export function errorThemeModel() {
    return ({
    type: 'error',
    name: 'error',
    background: '#BF3B1E',
    background_darker: '#AB351B',
    primary: '#DF5335',
    primary_lighter: '#F15D3D',
    primary_darker: '#CD4D31',
    secondary: '#5A2519',
    secondary_lighter: '#9D402C',
    secondary_darker: '#9D402C',
    background_image: "",
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
    boxInnerShadow: 'inset 0px 0px 10px 2px rgba(0,0,0,0.4)'
})}

export const ThemeContext = React.createContext()

const knownThemes = [lightThemeModel(), darkThemeModel()]

export const ThemeProvider = props => {
    var default_theme = undefined
    try {
        default_theme = getLocalObject('theme')
    }
    catch {console.log('Theme invalide')}
    if (!default_theme) {
        default_theme = darkThemeModel()
    }
    const [theme, setTheme] = useState(default_theme)
    const theme_value = { theme, setTheme }
    
    // console.log("theme default -> ", default_theme)
    useEffect(() => {
        setLocal('theme', JSON.stringify(default_theme))
    }, [])

    return (
        <ThemeContext.Provider value={theme_value}>{props.children}</ThemeContext.Provider>
    )
}

// export function switchTheme(callback, current_theme) {
//     for (const theme in knownThemes) {
//         if (knownThemes[theme]['name'] === current_theme.name) {
//             console.log("Application du theme ", current_theme)
//             callback(knownThemes[theme])

//         }
//     }
// }

function getThemeIndex(to_search) {
    var i = 0
    for (const curr_theme in knownThemes) {
        if (knownThemes[curr_theme].name === to_search.name) {
            return i
        }
        i += 1
    }
    return undefined
}

function getNextThemeIndex(theme) {
    const current_theme_index = getThemeIndex(theme)
    if (current_theme_index < knownThemes.length - 1)
        return current_theme_index + 1
    return 0
}

export function autoSwitchTheme(callback, current_theme) {
    const new_theme = getNextThemeIndex(current_theme)
    callback(knownThemes[new_theme])
    // console.log("nouveau theme en place -> ", knownThemes[new_theme])
    setLocal('theme', JSON.stringify(knownThemes[new_theme]))
}

// export function modifyTheme(callback, current_theme, target_theme) {
//     const new_theme = { ...current_theme }
//     for (const elem in current_theme) {
//         for (const key in target_theme) {
//             if (key === elem) {
//                 new_theme[key] = target_theme[key]
//             }
//         }
//     }
//     callback(new_theme)
// }

// SESSION
const sessionModel = {
    pseudo: null,
    id: null,
    session_token: null,
    roles: ['everyone'],
    avatar: undefined
}

export const SessionContext = React.createContext()

export const SessionProvider = props => {
    var default_session = undefined
    try {
        default_session = getLocalObject('session')
    }
    catch {console.log('session invalide')}
    if (!default_session) {
        default_session = sessionModel
    }
    const [session, setSession] = useState(default_session)
    const session_value = { session, setSession }
    
    // console.log("session default -> ", default_session)
    useEffect(() => {
        setLocal('session', JSON.stringify(default_session))
    }, [])

    return (
        <SessionContext.Provider value={session_value}>{props.children}</SessionContext.Provider>
    )
}

export function clearSession(callback) {
    callback(sessionModel)
}

// TAILLE ECRAN 

export const ScreenSizeContext = React.createContext()

export const ScreenSizeProvider = props => {
    const [screenSize, setScreenSize] = useState('')
    const screenSize_value = { screenSize, setScreenSize }
    
    // console.log("screenSize default -> ", default_screenSize)
    useEffect(() => {
        changeScreenSize(setScreenSize)
    }, [])

    return (
        <ScreenSizeContext.Provider value={screenSize_value}>{props.children}</ScreenSizeContext.Provider>
    )
}

export function changeScreenSize(callback) {
    const currScreenSize = window.screen.width
    if (currScreenSize <= 360) {
        callback('2xs')
    } else if (currScreenSize <= 380) {
        callback('xs')
    } else if (currScreenSize <= 640) {
        callback('sm')
    } else if (currScreenSize <= 768) {
        callback('md')
    } else if (currScreenSize <= 1024) {
        callback('lg')
    } else if (currScreenSize <= 1280) {
        callback('xl')
    } else if (currScreenSize <= 1536) {
        callback('2xl')
    }
}

export function screenSizeContains(reference, size) {
    const datas = {
        '2xs': ['2xs'],
        'xs': ['2xs', 'xs'],
        'sm': ['2xs', 'xs', 'sm'],
        'md': ['2xs', 'xs', 'sm', 'md'],
        'lg': ['2xs', 'xs', 'sm', 'md', 'lg'],
        'xl': ['2xs', 'xs', 'sm', 'md', 'lg', 'xl'],
        '2xl': ['2xs', 'xs', 'sm', 'md', 'lg', 'xl', '2xl']
    }
    console.log(reference, size)
    if (datas[reference].indexOf(size) > -1) {
        return true
    } else {
        return false
    }
}

// REFRESH APP

export const RefreshAppContext = React.createContext()

export const RefreshAppProvider = props => {
    const [refreshApp, setRefreshApp] = useState(0)
    const refreshAppValue = { refreshApp, setRefreshApp }

    return (
        <RefreshAppContext.Provider value={refreshAppValue}>{props.children}</RefreshAppContext.Provider>
    )
}

export function clearRefreshApp(callback) {
    callback(0)
}

// MODAL CONTEXT

export const ModalContext = React.createContext()

export const ModalProvider = props => {

    const [modal, setModal] = useState(undefined)
    const modalValue = { modal, setModal}


    return (
        <ModalContext.Provider value={modalValue}>{props.children}</ModalContext.Provider>
    )
}