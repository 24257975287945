/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { CircularProgress } from "@mui/material";
import { errorThemeModel, ThemeContext } from "./context";


const CustomThemed = styled(Button)((props) => ({
    color: props.theme.secondary,
    backgroundColor: props.theme.primary,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.primary_lighter,
        border: `2px solid ${props.theme.primary}`,
    },
    border: `2px solid ${props.theme.primary}`,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
}));

const CustomThemedReverse = styled(Button)((props) => ({
    color: props.theme.primary,
    backgroundColor: props.theme.secondary,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.secondary_lighter,
        border: `2px solid ${props.theme.secondary}`,
    },
    border: `2px solid ${props.theme.secondary}`,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
}));

const CustomThemedOutlined = styled(Button)((props) => ({
    color: props.theme.primary,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.secondary_lighter,
        border: `2px solid ${props.theme.primary}`,
    },
    border: `2px solid ${props.theme.primary}`,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
}));

const CustomThemedReverseOutlined = styled(Button)((props) => ({
    color: props.theme.secondary_darker,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.primary_lighter,
        border: `2px solid ${props.theme.secondary}`,
    },
    border: `2px solid ${props.theme.secondary}`,
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
}));

export const CustomLoadingButton = ({forceRefresh, ...props}) => {
    const [loadingAttr, setLoadingAttr] = useState(false)
    const [buttonAttr, setButtonAttr] = useState(false)
    const { theme } = useContext(ThemeContext)

    const forceRefreshButton = forceRefresh || []
    useEffect(() => {
        var themeVariant = null
        if (props.variant){
            themeVariant = errorThemeModel()
        }
        setLoadingAttr({
            style: {
                color: props.loadingColor || undefined,
            },
            variant: props.loadingVariant || 'indeterminate',
        })
        setButtonAttr({
            theme: themeVariant || theme || undefined,
            size: props.size || 'medium',
            startIcon: props.startIcon || undefined,
            endIcon: props.endIcon || undefined,
            type: props.type || 'button',
            onClick: props.onClick || undefined,
            disabled: props.disabled,
            fullWidth: props.fullWidth || false
        })
    }, [theme, ...forceRefreshButton])

    return (
        <div className='m-1 transition-all duration-500 flex justify-center'>
            {(buttonAttr || loadingAttr) && <>
                {!props.loading && <>
                    {props.outlined && <>
                        {props.reverse && <>
                            <CustomThemedReverseOutlined {...buttonAttr}>{props.children}</CustomThemedReverseOutlined>
                        </>} {!props.reverse && <>
                            <CustomThemedOutlined {...buttonAttr}>{props.children}</CustomThemedOutlined>
                        </>}
                    </>}
                    {!props.outlined && <>
                        {props.reverse && <>
                            <CustomThemedReverse {...buttonAttr}>{props.children}</CustomThemedReverse>
                        </>} {!props.reverse && <>
                            <CustomThemed  {...buttonAttr} fullWidth>{props.children}</CustomThemed>
                        </>}
                    </>}
                </>}
                {props.loading && <>
                    <CircularProgress {...loadingAttr} />
                </>}
            </>}
        </div>
    )
}