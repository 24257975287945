/* eslint-disable react-hooks/exhaustive-deps */

import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { CustomGif, PopMessageParams, useInterval } from "./utils"
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box';
import { SessionContext, ThemeContext } from "./context";
import { useSnackbar } from "notistack";
import { useHistory } from 'react-router';

const Access = (props) => {
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [autorizeContent, setAutorizeContent] = useState(false)
    const [duration, setDuration] = useState(0)
    const [fatalError, setFatalError] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const routerHistory = useHistory();

    useEffect(() => {
        axios.post('/api/get_access', {
            route: window.location.pathname,
            user_id: session.user_id,
            session_token: session.session_token
        }).then(() => {
            setAutorizeContent(true)
        })
        .catch(error => {
            if (error.response && error.response.status) {
                var rendedResponse = { msg: undefined, params: undefined, redirect: undefined, timing: 0 }
                if (error.response.status === 405) {
                    rendedResponse.msg = "La page est n'existe pas ou est désactivée"
                    rendedResponse.params = PopMessageParams('info')
                    rendedResponse.redirect = '/'
                } else if (error.response.status === 403) {
                    rendedResponse.msg = "Tu n'as pas le rôle nécessaire"
                    rendedResponse.params = PopMessageParams('error')
                    rendedResponse.redirect = '/'
                } else if (error.response.status === 401) {
                    rendedResponse.msg = "Tu dois être connecté"
                    rendedResponse.params = PopMessageParams('info')
                    rendedResponse.redirect = '/login'
                } else if (error.response.status === 500) {
                    rendedResponse.msg = "ERREUR SERVEUR"
                    rendedResponse.params = PopMessageParams('error')
                    setFatalError(true)
                } else if (error.response.status === 451) {
                    rendedResponse.msg = "Te reconnecter... tu dois"
                    rendedResponse.params = PopMessageParams('info')
                    rendedResponse.redirect = '/logout'
                }
                enqueueSnackbar(rendedResponse.msg, rendedResponse.params)
                if (rendedResponse.redirect) {
                    setTimeout(() => {
                        routerHistory.push(rendedResponse.redirect)
                    }, rendedResponse.timing);
                }
            }
        })
    }, [])

    useInterval(() => {
        setDuration(duration + 1)
    }, 1000)

    return (
        <div className='w-full'>
            {autorizeContent && <>
                {props.children}
            </>}
            {!autorizeContent && <>
                <div className={'absolute left-1/2 top-1/4 transition-all'}>
                    <div className='transition-all duration-500
                        relative -left-1/2 min-w-150
                        sm:min-w-300 w-min mt-4 top-0
                        '
                    >
                        <Stack style={{ color: theme.secondary }} className='transition-all items-center justify-center duration-500
                        w-full h-full p-4 text-center flex-row'
                        >
                            <div style={{ color: theme.secondary }} className='duration-500 transition-all transform text-4xl font-bold mt-6 mb-6'>
                                {fatalError && <>C KC chef
                                    <CustomGif
                                        type='fatalError'
                                        alt='erreur serveur'
                                        title='TATOU KC'
                                        className='mt-10 rounded'
                                    />
                                </>}
                                {!fatalError && <>
                                    Chargement...
                                    {duration >= 5 && duration <= 9 && <> très long?</>}
                                    {duration >= 10 && <> bcp très long</>}
                                    {duration > 0 && <>
                                        <CustomGif
                                            type='loading'
                                            alt='ça charge'
                                            title="Il vento d'oro"
                                            addStyle={{ transform: 'scaleX(-1)' }}
                                            className='mt-10 rounded'
                                        />
                                    </>}
                                </>}
                            </div>
                            {!fatalError && <>
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress color='inherit' />
                                </Box>
                            </>}
                        </Stack>
                    </div>
                </div>
            </>}
        </div >
    )
}

export default Access