/* eslint-disable react-hooks/exhaustive-deps */
/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/

import { useSnackbar } from "notistack"
import React, { useContext, useEffect, useRef, useState } from "react"
import { useHistory } from "react-router"
import { ModalContext, SessionContext, ThemeContext } from "./context"
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { CustomIconButton } from "./iconButton";
import CheckBox from "react-animated-checkbox"
import { IconHub } from "./icon";
import { CustomLoadingButton } from "./button";

export const rankedColors = {
    'platine': '#00C0F9',
    'gold': '#FFD700',
    'silver': '#C0C0C0',
    'bronze': '#614e1a',
}

export const getLocalObject = (object_name, item_to_get = null) => {
    // object names: user, theme
    const object = JSON.parse(localStorage.getItem(object_name))
    if (!object || !item_to_get) {
        return object
    }
    return object[item_to_get]
}

export const getLocal = (item_to_get) => {
    return localStorage.getItem(item_to_get)
}

export const setLocal = (index, item_to_set) => {
    localStorage.setItem(index, item_to_set)
}

export const removeLocal = (item_to_remove) => {
    localStorage.removeItem(item_to_remove)
}

export function PopMessageParams(props_variant, paramObj = {}) {
    const variant = props_variant || 'info'
    const vertical = paramObj.vertical || 'right'
    const horizontal = paramObj.horizontal || 'bottom'
    const preventDuplicate = paramObj.preventDuplicate || true
    const autoHideDuration = paramObj.autoHideDuration || 3000

    return ({
        variant: variant,
        anchorOrigin: {
            vertical: vertical,
            horizontal: horizontal,
        },
        preventDuplicate: preventDuplicate,
        autoHideDuration: autoHideDuration
    })
}

export const CustomP = ({ className, onClick, children, reverse, forceColor, ...props }) => {
    const { theme } = useContext(ThemeContext)
    const [style, setStyle] = useState({})

    useEffect(() => {
        const tmp_style = Object.assign({
            color: forceColor ? forceColor : reverse ? theme.secondary : theme.primary,
            textShadow: '1px 1px 0px rgba(0,0,0,0.2)',
            fontWeight: props.weight || undefined
        }, props.additionnal_style ? props.additionnal_style : {})
        setStyle(tmp_style)
    }, [theme])

    return (
        <div {...props} style={style} className={`transition-all ${className}`} onClick={onClick}>{children}</div>
    )
}

export const CustomCheckBox = (props) => {
    const { theme } = useContext(ThemeContext)

    return (
        <div className={`${props.className} flex items-center`}
            onClick={props.onClick}>
            {props.label && <>
                <CustomP className='mr-2 cursor-pointer' reverse={props.reverse}>{props.label}</CustomP>
            </>}
            <CheckBox
                checked={props.selected}
                checkBoxStyle={{
                    checkedColor: props.reverse ? theme.secondary_lighter : theme.primary_lighter,
                    size: props.size || 20,
                    unCheckedColor: props.reverse ? theme.secondary_darker : theme.primary_darker
                }}
                duration={props.duration || 200}

            />
        </div>
    )
}

export const Section = (props) => {
    const { theme } = useContext(ThemeContext)
    const [refresh, setRefresh] = useState(false)
    const [refreshRotation, setRefreshRotation] = useState(0)
    const noRefresh = props.noRefresh || undefined
    const sectionIcon = props.sectionIcon || undefined
    const sectionStyle = {
        backgroundColor: props.sectionReverse ? theme.primary : theme.secondary,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
        width: props.width ? `${props.width}px` : '91%',
        maxWidth: props.max_width ? `${props.max_width}px` : '800px'
    }
    const titleStyle = {
        color: props.sectionReverse ? theme.secondary : theme.primary,
        textShadow: '2px 1px 0px rgba(0,0,0,0.2)'
    }

    const refreshStyle = {
        transform: `rotate(${refreshRotation}deg)`
    }

    const handleRefresh = () => {
        setRefresh(refresh !== true)
        setRefreshRotation(360)
        setTimeout(() => {
            setRefreshRotation(0)
        }, 500);
        if (props.callBack) {
            props.callBack(refresh !== true)
        }
    }
    // const max_width = props.max_width || 800
    // const width = props.width || '11/12'
    return (
        <div style={sectionStyle}
            className={`rounded-md relative
                mx-auto p-4 ${props.className}`}>
            <div style={titleStyle} className='text-center font-bold
                py-2'>{props.title}</div>
            {React.cloneElement(props.children)}
            {!noRefresh && <>
                <div style={refreshStyle} className='absolute -top-4 -right-4 transition-all duration-500'>
                    <CustomIconButton reverse={false} onClick={handleRefresh}><AutorenewOutlinedIcon /></CustomIconButton>
                </div>
            </>}
            {sectionIcon && <>
                <div className='absolute -top-4 -left-0 transition-all duration-500'>
                    {sectionIcon}
                </div>
            </>}
        </div>
    )
}

export const PageTitle = (props) => {
    const { theme } = useContext(ThemeContext)

    const style = {
        color: props.reverse ? theme.primary : theme.secondary,
        textShadow: '3px 2px 0px rgba(0,0,0,0.2)'
    }
    return (
        <div style={style}
            className='w-full text-4xl text-center font-bold mt-6 mb-12 mx-auto transition-all duration-500'
        >
            {props.children}
        </div>
    )
}

export const FlashMessage = (props) => {
    const [animate, setAnimate] = useState(300)
    const [launchVanish, setLaunchVanish] = useState(100)

    const vanish = props.timeout ? props.timeout * 0.80 : 4000
    useEffect(() => {
        setTimeout(() => {
            setAnimate(0)
        }, 50);
    }, [])

    useInterval(() => {
        props.manageFlashMessage()
    }, props.timeout || 5000)

    useInterval(() => {
        setLaunchVanish(0)
    }, vanish)

    const style = {
        width: animate + 'px',
        backgroundColor: "bg-vab-blue_darker"
    }

    return (
        <div className={'absolute left-1/2 top-0 transition-all duration-1000 opacity-' + launchVanish}>
            <div className='bg-vab-white text-vab-blue_darker font-bold rounded-sm border rounded border-white transition-all cursor-pointer
            relative -left-1/2
            min-w-300 w-min mt-4 top-0 min-h-60 h-max
            '
                onClick={() => { props.manageFlashMessage() }}>
                <div className='flex justify-center items-center transition-all
                w-full h-full p-4 text-center'>
                    {props.content}
                </div>
                <div style={style}
                    className={`bg-${(props.color || 'red-500')} rounded-b rounded-tr transition-all ease-linear duration-${(props.timeout || '5000')} \
                    h-1 absolute bottom-0`}></div>
            </div>
        </div>
    )
}


export function useInterval(callback, delay) {
    const savedCallback = useRef();

    useEffect(() => {
        savedCallback.current = callback;
    });

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
    }, [delay]);
}

export const VabImage = (props) => {
    const pic = require('../statics/images/icons/icon_blason.png')

    return (
        <img {...props} src={pic.default} alt='blason Vab' />
    )
}

export const CustomNavLink = (props) => {
    const { session } = useContext(SessionContext)
    const routerHistory = useHistory();
    const { enqueueSnackbar } = useSnackbar()

    const handleClick = () => {
        if (!props.enabled) {
            enqueueSnackbar("La page est désactivée ou n'existe pas", PopMessageParams('info', { autoHideDuration: 1000 }))
        } else {
            // console.log(`${props.link} --> ${String(!(props.noLinkOnMobile && isOnMobile() && session.user_id))}`)
            if (!(props.noLinkOnMobile && isOnMobile() && session.user_id)) {
                routerHistory.push(props.enabled ? props.link : "/")
            }
        }
    }

    return (
        <div onClick={handleClick} className={props.className}>
            {props.children}
        </div>
    )
}


export const CustomGif = (props) => {
    var img
    if (props.type === '404') {
        img = 'https://media.giphy.com/media/KKOMG9EB7VqBq/giphy.gif'
    } else if (props.type === 'loading') {
        img = 'https://i.giphy.com/media/JRlqKEzTDKci5JPcaL/giphy.webp'
    } else if (props.type === 'fatalError') {
        img = 'https://c.tenor.com/WCE5JUszKGMAAAAC/tenor.gif'
    } else if (props.type === 'logout') {
        img = 'https://c.tenor.com/XrBtuKPlXKwAAAAC/ahaha-wave.gif'
    }

    const style = {
        boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
    }
    const classes = 'max-w-10/12 max-h-5/10' + props.className
    return (
        <img
            style={{ ...style, ...props.addStyle }}
            className={classes}
            alt={props.alt}
            title={props.title}
            src={img} />
    )
}

export function isOnMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
    } else {
        return false
    }
}

export function themeStyle(props, theme, forceReverse, forceOutlined) {
    const attr = { ...props }
    if (forceReverse !== undefined) {
        attr.reverse = forceReverse
    }
    if (forceOutlined !== undefined) {
        attr.outlined = forceOutlined
    }
    return {
        opacity: props.disabled ? 0.5 : 1,
        color: attr.outlined ? (attr.reverse ? theme.secondary_darker : theme.primary) : (attr.reverse ? theme.secondary : theme.primary),
        backgroundColor: attr.outlined ? '' : (attr.reverse ? theme.primary : theme.secondary),
        fontWeight: 'bold',
        '&:hover': {
            backgroundColor: attr.outlined ? (attr.reverse ? theme.primary_lighter : theme.secondary_lighter) : (attr.reverse ? theme.primary_lighter : theme.secondary_lighter),
            border: `2px solid ${attr.outlined ? (attr.reverse ? theme.secondary : theme.primary) : (attr.reverse ? theme.primary : theme.secondary)}`,
        },
        border: `2px solid ${attr.outlined ? (attr.reverse ? theme.secondary : theme.primary) : (attr.reverse ? theme.primary : theme.secondary)}`,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }
}



export const ListEntity = (props) => {
    const { theme } = useContext(ThemeContext)
    const [hover, setHover] = useState(false)
    const [selected, setSelected] = useState(false)

    useEffect(() => {
        setSelected(props.selected)
    }, [])


    const style = {
        backgroundColor: hover ? theme.primary_lighter : theme.primary,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    const to_display = typeof (props.entity) === 'string' ? props.entity : props.entity[props.entity.length - 1]
    return (
        <div
            style={style}
            className={`cursor-pointer transition-all rounded-md flex items-center justify-between relative
                px-2 py-1 m-1 w-11/12 ${props.className}`}
            onMouseEnter={() => { setHover(true) }}
            onMouseOut={() => { setHover(false) }}
            onClick={() => { props.handleList(props.entity) }}>
            {props.showCheck && <>
                <CustomCheckBox className="mt-2" selected={selected} reverse />

            </>}
            <CustomP reverse className='m-1 mx-auto'>{to_display}</CustomP>
            {props.deletable && <>
                <CustomIconButton
                    className='absolute right-0'
                    reverse={false}
                    invisible={true}
                    onClick={() => { props.deleteList(props.entity) }}
                >
                    <IconHub name='clear' />
                </CustomIconButton>
            </>}
        </div>
    )
}

export const SelectIntoList = (props) => {
    const { theme } = useContext(ThemeContext)



    const areaStyle = {
        border: `2px solid ${theme.primary}`,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    const listStyle = {
        // border: `2px solid ${theme.primary}`,
        // boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }
    return (
        <div
            style={areaStyle}
            className={`${props.className} text-center flex justify-center mx-auto rounded-md transition-all
                p-2 h-max`}>
            <div
                className='mx-2 rounded-md flex flex-col items-center'
                style={listStyle}
                id='full-list'
            >
                <CustomP>{props.leftTitle}</CustomP>
                {props.list.map((elem, key) => <ListEntity key={key} entity={elem} handleList={props.handleSelectedList} />)}
            </div>
            <div
                className='mx-2 rounded-md flex flex-col items-center'
                style={listStyle}
                id='save-list'>

                <CustomP>{props.rightTitle}</CustomP>
                {props.selectedList && <>
                    {props.selectedList.map((elem, key) => <ListEntity key={key} entity={elem} handleList={props.handleList} showCheck selected={true} />)}
                </>}
            </div>
        </div>
    )
}

export const CustomModal = (props) => {
    const { theme } = useContext(ThemeContext)
    const { _modal, setModal } = useContext(ModalContext)

    const [scrollTop, setScrollTop] = useState(window.scrollY)

    const onScroll = e => {
        setScrollTop(e.target.documentElement.scrollTop);
    };

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        window.addEventListener('keydown', handleKeyDown)
        return () => {
            window.removeEventListener("scroll", onScroll)
            window.removeEventListener('keydown', handleKeyDown)
        };
    }, []);

    const backgroundStyle = {
        backgroundColor: `rgba(60, 60, 60, 0.5)`,
        top: `${scrollTop}px`,
    }
    const modalStyle = {
        backgroundColor: `rgba(60, 60, 60, 0.8)`,
        transform: 'translateX(-50%) translateY(-50%)',
        top: `50%`,
        left: '50%',
        height: 'auto',
        maxHeight: '100% !important',
        border: `2px solid ${theme.secondary}`,
        color: theme.secondary_lighter,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    const handleKeyDown = (key) => {
        if (key.keyCode === 27) {
            setModal(undefined)
        }
    }

    const handleModalVanish = () => {
        if (!props.disableAutoVanish) {
            setModal(undefined)
        }
    }



    useEffect(() => {
        setModal(
            <div id='modal'
                className='absolute transition-all z-9999 flex justify-center items-center cursor-pointer
                w-full h-full'
                style={backgroundStyle}
                onClick={handleModalVanish}
            >
                <div
                    onClick={handleModalVanish}
                    style={modalStyle}
                    className='absolute transition-all flex justify-center absolute items-center cursor-pointer
        w-full h-full z-9998 '>
                    {props.children}
                </div>
            </div>
        )
    }, [theme, scrollTop])
    return (
        <div></div>
    )
}

export const ModalImg = (props) => {
    const [isOpen, setIsOpen] = useState(false)
    const { modal, setModal } = useContext(ModalContext)

    useEffect(() => {
        if (!modal) {
            setIsOpen(false)
        }
    }, [modal])


    const handleClose = () => {
        setModal(undefined)
        setIsOpen(false)
    }

    return (
        <div className='w-full overflow-y-hidden flex justify-center items-center'>
            <img
                style={{
                    maxWidth: '100%',
                    maxHeight: '100%'
                }}
                src={props.src}
                alt={props.alt || 'undefined'}
                className={`${props.className} m-auto cursor-pointer`}
                onClick={() => { setIsOpen(true) }}
            />
            {isOpen && <>
                <CustomModal>
                    <img
                        style={{
                            maxWidth: '70%',
                            maxHeight: '60%'
                        }}
                        src={props.src}
                        alt={props.alt || 'undefined'}
                        className={`${props.className} cursor-pointer`}
                        onClick={handleClose}
                    />
                </CustomModal>
            </>}
        </div>
    )
}

export function getUserAvatarLink(avatarName) {
    if (!avatarName)
        return undefined
    return window.location.origin + '/variables/users/avatars/' + avatarName
}

export function getVabzeumScreenshotLink(screenshotName) {
    if (!screenshotName)
        return undefined
    return window.location.origin + '/variables/vabzeum/matchs/' + screenshotName
}

export function getVabrusherScreenshotLink(screenshotName) {
    if (!screenshotName)
        return undefined
    return window.location.origin + '/variables/vabrusher/matchs/' + screenshotName
}

export function getCarouselImageLink(image) {
    if (!image)
        return undefined
    return window.location.origin + '/variables/carousel/' + image
}

export function getVabzeumScoreLink(scoreName) {
    if (!scoreName)
        return undefined
    return window.location.origin + '/variables/vabzeum/charts/' + scoreName
}

export function getVabrusherDungeonBossImage(image) {
    if (!image)
        return require("../statics/images/vabrusher/dungeons/default.png").default
    return window.location.origin + '/variables/vabrusher/dungeons/bossImages/' + image
}

export function getVabrusherQuestImage(image) {
    if (!image)
        return require("../statics/images/vabrusher/quests/default.png").default
    return window.location.origin + '/variables/vabrusher/quest/images/' + image
}

export function getVabrusherChallengeImage(image, type) {
    if (type === 'dungeon')
        return getVabrusherDungeonBossImage(image)
    if (type === 'quest')
        return getVabrusherQuestImage(image)
}

export function getVabrusherScoreLink(scoreName) {
    if (!scoreName)
        return undefined
    return window.location.origin + '/variables/vabrusher/charts/' + scoreName
}

export function getUnknownImage() {
    return require("../statics/images/icons/icon_unknown.png").default
}

export function getUserAvatar(user_id = false) {
    var target_usr = user_id
    if (!user_id) {
        target_usr = getLocalObject('session', 'user_id')
    }
    var current_img = undefined
    const extension = ['png', 'jpg', 'jpeg']
    // console.log("recherche avatar sur id: ", target_usr)
    for (const type in extension) {
        try {
            // console.log(window.location.origin + "/users/" + target_usr + "_min." + extension[type])
            current_img = require(window.location.origin + "/users/" + target_usr + "_min." + extension[type])
            break
        } catch {
        }
    }
    // if (!current_img) {
    //     current_img = require("../statics/images/users/icon_profile_default.png")
    // }
    console.log("avatar found -> ", current_img)
    return current_img
}

export function getMatchImage(match_id, type) {
    var current_img = false
    // console.log("../statics/images/" + type + '/matchs/' + match_id + '.png')
    try {
        current_img = require("../statics/images/" + type + '/matchs/' + match_id + '.png')
    } catch {
        try {
            current_img = require("../statics/images/" + type + '/matchs/' + match_id + '.jpeg')
        } catch {
            try {
                current_img = require("../statics/images/" + type + '/matchs/' + match_id + '.jpg')
            } catch { }
        }
    }
    return current_img
}

export function isAlphaNumWritable(ch) {
    return typeof ch === "string"
        && ch.length === 1
        && ((ch >= "a" && ch <= "z")
            || (ch >= "A" && ch <= "Z")
            || (ch >= '0' && ch <= '9')
            || ch === '-'
            || ch === '_')
}

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});


export const Pagination = (props) => {
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)

    useEffect(() => {
        setPage(props.page)
        setMaxPage(props.maxPage)
    }, [props.page, props.maxPage])

    return (
        <div className="flex space-x-4 items-center">
            {page > 1 && <>
                <CustomLoadingButton
                    outlined
                    onClick={() => { props.changePage(props.page - 1) }}
                    forceRefresh={[props.page]}>
                    prec
                </CustomLoadingButton>
            </>}
            <CustomP className="text-xl font-bold">
                {page}/{maxPage}
            </CustomP>
            {page < maxPage && <>
                <CustomLoadingButton
                    outlined
                    onClick={() => { props.changePage(props.page + 1) }}
                    forceRefresh={[props.page]}>
                    suiv
                </CustomLoadingButton>
            </>}
        </div>
    )
}

export function getStyledNumbers(toHandle, returnType, prefix = undefined, width = 20) {
    const tmpList = []
    const retList = []
    var decimalNb = undefined
    toHandle = Math.round(toHandle * 100) / 100
    // console.log("---------------------------")
    // console.log("tohandle -> ", toHandle)
    while (parseFloat(toHandle) % 1 !== 0 && (decimalNb < 2 || decimalNb === undefined)) {
        decimalNb = decimalNb === undefined ? 1 : decimalNb + 1
        toHandle *= 10
    }
    // console.log("apres recherche de decimale => nbdec: ", decimalNb)
    while (toHandle > 0) {
        tmpList.push(parseInt(toHandle % 10))
        toHandle = parseInt(toHandle / 10)
    }
    var i = 1
    tmpList.forEach(entity => {
        if (returnType === 'react') {
            if (decimalNb === 0) {
                const image = require(`../statics/images/utils/dot.png`)
                retList.push(<img style={{ width: width * 0.5 }} key={`dot_${0}`} src={image.default} alt={`nb_group_dot`} />)
            }
            const image = require(`../statics/images/utils/${entity}.png`)
            retList.push(<img style={{ width: width }} key={i} src={image.default} alt={`nb_group${entity}`} />)
            decimalNb -= 1
        }
        i += 1
    })
    if (prefix) {
        const image = require(`../statics/images/utils/${prefix}.png`)
        retList.push(<img style={{ width: width * 1.3 }} key={0} src={image.default} alt={`nb_group${prefix}`} />)
    }
    // console.log("==============================")

    return retList.reverse()
}


export function getSelectList(size, starting = false, ending = false) {
    var ret = {}
    for (var i = starting || 0; i <= size; i++) {
        ret[i] = i
        if (ending !== false && i === parseInt(ending)) {
            break
        }
    }
    return ret
}

export function getScoreRankedColor(value, theme) {
    if (value === 1) {
        return theme.primary
    }
    else if (value <= 2) {
        return rankedColors.bronze
    } else if (value <= 4) {
        return rankedColors.silver
    } else if (value <= 6) {
        return rankedColors.gold
    } else {
        return rankedColors.platine
    }
}

export function getRankedColor(value, theme, reverse) {
    if (value === 1) {
        return rankedColors.gold
    }
    else if (value === 2) {
        return rankedColors.silver
    } else if (value === 3) {
        return rankedColors.bronze
    } else {
        return reverse ? theme.secondary : theme.primary
    }
}

export function getRankedTextures(value) {
    if (value === 1) {
        return require('../statics/images/utils/gold_texture.png')
    } else if (value === 2) {
        return require('../statics/images/utils/silver_texture.png')
    } else if (value === 3) {
        return require('../statics/images/utils/bronze_texture.png')
    }
    return false
}


export function errorPlayer(selectedAllies, alliesList) {
    var check = false
    selectedAllies.forEach(allyToCheck => {
        var count = 0
        selectedAllies.forEach(ally => {
            // console.log("check -> ", allyToCheck.id, allyToCheck.name, ' // ally -> ', ally.id, ally.name)
            if (allyToCheck.id === ally.id || allyToCheck.name === ally.name) {
                if (!count) {
                    count += 1
                } else {
                    check = true
                }
            }
        })
    })
    // console.log("premier -> ", check)
    if (!check) {
        var BreakException = {}
        try {
            selectedAllies.forEach(ally => {
                var allyExists = false
                alliesList.forEach(allyToCheck => {
                    // console.log("check -> ", allyToCheck.id, allyToCheck.name, ' // ally -> ', ally.id, ally.name, ally.show)
                    if (allyToCheck.id === ally.id && allyToCheck.name === ally.name && ally.show) {
                        allyExists = true
                    }
                })
                if (!allyExists) {
                    // console.log("ally not exists => ", ally)
                    throw BreakException
                }
            })
        }
        catch (e) {
            if (e !== BreakException) {
                throw e
            }
            check = true
            // console.log('deuxieme -> ', check)
        }
    }
    return check
}