import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ClearIcon from '@mui/icons-material/Clear';
import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import StarsIcon from '@mui/icons-material/Stars';
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GroupIcon from '@mui/icons-material/Group';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import Rotate90DegreesCcwIcon from '@mui/icons-material/Rotate90DegreesCcw';
import CropIcon from '@mui/icons-material/Crop';
import AddIcon from '@mui/icons-material/Add';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';

export const IconHub = ({ name, ...props }) => {
    if (name === 'lock')
        return (<LockIcon {...props} />)
    else if (name === 'lock-open')
        return (<LockOpenIcon {...props} />)
    else if (name === 'clear')
        return (<ClearIcon {...props} />)
    else if (name === 'login')
        return (<LoginIcon {...props} />)
    else if (name === 'person')
        return (<PersonIcon {...props} />)
    else if (name === 'key')
        return (<VpnKeyIcon {...props} />)
    else if (name === 'list-numbered')
        return (<FormatListNumberedIcon {...props} />)
    else if (name === 'person-add')
        return (<PersonAddIcon {...props} />)
    else if (name === 'playlist-add')
        return (<PlaylistAddCheckIcon {...props} />)
    else if (name === 'star')
        return (<StarsIcon {...props} />)
    else if (name === 'discord')
        return (<img alt='icon-discord' src={require("../statics/images/icons/icon_discord.png").default} {...props} />)
    else if (name === 'manette')
        return (<SportsEsportsIcon {...props} />)
    else if (name === 'add-circle-filled')
        return (<AddCircleOutlineIcon {...props} />)
    else if (name === 'edit')
        return (<EditIcon {...props} />)
    else if (name === 'checkbox')
        return (<CheckBoxIcon {...props} />)
    else if (name === 'checkbox-circle')
        return (<CheckCircleIcon {...props} />)
    else if (name === 'crossbox')
        return (<CancelIcon {...props} />)
    else if (name === 'down-arrow')
        return (<KeyboardArrowDownIcon {...props} />)
    else if (name === 'search')
        return (<SearchIcon {...props} />)
    else if (name === 'time')
        return (<AccessTimeIcon {...props} />)
    else if (name === 'hashtag')
        return (<Grid3x3Icon {...props} />)
    else if (name === 'delete')
        return (<DeleteForeverIcon {...props} />)
    else if (name === 'multi-person')
        return (<GroupIcon {...props} />)
    else if (name === 'eye')
        return (<VisibilityIcon {...props} />)
    else if (name === 'eye-off')
        return (<VisibilityOffIcon {...props} />)
    else if (name === 'person-circle')
        return (<AccountCircleIcon {...props} />)
    else if (name === 'zoom')
        return (<ZoomOutMapIcon {...props} />)
    else if (name === 'rotate')
        return (<Rotate90DegreesCcwIcon {...props} />)
    else if (name === 'crop')
        return (<CropIcon {...props} />)
    else if (name === 'vabzeum_prisme')
        return (<img alt='icon_prisme' src={require("../statics/images/icons/icon_prisme.png").default} {...props} />)
    else if (name === 'vabzeum_perco')
        return (<img alt='icon_perco' src={require("../statics/images/icons/icon_perco.png").default} {...props} />)
    else if (name === 'vabzeum_atk')
        return (<img alt='icon_atk' src={require("../statics/images/icons/icon_atk.png").default} {...props} />)
    else if (name === 'vabzeum_def')
        return (<img alt='icon_def' src={require("../statics/images/icons/icon_def.png").default} {...props} />)
    else if (name === 'dungeon')
        return (<img alt='icon_dungeon' src={require("../statics/images/icons/icon_dungeon.png").default} {...props} />)
    else if (name === 'quest')
        return (<img alt='icon_quest' src={require("../statics/images/icons/icon_quest.png").default} {...props} />)
    else if (name === 'dungeon2')
        return (<img alt='icon_dungeon' src={require("../statics/images/vabrusher/dungeons/default.png").default} {...props} />)
    else if (name === 'quest2')
        return (<img alt='icon_quest' src={require("../statics/images/vabrusher/quests/default.png").default} {...props} />)
    else if (name === 'idole')
        return (<img alt='icon_idole' src={require("../statics/images/icons/icon_idole.png").default} {...props} />)
    else if (name === 'user')
        return (<img alt='icon_user' src={require("../statics/images/users/icon_profile_default.png").default} {...props} />)
    else if (name === 'user2')
        return (<img alt='icon_user2' src={require("../statics/images/icons/icon_user2.png").default} {...props} />)
    else if (name === 'swords')
        return (<img alt='icon_swords' src={require("../statics/images/icons/icon_swords.png").default} {...props} />)
    else if (name === 'locker')
        return (<img alt='icon_locker' src={require("../statics/images/icons/icon_locker.png").default} {...props} />)
    else if (name === 'stats')
        return (<img alt='icon_stats' src={require("../statics/images/icons/icon_stats.png").default} {...props} />)
    else if (name === 'death')
        return (<img alt='icon_death' src={require("../statics/images/icons/icon_death.png").default} {...props} />)
    else if (name === 'star2')
        return (<img alt='icon_star' src={require("../statics/images/icons/icon_star.png").default} {...props} />)
    else if (name === 'wait')
        return (<img alt='icon_wait' src={require("../statics/images/icons/icon_wait.png").default} {...props} />)
    else if (name === 'add')
        return (<AddIcon {...props} />)
    else if (name === 'graph')
        return (<AutoGraphIcon {...props} />)
    return (
        <div>icon not found {props.name}</div>
    )
}
