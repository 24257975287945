/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { CustomP, getLocalObject, getUserAvatarLink, PageTitle, Section } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import { useHistory } from 'react-router';
import { IconHub } from "../../components/icon.js";
import { CustomLoadingButton } from "../../components/button.js";
import { Avatar } from "@mui/material";

const ProfileSubSection = (props) => {
    return (
        <div className={`flex m-2 ${props.className}`}>
            {props.iconName && <> <IconHub name={props.iconName} className={`mx-2 ${props.iconClassName}`} /> </>}
            {props.children}
        </div>
    )
}

const ProfileListElem = (props) => {
    return (
        <div>
            {props.content}
        </div>
    )
}

const UserProfile = () => {
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [avatar, setAvatar] = useState(undefined)
    const [name, setName] = useState(false)
    const [discordId, setDiscordId] = useState(false)
    const [roleList, setRoleList] = useState([])
    const [renderedRoleList, setRenderedRoleList] = useState([])
    const [characterList, setCharacterList] = useState([])
    const [renderedCharacterList, setRenderedCharacterList] = useState([])
    const [created, setCreated] = useState(false)

    const routerHistory = useHistory();

    useEffect(() => {
        axios.post('/api/users', {
            target_member: getLocalObject('session', 'user_id'),
            user_id: session.user_id,
            session_token: session.session_token
        }).then(response => {
            // console.log(response.data[0])
            setName(response.data[0].name)
            setDiscordId(response.data[0].discord_id)
            setRoleList(response.data[0].roles)
            setCharacterList(response.data[0].characters ? response.data[0].characters : [])
            setCreated(response.data[0].created)
            setAvatar(getUserAvatarLink(response.data[0].avatar))
        }).catch(error => {
            console.log(error)
        })
    }, [])

    useEffect(() => {
        var renderedRoles = []
        if (roleList) {
            for (var i = 0; i < roleList.length; i++) {
                renderedRoles.push(<ProfileListElem rowLen={roleList.length} key={i} index={i} content={roleList[i][1]} theme={theme} />)
            }
        }
        setRenderedRoleList(renderedRoles)
    }, [roleList])


    useEffect(() => {
        var renderedCharacters = []
        for (var i = 0; i < characterList.length; i++) {
            renderedCharacters.push(<ProfileListElem rowLen={characterList.length} key={i} index={i} content={characterList[i]} theme={theme} />)
        }
        setRenderedCharacterList(renderedCharacters)
    }, [characterList])

    const separatorStyle = {
        border: `2px solid ${theme.primary}`,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    return (
        <div className='flex flex-col justify-center mt-4'>
            <Section noRefresh width='max'>
                <div>
            <PageTitle reverse>{name}</PageTitle>
                    <CustomP>
                        <div>
                            <Avatar sx={{
                                width: 150,
                                height: 150,
                                boxShadow: theme.boxShadow
                            }}
                                className='mx-auto mb-8'
                                src={avatar} />
                            <div>
                                <ProfileSubSection iconName='person'>
                                    Pseudo: {name}
                                </ProfileSubSection>
                                <ProfileSubSection iconName='discord' iconClassName='w-6'>
                                    ID Discord: {discordId}
                                </ProfileSubSection>
                            </div>
                            <div style={separatorStyle} className='w-2/12 m-auto rounded-2xl my-2' />
                            <div className='flex m-0 p-0'>
                                <ProfileSubSection iconName='multi-person'>
                                    Mes rôles:
                                </ProfileSubSection>
                                <ProfileSubSection className='flex flex-col'>
                                    {renderedRoleList}
                                </ProfileSubSection>
                            </div>
                            <div className='flex m-0 p-0'>
                                <ProfileSubSection iconName='manette'>
                                    Mes persos:
                                </ProfileSubSection>
                                <ProfileSubSection className='flex flex-col'>
                                    {renderedCharacterList}
                                </ProfileSubSection>
                            </div>
                            <div style={separatorStyle} className='w-2/12 m-auto rounded-2xl my-2' />
                            <ProfileSubSection iconName='time'>
                                Création du compte: {created}
                            </ProfileSubSection>
                        </div>
                    </CustomP>
                    <div className='flex justify-center'>
                        <CustomLoadingButton
                            loading={false}
                            loadingColor={theme.primary}
                            startIcon={<IconHub name='edit' />}
                            onClick={() => { routerHistory.push('/user/edit') }}
                        >Modifier
                        </CustomLoadingButton>
                    </div>
                </div>
            </Section>
        </div>
    );
}

const UserProfilePage = () => {
    return (
        <Access>
            <UserProfile />
        </Access>
    )
}

export default UserProfilePage