/* eslint-disable react-hooks/exhaustive-deps */
/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/

import React, { useContext, useState } from "react";
import axios from 'axios';
import { Formik, Form } from 'formik';
import { PageTitle, CustomP, PopMessageParams, removeLocal, setLocal } from "../components/utils.js";
import { useSnackbar } from 'notistack';
import Access from "../components/access.js";
import { clearSession, SessionContext, ThemeContext } from "../components/context.js";
import { CustomLoadingButton } from "../components/button.js";
import { CustomTextInput } from "../components/textInput.js";
import { useHistory } from 'react-router';
import { loginSchema } from "../components/formSchema.js";
import { IconHub } from "../components/icon.js";


const LoginForm = () => {
    const { theme } = useContext(ThemeContext)
    const { session, setSession } = useContext(SessionContext)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const routerHistory = useHistory();

    return (
        <div className='flex flex-wrap justify-center mt-24
        w-11/12
        sm: flex'
        >
            <React.Fragment>
                <div style={{
                    backgroundColor: theme.secondary,
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
                }}
                className='rounded-lg  
                w-full flex-row items-center justify-center max-w-screen-sm'>
                    <div className='mt-16'>
                        <PageTitle reverse>Connexion</PageTitle>
                    </div>
                    <div className="flex justify-center text-center py-2" style={{
                        backgroundColor: theme.primary
                    }}>
                        <CustomP reverse>
                            Tu peux retrouver ton login sur discord avec <b>&gt;login</b>
                            <br/>Tu peux changer ton mdp sur discord avec la commande <b>&gt;resetmdp</b>
                        </CustomP>
	    	    </div>
                    <div className='w-11/12 m-auto flex-row'>
                        <Formik
                            initialValues={{ pseudo: "", password: "" }}
                            validationSchema={loginSchema}
                            onSubmit={(values) => {
                                setLoading(true)
                                axios.post('/api/connect', { 
                                    name: values.pseudo, 
                                    password: values.password,
                                    user_id: session.user_id,
                                    session_token: session.session_token
                                })
                                    .then(response => {
                                        try {
                                            enqueueSnackbar(response.data.response, PopMessageParams('success'))
                                            const session_data = {
                                                user_id: response.data.usr_id,
                                                pseudo: response.data.pseudo,
                                                session_token: response.data.session_token,
                                                roles: response.data.roles,
                                                avatar: response.data.avatar
                                            };
                                            // console.log("roles -> ", session_data.roles)
                                            // console.log(session_data)
                                            setSession(session_data)
                                            setLocal('session', JSON.stringify(session_data))
                                            setTimeout(() => {
                                                routerHistory.push('/')
                                            }, 500);
                                        } catch {
                                            clearSession(setSession)
                                            removeLocal("session")
                                            enqueueSnackbar("Quelque chose s'est mal passé :(")
                                        }
                                    })
                                    .catch(error => {
                                        if (error.response.status === 406) {
                                            enqueueSnackbar(error.response.data['error'], PopMessageParams('error'))
                                        } else if (error.response.status === 403) {
                                            enqueueSnackbar(error.response.data['error'], PopMessageParams('error'))
                                        } else if (error.response.status === 500) {
                                            enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
                                        }
                                    })
                                    .finally(() => {
                                        setLoading(false)
                                    })
                            }}
                        >
                            <Form>
                                <div>
                                    <CustomTextInput
                                        name="pseudo"
                                        type="pseudo"
                                        placeholder="Pseudo"
                                        startIcon={<IconHub name='person' />}
                                    />
                                </div>
                                <div>
                                    <CustomTextInput
                                        name="password"
                                        type="password"
                                        placeholder="Mot de passe"
                                        startIcon={<IconHub name='key' />}
                                    />
                                </div>
                                <div className='mt-8 mb-4 flex justify-center'>
                                    <CustomLoadingButton
                                        loading={loading}
                                        loadingColor={theme.primary}
                                        outlined={true}
                                        endIcon={<IconHub name='login' />}
                                        type='submit'
                                    >
                                        Connexion
                                    </CustomLoadingButton>
                                </div>
                            </Form>
                        </Formik>
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}


const Login = () => {

    return (
        <div className='flex justify-center items-center w-full'
        >
            <LoginForm />
        </div>
    );
}

const LoginPage = () => {
    return (
        <Access>
            < Login />
        </Access>
    )
}


export default LoginPage
