/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { errorThemeModel, ThemeContext } from "./context";


const CustomThemed = styled(IconButton)((props) => ({
    color: props.theme.secondary,
    backgroundColor: props.theme.primary,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.primary_lighter,
        border: `2px solid ${props.theme.primary}`,
    },
    border: `2px solid ${props.theme.primary}`,
    boxShadow: props.theme.boxShadow
}));

const CustomThemedReverse = styled(IconButton)((props) => ({
    color: props.theme.primary,
    backgroundColor: props.theme.secondary,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.secondary_lighter,
        border: `2px solid ${props.theme.secondary}`,
    },
    border: `2px solid ${props.theme.secondary}`,
    boxShadow: props.theme.boxShadow
}));

const CustomThemedOutlined = styled(IconButton)((props) => ({
    color: props.theme.primary,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.secondary_lighter,
        border: `2px solid ${props.theme.primary}`,
    },
    border: `2px solid ${props.theme.primary}`,
    boxShadow: props.theme.boxShadow
}));

const CustomThemedReverseOutlined = styled(IconButton)((props) => ({
    color: props.theme.secondary_darker,
    fontWeight: 'bold',
    '&:hover': {
        backgroundColor: props.theme.primary_lighter,
        border: `2px solid ${props.theme.secondary}`,
    },
    border: `2px solid ${props.theme.secondary}`,
    boxShadow: props.theme.boxShadow
}));

export const CustomIconButton = (props) => {
    const [buttonAttr, setButtonAttr] = useState(false)
    const { theme } = useContext(ThemeContext)

    
    useEffect(() => {
        var themevariant = null
        if (props.themevariant && props.themevariant === 'error'){
            themevariant = errorThemeModel()
        }
        setButtonAttr({
            theme: props.invisible || themevariant || theme,
            // variant: props.variant || 'outlined', //text, contained, outlined
            disabled: props.disabled || false,
            size: props.size || 'small',
            type: props.type || 'button',
            // onClick: props.onClick || undefined
        })
    }, [theme])

    return (
        <div className={`m-1 transition-all duration-500 ${props.className}`}
            onClick={props.onClick}>
            {buttonAttr && <>
                {!props.loading && <>
                    {props.outlined && <>
                        {props.reverse && <>
                            <CustomThemedReverseOutlined {...buttonAttr}>{props.children}</CustomThemedReverseOutlined>
                        </>} {!props.reverse && <>
                            <CustomThemedOutlined {...buttonAttr}>{props.children}</CustomThemedOutlined>
                        </>}
                    </>}
                    {!props.outlined && <>
                        {props.reverse && <>
                            <CustomThemedReverse {...buttonAttr}>{props.children}</CustomThemedReverse>
                        </>} {!props.reverse && <>
                            <CustomThemed {...buttonAttr}>{props.children}</CustomThemed>
                        </>}
                    </>}
                </>}
            </>}
        </div>
    )
}