import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
    pseudo: Yup.string()
        .required("Obligatoire")
        .matches(/^\S*$/, "Ne peut pas contenir d'espaces"),
    password: Yup.string()
        .required("Obligatoire")
        .matches(/^\S*$/, "Ne peut pas contenir d'espaces")
});

export const CreateUserSchema = Yup.object().shape({
    pseudo: Yup.string()
        .required("Obligatoire")
        .min(2, 'Doit faire entre 2 et 15 caractères')
        .max(15, 'Doit faire entre 2 et 15 caractères')
        .matches(/^\S*$/, "Ne peut pas contenir d'espaces"),
    password: Yup.string()
        .required("Obligatoire")
        .min(3, 'Doit faire entre 3 et 15 caractères')
        .max(15, 'Doit faire entre 3 et 15 caractères')
        .matches(/^\S*$/, "Ne peut pas contenir d'espaces"),
    discord_id: Yup.string()
    .required("Obligatoire")
    .matches(/^[0-9]+$/, "Il ne doit y avoir que des chiffres")
    .matches(/^\S*$/, "Ne peut pas contenir d'espaces")
    .min(18, 'Doit faire 18 caractères')
    .max(18, 'Doit faire 18 caractères')
});

export const ModifyUserSchema = Yup.object().shape({
    pseudo: Yup.string()
        .required("Obligatoire")
        .min(2, 'Doit faire entre 2 et 15 caractères')
        .max(15, 'Doit faire entre 2 et 15 caractères')
        .matches(/^\S*$/, "Ne peut pas contenir d'espaces"),
    password: Yup.string()
        .min(3, 'Doit faire entre 3 et 15 caractères')
        .max(15, 'Doit faire entre 3 et 15 caractères')
        .matches(/^\S*$/, "Ne peut pas contenir d'espaces"),
    discord_id: Yup.string()
    .required("Obligatoire")
    .matches(/^[0-9]+$/, "Il ne doit y avoir que des chiffres")
    .matches(/^\S*$/, "Ne peut pas contenir d'espaces")
    .min(18, 'Doit faire 18 caractères')
    .max(18, 'Doit faire 18 caractères')
});


export const VabzeumBonusSchema = Yup.object().shape({
    name: Yup.string()
        .required("Obligatoire"),
    value: Yup.string()
        .required("Obligatoire"),
});