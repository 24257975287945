/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { PageTitle, PopMessageParams, CustomP, CustomCheckBox, getSelectList } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import { CustomLoadingButton } from "../../components/button.js";
import { Formik, Form } from 'formik';
import { useSnackbar } from 'notistack';
import { CustomTextInput } from "../../components/textInput.js";
import { IconHub } from "../../components/icon.js";
import { CustomSelect } from "../../components/select.js";
import { VabzeumBonusSchema } from "../../components/formSchema.js";



const AdminVabzeumBonusCreateForm = () => {
    const { theme } = useContext(ThemeContext)
    const [loading, setLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const { session } = useContext(SessionContext)

    const [bonusType, setBonusType] = useState({})
    const [selectedBonusType, setSelectedBonusType] = useState(undefined)

    const [baseData, setBaseData] = useState({})

    const [selectedEntityCond, setSelectedEntityCond] = useState(undefined)
    const [selectedSideCond, setSelectedSideCond] = useState(undefined)
    const [selectednbGroupCond, setSelectedNbGroupCond] = useState(undefined)
    const [selectedHourMinCond, setSelectedHourMinCond] = useState(undefined)
    const [selectedHourMaxCond, setSelectedHourMaxCond] = useState(undefined)

    const [operation, setOperation] = useState('x')
    const [auto, setAuto] = useState(false)
    const [enabled, setEnabled] = useState(false)
    const [hourCond, setHourCond] = useState(false)
    const [nbGroupCond, setNbGroupCond] = useState(false)
    const [entityCond, setEntityCond] = useState(false)
    const [sideCond, setSideCond] = useState(false)

    const separatorStyle = {
        border: `2px solid ${theme.primary}`,
        boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    }

    const handleOperation = (op) => {
        setOperation(op)
    }

    const handleSelectBonusType = (type) => {
        setSelectedBonusType(type)
    }

    const handleKeyDownBonusValue = e => {
        if (!((e.keyCode >= 48 && e.keyCode <= 57)
            || (e.keyCode >= 96 && e.keyCode <= 105)
            || e.keyCode === 110
            || e.keyCode === 190
            || e.keyCode === 8
            || e.keyCode === 13)) {
            e.preventDefault()
        }
    }

    const inputStyle = {
        backgroundColor: theme.primary,
        boxShadow: theme.boxShadow,
        border: `2px solid ${theme.primary}`,
        borderRadius: 2,
        color: theme.secondary,
        carretColor: theme.secondary,
    }

    useEffect(() => {
        // var target = parseInt(new URLSearchParams(window.location.search).get('bonus'))
        axios.post('/api/admin/vabzeum/get_bonus', {
            user_id: session.user_id,
            session_token: session.session_token

        })
            .then(response => {
                var formatted_bonus_type_list = {}
                response.data.bonus_type.forEach(type => {
                    formatted_bonus_type_list[type.name] = type
                })
                setBonusType(formatted_bonus_type_list)

                var formatted_base_data = {}
                for (const key in response.data.base_data) {
                    formatted_base_data[key] = {}
                    response.data.base_data[key].forEach(data => {
                        formatted_base_data[key][data.name] = data
                    })
                }
                setBaseData(formatted_base_data)
            })
            .catch(e => {
                enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
            })
    }, [])

    return (
        <div className='flex flex-wrap justify-center my-8 mx-auto
        w-11/12
        sm: flex'
        >
            <React.Fragment>
                <div style={{
                    backgroundColor: theme.secondary,
                    boxShadow: 'rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px'
                }}
                    className='rounded-lg  
                    w-full flex-row items-center justify-center max-w-screen-sm'>
                    <div className='mt-16'>
                        <PageTitle reverse>Créer un bonus</PageTitle>
                    </div>
                    <div className='w-11/12 m-auto flex-row'>
                        <Formik
                            initialValues={{
                                name: "",
                                operation: "",
                                value: "",
                                type: "",
                                auto: false,
                                hourMin: false,
                                hourMax: false,
                                nbGroup: false,
                                entity: false,
                                side: false
                            }}
                            validationSchema={VabzeumBonusSchema}
                            onSubmit={(values, { resetForm }) => {
                                if (!selectedBonusType) {
                                    enqueueSnackbar("Il faut choisir un type de bonus", PopMessageParams())
                                }
                                else if ((selectedHourMinCond === undefined && selectedHourMaxCond !== undefined)
                                || (selectedHourMinCond !== undefined && selectedHourMaxCond === undefined)) {
                                    enqueueSnackbar("Si tu choisis un horaire, il faut un minimum et un maximum", PopMessageParams())
                                }
                                else if (selectedHourMinCond > selectedHourMaxCond) {
                                    enqueueSnackbar("L'heure minimum doit être inférieure à l'heure maximum", PopMessageParams())
                                }
                                else if (isNaN(parseFloat(values.value))) {
                                    enqueueSnackbar("La valeur du bonus doit être un chiffre (ex: 1, 1.45, 12)", PopMessageParams())
                                }
                                else {
                                    setLoading(true)
                                    axios.post('/api/admin/vabzeum/create_bonus', {
                                        user_id: session.user_id,
                                        session_token: session.session_token,
                                        bonus: {
                                            name: values.name,
                                            operation: operation,
                                            value: parseFloat(values.value),
                                            auto: auto,
                                            bonusType: selectedBonusType,
                                            hourMin: selectedHourMinCond,
                                            hourMax: selectedHourMaxCond,
                                            nbGroup: selectednbGroupCond,
                                            side: selectedSideCond,
                                            entity: selectedEntityCond,
                                            enabled: enabled
                                        }
                                    }).then(response => {
                                        enqueueSnackbar(response.data, PopMessageParams('success'))
                                        resetForm({})
                                        setOperation('x')
                                        setAuto(false)
                                        setSelectedBonusType(undefined)
                                        setEnabled(false)
                                        setHourCond(false)
                                        setNbGroupCond(false)
                                        setSideCond(false)
                                        setEntityCond(false)
                                        setSelectedHourMinCond(undefined)
                                        setSelectedHourMaxCond(undefined)
                                        setSelectedEntityCond(undefined)
                                        setSelectedSideCond(undefined)
                                        setSelectedNbGroupCond(undefined)
                                    }).catch(error => {
                                        enqueueSnackbar(error.response.data.error, PopMessageParams('error'))
                                    }).finally(
                                        setLoading(false)
                                    )
                                }
                            }}
                        >
                            {({
                                setFieldValue
                            }) => {
                                return (
                                    <Form>
                                        <div className="flex flex-wrap justify-center items-start
                                        sm:space-x-1">
                                            <div className="
                                            min-w-35 mt-1
                                            sm:w-4/12">
                                                <CustomTextInput

                                                    style={inputStyle}
                                                    name="name"
                                                    type="name"
                                                    placeholder="Nom du bonus"
                                                />
                                            </div>
                                            <div className="
                                            min-w-35 mt-5
                                            sm:w-2/12">
                                                <CustomSelect
                                                    placeHolder={operation}
                                                    // reverse
                                                    outlined
                                                    options={{ '+': "+", 'x': "x" }}
                                                    callBack={handleOperation} />
                                            </div>
                                            <div className="
                                            min-w-35 mt-1
                                            sm:w-4/12">
                                                <CustomTextInput
                                                    onKeyDown={handleKeyDownBonusValue}
                                                    style={inputStyle}
                                                    name="value"
                                                    type="value"
                                                    placeholder="Valeur du bonus"
                                                />
                                            </div>
                                        </div>
                                        <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                                        <div className="flex flex-wrap justify-center items-center
                                        sm:space-x-8">
                                            <div
                                                className="cursor-pointer flex items-center justify-center"
                                                onClick={() => { setAuto(auto !== true) }}>
                                                <CustomCheckBox label='Bonus automatique' className="ml-2 mt-2" selected={auto} />
                                            </div>
                                            <div
                                                className="flex items-center justify-center mt-1">
                                                <CustomP>Type de bonus</CustomP>
                                                <CustomSelect
                                                    className='w-28'
                                                    outlined
                                                    options={bonusType}
                                                    placeHolder={selectedBonusType ? selectedBonusType : 'Choisir'}
                                                    callBack={handleSelectBonusType} />
                                            </div>
                                            <div
                                                className="cursor-pointer flex items-center justify-center"
                                                onClick={() => { setEnabled(enabled !== true) }}>
                                                <CustomCheckBox label='Bonus actif' className="ml-2 mt-2" selected={enabled} />
                                            </div>
                                        </div>
                                        <div style={separatorStyle} className='w-6/12 m-auto rounded-2xl my-2' />
                                        <div className="flex justify-center text-lg font-bold">
                                            <CustomP>Conditions de validité</CustomP>
                                        </div>
                                        <div className="flex flex-wrap justify-center items-start">
                                            <div className="m-2 flex flex-col justify-center">
                                                <CustomCheckBox
                                                    label="Horaire"
                                                    className="mx-auto"
                                                    selected={hourCond}
                                                    onClick={() => { if (hourCond === true) { setSelectedHourMinCond(undefined); setSelectedHourMaxCond(undefined) }; setHourCond(hourCond !== true) }} />
                                                {hourCond && <>
                                                    <div className="flex flex-wrap justify-center">
                                                        <CustomSelect
                                                            outlined
                                                            options={getSelectList(23, 0, selectedHourMaxCond || 23)}
                                                            placeHolder={selectedHourMinCond ? selectedHourMinCond : 'Début'}
                                                            callBack={(hour) => { setSelectedHourMinCond(hour) }}
                                                            unHoverDelay={50}
                                                        />
                                                        <CustomSelect
                                                            outlined
                                                            options={getSelectList(23, selectedHourMinCond || 0)}
                                                            placeHolder={selectedHourMaxCond ? selectedHourMaxCond : 'Fin'}
                                                            callBack={(hour) => { setSelectedHourMaxCond(hour) }}
                                                            unHoverDelay={50}
                                                        />
                                                    </div>
                                                </>}
                                            </div>
                                            <div className="m-2 flex flex-col justify-center">
                                                <CustomCheckBox
                                                    label="Nombre d'allié"
                                                    className="mx-auto"
                                                    selected={nbGroupCond}
                                                    onClick={() => { if (nbGroupCond === true) { setSelectedNbGroupCond(undefined) }; setNbGroupCond(nbGroupCond !== true) }} />
                                                {nbGroupCond && <>
                                                    <CustomSelect
                                                        outlined
                                                        options={getSelectList(5, 1)}
                                                        placeHolder={selectednbGroupCond ? selectednbGroupCond : 'Choisir'}
                                                        callBack={(nbGroup) => { setSelectedNbGroupCond(nbGroup) }}
                                                        unHoverDelay={50}
                                                    />
                                                </>}
                                            </div>
                                            <div className="m-2 flex flex-col justify-center">
                                                <CustomCheckBox
                                                    label="Atk/Def"
                                                    className="mx-auto"
                                                    selected={sideCond}
                                                    onClick={() => { if (sideCond === true) { setSelectedSideCond(undefined) }; setSideCond(sideCond !== true) }} />
                                                {sideCond && <>
                                                    <CustomSelect
                                                        className=''
                                                        outlined
                                                        options={baseData ? baseData.side : {}}
                                                        placeHolder={selectedSideCond ? selectedSideCond : 'Choisir'}
                                                        callBack={(side) => { setSelectedSideCond(side) }}
                                                        unHoverDelay={50}
                                                    />
                                                </>}
                                            </div>
                                            <div className="m-2 flex flex-col justify-center">
                                                <CustomCheckBox
                                                    label="Perco/Prisme"
                                                    className="mx-auto"
                                                    selected={entityCond}
                                                    onClick={() => { if (entityCond === true) { setSelectedEntityCond(undefined) }; setEntityCond(entityCond !== true) }} />
                                                {entityCond && <>
                                                    <CustomSelect
                                                        className=''
                                                        outlined
                                                        options={baseData ? baseData.entity : {}}
                                                        placeHolder={selectedEntityCond ? selectedEntityCond : 'Choisir'}
                                                        callBack={(entity) => { setSelectedEntityCond(entity) }}
                                                        unHoverDelay={50}
                                                    />
                                                </>}
                                            </div>
                                        </div>
                                        <div className='mt-8 mb-4 flex justify-center'>
                                            <CustomLoadingButton
                                                loading={loading}
                                                loadingColor={theme.primary}
                                                outlined={true}
                                                endIcon={<IconHub name='add' />}
                                                type='submit'
                                            >
                                                Créer
                                            </CustomLoadingButton>
                                        </div>

                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}



const AdminVabzeumBonusCreate = () => {
    return (
        <div className='flex-row justify-center'>
            <AdminVabzeumBonusCreateForm />
        </div>
    );
}

const AdminVabzeumBonusCreatePage = () => {
    return (
        <Access>
            <AdminVabzeumBonusCreate />
        </Access>
    )
}


export default AdminVabzeumBonusCreatePage