/* eslint-disable react-hooks/exhaustive-deps */
/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/

import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { CustomP, getUserAvatarLink, PageTitle, PopMessageParams, Section } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import { IconHub } from "../../components/icon.js";
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { CustomSelect } from "../../components/select.js";
import { CustomTextInput } from "../../components/textInput.js";
import { Form, Formik } from "formik";
import { CustomIconButton } from "../../components/iconButton.js";
import { NavLink } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Avatar } from "@mui/material";


const UserListElem = (props) => {
    return (
        <div style={{
            backgroundColor: props.index % 2 === 1 ? props.theme.secondary : props.theme.secondary_lighter,
            borderTop: props.index > 0 ? `1px solid ${props.theme.primary}` : ''
        }}
            className={`py-4 flex justify-around text-center items-center`}>
            <div className='flex justify-center
                w-3/12
                sm:w-3/12
                lg:w-1/12'>
                <Avatar
                    alt={props.content}
                    src={getUserAvatarLink(props.user.avatar)}
                    sx={{ 
                        width: 56,
                        height: 56,
                        boxShadow: props.theme.boxShadow }}  
                />
            </div>
            <div className='
                hidden
                sm:w-1/12 sm:block
                lg:w-1/12'>
                <CustomP>{props.user.id}</CustomP>
            </div>
            <div className='
                w-4/12
                sm:w-2/12
                lg:w-1/12'>
                <CustomP>{props.user.name}</CustomP>
            </div>
            <div className='
                hidden
                lg:block lg:w-2/12'>
                <CustomP>{props.user.discord_id}</CustomP>
            </div>
            <div className='
                hidden
                sm:block sm:w-2/12
                lg:w-1/12'>
                <CustomP>{props.user.enabled ? <IconHub fontSize='large' name='checkbox-circle' /> : <IconHub fontSize='large' name='crossbox' />}</CustomP>
            </div>
            <div className='
                hidden
                sm:block sm:w-2/12
                lg:w-1/12'>
                <CustomP>{props.user.roles && props.user.roles.map((elem, key) => <CustomP key={key}>{elem[1]}</CustomP>)}</CustomP>
            </div>
            <div className='
                hidden
                lg:block lg:w-1/12'>
                <CustomP>{props.user.characters && props.user.characters.map((elem, key) => <CustomP key={key}>{elem}</CustomP>)}</CustomP>
            </div>
            <div className='
                hidden
                lg:block lg:w-1/12'>
                <CustomP>{props.user.created}</CustomP>
            </div>
            <div
                className='
                w-4/12
                sm:w-2/12
                lg:w-1/12'>
                <NavLink to={`/admin/user?user=${props.user.id}`}>
                    <CustomP className={`cursor-pointer`}><IconHub fontSize='large' name='edit' /></CustomP>
                </NavLink>
            </div>
        </div>
    )
}

const AdminUserList = () => {
    const [userList, setUserList] = useState([])
    const [renderedUserList, setRenderedUserList] = useState([])
    const [renderedUsers, setRenderedUsers] = useState([])
    const [nbRenderedUsers, setNbRenderedUsers] = useState(0)
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const { enqueueSnackbar } = useSnackbar()


    const refreshUserList = () => {
        axios.post('/api/users', {
            user_id: session.user_id,
            session_token: session.session_token,
            filters: {}
        })
            .then(response => {
                setUserList(response.data)
                setRenderedUserList(response.data)
            })
            .catch(() => {
                enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
            })
    }

    useEffect(() => {
        refreshUserList()
    }, [])

    useEffect(() => {
        var renderedUser = []
        for (var i = 0; i < renderedUserList.length; i++) {
            renderedUser.push(<UserListElem rowLen={renderedUserList.length} key={i} index={i} user={renderedUserList[i]} theme={theme} />)
            setRenderedUsers(renderedUser)
        }
        setNbRenderedUsers(renderedUser.length)
    }, [renderedUserList])

    const [searchIndex, setSearchIndex] = useState(false)
    const options = { 'Id': 0, 'Pseudo': 1, 'Id discord': 2, 'Activé': 3, 'Roles': 4, 'Personnages': 5 }

    const handleSearchIndex = (index) => {
        setSearchIndex(index)
    }

    const searchForUser = (search) => {
        if (search.length === 0) {
            setRenderedUserList([...userList])
        }
        else {
            const tmpRenderedUserList = []
            for (const user in userList) {
                const key = options[searchIndex]
                if (['Id', 'Pseudo', 'Id discord'].indexOf(searchIndex) !== -1) {
                    if (String(userList[user][key]).toLowerCase().indexOf(String(search).toLowerCase()) !== -1) {
                        tmpRenderedUserList.push(userList[user])
                    }
                } else if (['Roles', 'Personnages'].indexOf(searchIndex) !== -1) {
                    for (const elem in userList[user][key]) {
                        if (String(userList[user][key][elem]).toLowerCase().indexOf(String(search).toLowerCase()) !== -1) {
                            tmpRenderedUserList.push(userList[user])
                        }
                    }
                } else if (['Activé'].indexOf(searchIndex) !== -1) {
                    if ((!userList[user][key] && !search.length) || (userList[user][key] && search.length)) {
                        tmpRenderedUserList.push(userList[user])
                    }
                }
            }
            if (tmpRenderedUserList.length) {
                setRenderedUserList(tmpRenderedUserList)
            }
        }
    }

    return (
        <div className='flex-row justify-center mb-4'>
            <PageTitle>Liste des membres</PageTitle>
            <Section title='Recherche' max_width={600} className='mb-2' noRefresh>
                <div>
                    <div className='flex flex-wrap justify-center items-center'>
                        <CustomSelect
                            className='w-48 mt-6'
                            outlined
                            placeHolder={'Index de recherche'}
                            current={searchIndex}
                            options={options}
                            callBack={handleSearchIndex} />
                        {searchIndex && <>
                            <Formik
                                initialValues={{ search: '' }}
                                onSubmit={(values) => { searchForUser(values.search) }}>
                                <Form>
                                    <div className='flex items-center'>
                                        <CustomTextInput
                                            autoComplete='off'
                                            name="search"
                                            type="search"
                                            placeholder="Recherche"
                                        />
                                        <CustomIconButton
                                            className='mt-7'
                                            type='submit'
                                            reverse={false}
                                        >
                                            <IconHub 
                                                name='search' />
                                        </CustomIconButton>
                                    </div>
                                </Form>
                            </Formik>
                        </>}
                    </div>
                </div>
            </Section>
            <Section title={`${nbRenderedUsers} résultats`} max_width={1200} callBack={refreshUserList} className=''>
                <div className='w-full'>
                    <div className=''>
                        <div style={{ borderBottom: `2px solid ${theme.primary}` }}
                            className='border-b-2 flex justify-around items-center text-center h-12'>
                            <CustomP className='
                            w-4/12
                            sm:w-3/12
                            lg:w-1/12'></CustomP>
                            <CustomP className='
                            hidden
                            sm:w-1/12 sm:block
                            lg:w-1/12'>Id</CustomP>

                            <CustomP className='
                            w-4/12
                            sm:w-2/12
                            lg:w-1/12'>Pseudo</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-2/12
                            '>Id discord</CustomP>

                            <CustomP className='
                            hidden
                            sm:block sm:w-2/12
                            lg:w-1/12
                            '>Activé</CustomP>

                            <CustomP className='
                            hidden
                            sm:block sm:w-2/12
                            lg:w-1/12
                            '>Roles</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-1/12
                            '>Personnages</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-1/12
                            '>Création</CustomP>

                            <CustomP 
                            className='
                            w-4/12
                            sm:w-2/12
                            lg:w-1/12'>Modifier</CustomP>

                        </div>
                    </div>
                    <Scrollbars autoHide autoHeight autoHeightMax={window.innerHeight - 445}>
                        <div style={{
                            borderColor: theme.secondary,
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                        }}
                            v-for="item in items" className='w-full transform even:bg-gray-400'>
                            {renderedUsers}
                        </div>
                    </Scrollbars>
                </div>
            </Section >
        </div >
    );
}

const AdminUserListPage = () => {
    return (
        <Access>
            <AdminUserList />
        </Access>
    )
}

export default AdminUserListPage