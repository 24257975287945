import { CustomGif, PageTitle } from "../components/utils"

export const NotFound = () => {
    return (
        <div className='w-full'>
            <PageTitle>Tu t'es perdu?</PageTitle>
            <CustomGif
                type='404'
                alt="y'a rien ici"
                title='404 not found'
                className='mt-10 rounded m-auto'
            />
        </div>
    )
}