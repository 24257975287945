/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
// import axios from 'axios';
import Access from '../components/access.js'
import { getCarouselImageLink, PageTitle } from "../components/utils.js";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { SessionContext, ThemeContext } from "../components/context.js";
import axios from "axios";
// import Swiper from 'swiper';
// import 'swiper/css';

const Home = () => {
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [carousel, setCarousel] = useState(false)

    // const fs = require('fs');
    // const files = fs.readdirSync('/assets/photos/');
    // console.log(files)

    useEffect(() => {
        axios.post("/api/carousel", {
            user_id: session.user_id,
            session_token: session.session_token
        })
            .then(response => {
                const tmp_carousel = []
                var i = 0
                if (response.data) {
                    response.data.forEach(image => {
                        const imageLink = getCarouselImageLink(image)
                        tmp_carousel.push(
                            <img src={imageLink} alt={image} key={i} />
                        )
                        i += 1
                    })
                    setCarousel(tmp_carousel)
                }
            }).catch(() => {
                console.log("erreur carousel")
            })
    }, [])

    return (
        <div className=''>
            <PageTitle>Les Vagues à Bons</PageTitle>
            <div style={{
                backgroundImage: `linear-gradient(to bottom right, ${theme.secondary}, ${theme.secondary_darker})`
            }}
                className="flex mx-auto rounded-md
            max-w-1000">
                {carousel && <>
                    <Carousel
                        autoPlay
                        emulateTouch
                        infiniteLoop
                        interval={10000}
                        stopOnHover
                        showStatus={false}
                        // centerMode
                        // centerSlidePercentage={95}
                        useKeyboardArrows
                        dynamicHeight={true}
                    >
                        {carousel}
                    {/* <div> */}
                    {/* <ModalImg src={require("../statics/images/carousel/dessin_de_guilde_final.jpg").default} alt='fresque'/> */}
                    {/* <img src={require("../statics/images/carousel/dessin_de_guilde_final_reduit.jpg").default} alt='fresque'/> */}
                    {/* <p className="legend">Legend 1</p> */}
                    {/* </div> */}
                    {/* <div> */}
                    {/* <img src={require("../statics/images/carousel/screen_2.png").default} alt='screen guilde 2'/> */}
                    {/* <p className="legend">Legend 2</p> */}
                    {/* </div> */}
                    {/* <div> */}
                    {/* <img src={require("../statics/images/carousel/screen_1.png").default} alt='screen guilde 2'/> */}
                    {/* <p className="legend">Legend 2</p> */}
                    {/* </div> */}
                    </Carousel>
                    {/* <Swiper
                        spaceBetween={50}
                        slidesPerView={3}
                        onSlideChange={() => console.log('slide change')}
                        onSwiper={(swiper) => console.log(swiper)}
                    >
                        <SwiperSlide>Slide 1</SwiperSlide>
                        <SwiperSlide>Slide 2</SwiperSlide>
                        <SwiperSlide>Slide 3</SwiperSlide>
                        <SwiperSlide>Slide 4</SwiperSlide>
                        ...
                    </Swiper> */}
                </>}
            </div>
        </div>
    );

}

const Homepage = () => {
    return (
        <Access>
            <Home />
        </Access>
    )
}


export default Homepage