/* eslint-disable react-hooks/exhaustive-deps */

import { Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { ThemeContext, autoSwitchTheme, SessionContext } from "./context";
import { styled } from '@mui/material/styles';
import { getLocal, getLocalObject } from "./utils";

const SwitchThemeButton = styled(Button)(({ theme }) => ({
    color: theme.primary,
    backgroundColor: theme.secondary,
    '&:hover': {
        color: theme.primary_darker,
        backgroundColor: theme.secondary_darker
    }
}));

const ShowSessionData = () => {
    const { theme, setTheme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const [ctrlDown, setCtrlDown] = useState(false)

    const [show, setShow] = useState(false)

    const onKeyUp = e => {
        if (e.keyCode === 17) {
            setCtrlDown(false)
        }
        if (e.keyCode === 37 && ctrlDown) {
            setShow(false)
        }
    }

    const onKeyDown = e => {
        if (e.keyCode === 17) {
            setCtrlDown(true)
        }
        if (e.keyCode === 39 && ctrlDown) {
            setShow(true)
        }
    }
    
    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);
        window.addEventListener('keyup', onKeyUp);
        return () => {
            window.removeEventListener('keydown', onkeydown)
            window.removeEventListener('keyup', onKeyUp)
        }
    }, [ctrlDown])


    var i = 0
    const session_local = [<div key='session_local' className='font-bold text-sm'>Local Session datas:</div>]
    for (const property in getLocalObject('session')) {
        session_local.push(<div key={i} className=' text-sm'>{property}: {String(session[property])}</div>)
        i += 1
    }

    const theme_local = [<div key='theme_local' className='font-bold text-sm'>Local Theme:</div>]
    theme_local.push(<div className='text-sm' key={1}>Theme: {getLocal('theme') || "NONE"}</div>)

    const session_context = [<div key='session_context' className='font-bold text-sm'>SessionContext:</div>]
    for (const property in session) {
        session_context.push(<div key={i} className=' text-sm'>{property}: {String(session[property])}</div>)
        i += 1
    }

    const theme_context = [<div key='theme_context' className='font-bold text-sm'>ThemeContext:</div>]
    for (const property in theme) {
        theme_context.push(<div key={i} className=' text-sm'>{property}: {String(theme[property])}</div>)
        i += 1
    }

    const info = ["i", "n", "f", "o", "s"]
    const info_displayer = []
    for (const property in info) {
        info_displayer.push(<div key={i} className='text-center text-xs' >{info[property]}</div>)
        i += 1
    }

    // function switchShow() {
    //     setShow(show !== true)
    // }

    const handleButton = () => {
        autoSwitchTheme(setTheme, theme)
    }
    return (
        <div id='showData'
            className={"z-9999 bg-gray-800 fixed bottom-1 left-0 text-left shadow-2xl transition duration-700 ease-out transform text-white font-semibold " + (show ? "" : "-translate-x-full z-9999")}>
            <div className={"max-w-300 max-h-screen overflow-x-auto overflow-y-auto py-3 px-4 transition-all ease-out duration-1000 rounded-bl-2xl transform " + (show ? " opacity-100" : " opacity-0")}>
                {session_local}<br />
                {theme_local}<br />
                {session_context}<br />
                {theme_context}
                <SwitchThemeButton theme={theme} onClick={() => { handleButton() }}>
                    Switch theme
                </SwitchThemeButton>
            </div>

            {/* <button
                className={'bg-gray-900 absolute -top-1 -right-4 focus:outline-none transition-all rounded-r-md px-1 text-white mt-1'}
                onClick={() => { switchShow() }}
            >
                {info_displayer}
            </button> */}
        </div>
    )
}

export default ShowSessionData