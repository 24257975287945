/* eslint-disable react-hooks/exhaustive-deps */
/* eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }] */

import React, { useContext, useEffect, useState } from "react";
import axios from 'axios';
import Access from '../../components/access.js'
import { CustomP, PageTitle, PopMessageParams, Section, Pagination } from "../../components/utils.js";
import { SessionContext, ThemeContext } from "../../components/context.js";
import 'react-perfect-scrollbar/dist/css/styles.css'
import { Scrollbars } from 'react-custom-scrollbars-2';
import { useSnackbar } from "notistack";
import { CustomLoadingButton } from "../../components/button.js";
import { VabzeumMatchListElem } from "../admin/vabzeum_match_list";


const VabzeumMatchList = () => {
    const [_matchList, setMatchList] = useState([])
    const [renderedMatchList, setRenderedMatchList] = useState([])
    const [showOnlyMatchToValid, setShowOnlyMatchToValid] = useState(true)
    const { theme } = useContext(ThemeContext)
    const { session } = useContext(SessionContext)
    const { enqueueSnackbar } = useSnackbar()
    const [page, setPage] = useState(0)
    const [maxPage, setMaxPage] = useState(1)
    const [nbMatch, setNbMatch] = useState(0)
    const [mode, setMode] = useState(undefined)
    const nbPerPage = 50

    const refreshVabzeumMatchList = (currPage) => {
        if (!Number.isInteger(currPage)) {
            currPage = page
        }
        
        axios.post('/api/vabzeum/get_match', {
            user_id: session.user_id,
        session_token: session.session_token,
        page: currPage,
        nbPerPage: nbPerPage,
        showOnlyMatchToValid: showOnlyMatchToValid
        }).then(response => {
            setMatchList(response.data)
            var renderedMatch = []
            for (var i = 0; i < response.data.match_list.length; i++) {
                renderedMatch.push(<VabzeumMatchListElem notAdmin={true} rowLen={response.data.match_list.length} key={i} index={i} match={response.data.match_list[i]} theme={theme} page={page} />)
            }
            // console.log(response.data)
            setMaxPage(response.data.max_page)
            setNbMatch(response.data.nb_match)
            setMode(response.data.to_valid)
            setRenderedMatchList(renderedMatch)
            setPage(currPage)
        }).catch(() => {
            enqueueSnackbar("ERREUR SERVEUR", PopMessageParams('error'))
        })
        

    }

    useEffect(() => {
        refreshVabzeumMatchList(1)
    }, [showOnlyMatchToValid])

    const changePage = (newPage) => {
        if (newPage > 0 && newPage < (nbMatch / nbPerPage) + 1) {
            refreshVabzeumMatchList(newPage)
        }
    }

    const switchShowOnlyMatchToValid = () => {
        setShowOnlyMatchToValid(showOnlyMatchToValid !== true)
    }

    return (
        <div className='flex-row justify-center mb-8'>
            <PageTitle>Liste des matchs</PageTitle>
            <Section max_width={1600} callBack={refreshVabzeumMatchList} className=''
                title={`${!mode ? "Tous les matchs" : "Matchs non validés"} (${nbMatch})`}>
                <div className='w-full'>
                    <div className="flex flex-wrap justify-center">
                        <CustomLoadingButton
                            className='mx-10'
                            onClick={switchShowOnlyMatchToValid}
                            forceRefresh={[showOnlyMatchToValid]}
                        >{`${!mode ? "Voir les matchs non validés" : "Voir tous les matchs"}`}</CustomLoadingButton>
                        <Pagination page={page} nbPerPage={nbPerPage} maxPage={maxPage} nbElem={nbMatch} changePage={changePage} />
                    </div>
                    <div className=''>
                        <div style={{ borderBottom: `2px solid ${theme.primary}` }}
                            className='border-b-2 flex justify-around items-center text-center h-12'>
                            <CustomP className='
                            w-1/12'>Id</CustomP>
                            <CustomP className='
                            w-5/12
                            sm:w-2/12
                            md:w-1/12'>Alliés</CustomP>

                            <CustomP className='
                            w-4/12
                            sm:w-3/12
                            md:w-2/12
                            lg:w-1/12'>Récap</CustomP>

                            <CustomP className='
                            hidden
                            sm:block sm:w-1/12
                            '>Atk Def</CustomP>

                            <CustomP className='
                            hidden
                            sm:block sm:w-1/12
                            '>Perco Prisme</CustomP>

                            <CustomP className='
                            hidden
                            lg:block lg:w-1/12
                            '>Bonus</CustomP>

                            <CustomP className='
                            hidden
                            md:block md:w-1/12
                            '>Score</CustomP>

                            <CustomP className='
                            hidden
                            md:block md:w-1/12
                            '>Score Calculé</CustomP>

                            <CustomP
                                className='
                            hidden
                            sm:block sm:w-1/12
                            '>Vérifié</CustomP>

                            <CustomP
                                className='
                            hidden
                            sm:block sm:w-1/12
                            '>Upload Bot</CustomP>

                            <CustomP
                                className='
                            hidden
                            sm:block sm:w-1/12
                            '>Date</CustomP>

                            <CustomP
                                className='
                            w-2/12
                            sm:w-1/12
                            '></CustomP>

                        </div>
                    </div>
                    <Scrollbars autoHide autoHeight autoHeightMax={window.innerHeight - 350}>
                        <div style={{
                            borderColor: theme.secondary,
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
                        }}
                            v-for="item in items" className='w-full transform even:bg-gray-400'>
                            {renderedMatchList}
                        </div>
                    </Scrollbars>
                </div>
            </Section >
        </div >
    );
}

const VabzeumMatchListPage = () => {
    return (
        <Access>
            <VabzeumMatchList />
        </Access>
    )
}

export default VabzeumMatchListPage


