
// eslint-disable-next-line
/*eslint no-unused-vars: [1, { "varsIgnorePattern": "^_" }]*/
/* eslint-disable react-hooks/exhaustive-deps */


import { useContext, useEffect, useState } from 'react'
import React from 'react'
import { ModalImg } from './utils'
import { ThemeContext } from './context'


export const ScreenShotHandler = (props) => {

    const [width, _setWidth] = useState(215)
    const [height, _setHeight] = useState(116)
    const [hover, setHover] = useState(false)
    const { theme } = useContext(ThemeContext)

    const [drag, setDrag] = useState(false)

    useEffect(() => {
        if (!props.preventReupload) {
            window.addEventListener('paste', onPaste);
            window.addEventListener('mouseup', onDragLeave);
            window.addEventListener('mouseleave', onDragLeave);
            window.addEventListener('dragenter', onDragEnter);
            window.addEventListener('dragover', onDragOver);
            window.addEventListener('drop', onDrop);
        }
        // document.getElementById('dragbox').addEventListener('dragleave', onDragLeave);
        return () => {
            if (!props.preventReupload) {
                window.removeEventListener('paste', onPaste)
                window.removeEventListener('mouseup', onDragLeave);
                window.removeEventListener('mouseleave', onDragLeave);
                window.removeEventListener('dragenter', onDragEnter);
                window.removeEventListener('dragover', onDragOver);
                window.removeEventListener('drop', onDrop);
            }
            // document.getElementById('dragbox').removeEventListener('dragleave', onDragLeave);
        }
    }, [])

    // useInterval(() => {
    //     setDrag(false)
    // }, 2500)

    const onDragEnter = e => {
        e.stopPropagation();
        e.preventDefault();
        setDrag(true)
        return false
    }

    const onDragLeave = e => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(false)
        return false
    }

    const onDragOver = e => {
        e.preventDefault();
        e.stopPropagation();
        setDrag(true)
        return false
    }

    const onDrop = e => {
        e.preventDefault();
        if (e.dataTransfer.files.length) {
            props.setImage(URL.createObjectURL(e.dataTransfer.files[0]))
            props.setRef(e.dataTransfer.files[0])
        }
        setDrag(false)
    }

    const onPaste = (e) => {
        if (e.clipboardData.files.length) {
            props.setImage(URL.createObjectURL(e.clipboardData.files[0]))
            props.setRef(e.clipboardData.files[0])
        }
    }


    const handleNewImage = e => {
        if (e.target.files.length) {
            props.setImage(URL.createObjectURL(e.target.files[0]))
            props.setRef(e.target.files[0])
        }
    }

    return (
        <div
            style={{
                width: width,
                height: height,
                boxShadow: theme.boxShadow,
                border: `2px solid ${theme.secondary_lighter}`
            }}
            onMouseEnter={() => { setHover(true) }}
            onMouseLeave={() => { setHover(false) }}
            className='flex justify-center m-auto relative rounded-sm'>
            <div
                style={{
                    top: '50%',
                    left: '50%',
                    transform: 'translateX(-50%) translateY(-50%)'
                }}
                className='flex items-center w-max absolute'>
                {!props.preventReupload && <>
                    <label
                        id='dragbox'
                        style={{
                            backgroundColor: (hover || drag) ? theme.primary_lighter : props.preloadImg ? '' : theme.primary,
                            color: theme.secondary,
                            boxShadow: (hover || drag) ? theme.boxShadow : props.preloadImg ? '' : theme.boxShadow,
                        }}
                        htmlFor='files'
                        className="transition-all cursor-pointer p-2 rounded-md w-max"

                    >
                        {props.preloadImg ?
                            drag ?
                                "Dépose ton screenshot ici" : hover ? "Choisir un screenshot" : ""
                            : drag ? "Dépose ton screenshot ici" : "Choisir un screenshot"}
                    </label>
                </>}
                <input
                    id="files"
                    type="file"
                    className='hidden'
                    onChangeCapture={handleNewImage}
                />
            </div>
            {props.preloadImg && <>
                <ModalImg
                    src={props.preloadImg} alt="preview screenShot" />
            </>}
        </div>
    )
}